export const GET_HOLIDAYS_REQUEST="GET_HOLIDAYS_REQUEST";
export const GET_HOLIDAYS_SUCCESS="GET_HOLIDAYS_SUCCESS";
export const GET_HOLIDAYS_FALIURE="GET_HOLIDAYS_FALIURE";

export const ADD_HOLIDAYS_REQUEST="ADD_HOLIDAYS_REQUEST";
export const ADD_HOLIDAYS_SUCCESS="ADD_HOLIDAYS_SUCCESS";
export const ADD_HOLIDAYS_FALIURE="ADD_HOLIDAYS_FALIURE";

export const UPDATE_HOLIDAYS_REQUEST="UPDATE_HOLIDAYS_REQUEST";
export const UPDATE_HOLIDAYS_SUCCESS="UPDATE_HOLIDAYS_SUCCESS";
export const UPDATE_HOLIDAYS_FALIURE="UPDATE_HOLIDAYS_FALIURE";

export const DELETE_HOLIDAYS_REQUEST="DELETE_HOLIDAYS_REQUEST";
export const DELETE_HOLIDAYS_SUCCESS="DELETE_HOLIDAYS_SUCCESS";
export const DELETE_HOLIDAYS_FALIURE="DELETE_HOLIDAYS_FALIURE";