import {
  GET_HOLIDAYS_FALIURE,
  GET_HOLIDAYS_REQUEST,
  GET_HOLIDAYS_SUCCESS,
  // ADD_HOLIDAYS_FALIURE,
  // ADD_HOLIDAYS_SUCCESS,
  // ADD_HOLIDAYS_REQUEST,
  // UPDATE_HOLIDAYS_FALIURE,
  // UPDATE_HOLIDAYS_REQUEST,
  // UPDATE_HOLIDAYS_SUCCESS,
  // DELETE_HOLIDAYS_FALIURE,
  // DELETE_HOLIDAYS_REQUEST,
  // DELETE_HOLIDAYS_SUCCESS,
} from "../constants/holidays";

const holidayData = { data: [], isLoading: false, error: false };

function getHolidays(state = holidayData, action) {
  switch (action.type) {
    case GET_HOLIDAYS_REQUEST:
      return { data: [], isLoading: true, error: false };
    case GET_HOLIDAYS_SUCCESS:
      return { data: action.payload, isLoading: false, error: false };
    case GET_HOLIDAYS_FALIURE:
      return { data: [], isLoading: false, error: true };
    default:
      return state;
  }
}

export { getHolidays };
