import React, { useState } from "react";
import Slot from "./Slot";

export default function ParkingMap() {
  const [mapping] = useState([
    [
      { fill: 30, empty: 1, start: 41, col: "15" },
      { fill: 11, empty: 0, start: 11, col: "15" },
    ],
    [{ fill: 0, empty: 42 }],
    [
      { fill: 0, empty: 41 },
      { fill: 0, empty: 0 },
    ],
    [
      { fill: 35, empty: 1, start: 40, col: "14" },
      { fill: 1, empty: 4, start: 12, col: "C" },
      { fill: 1, empty: 0, start: 12, col: "D" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 11, col: "C" },
      { fill: 1, empty: 0, start: 11, col: "D" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 10, col: "C" },
      { fill: 1, empty: 0, start: 10, col: "D" },
    ],
    [
      { fill: 30, empty: 1, start: 33, col: "13" },
      { fill: 3, empty: 2, start: 3, col: "13" },
      { fill: 1, empty: 4, start: 9, col: "C" },
      { fill: 1, empty: 0, start: 9, col: "D" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 8, col: "C" },
      { fill: 1, empty: 0, start: 8, col: "D" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 7, col: "C" },
      { fill: 1, empty: 0, start: 7, col: "D" },
    ],
    [
      { fill: 30, empty: 1, start: 33, col: "12" },
      { fill: 3, empty: 2, start: 3, col: "12" },
      { fill: 1, empty: 4, start: 6, col: "C" },
      { fill: 1, empty: 0, start: 6, col: "D" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 5, col: "C" },
      { fill: 1, empty: 0, start: 5, col: "D" },
    ],
    [
      { fill: 0, empty: 6 },
      { fill: 24, empty: 1, start: 27, col: "11" },
      { fill: 3, empty: 2, start: 3, col: "11" },
      { fill: 1, empty: 4, start: 4, col: "C" },
      { fill: 1, empty: 0, start: 4, col: "D" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 3, col: "C" },
      { fill: 1, empty: 0, start: 3, col: "D" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 2, col: "C" },
      { fill: 1, empty: 0, start: 2, col: "D" },
    ],
    [
      { fill: 0, empty: 6 },
      { fill: 24, empty: 1, start: 27, col: "10" },
      { fill: 3, empty: 2, start: 3, col: "10" },
      { fill: 1, empty: 4, start: 1, col: "C" },
      { fill: 1, empty: 0, start: 1, col: "D" },
    ],
    [{ fill: 0, empty: 42 }],
    [
      { fill: 0, empty: 7 },
      { fill: 23, empty: 1, start: 33, col: "9" },
      { fill: 10, empty: 1, start: 10, col: "9" },
    ],
    [{ fill: 0, empty: 42 }],
    [{ fill: 0, empty: 42 }],
    [
      { fill: 0, empty: 5 },
      { fill: 25, empty: 1, start: 35, col: "8" },
      { fill: 10, empty: 1, start: 10, col: "8" },
    ],
    [{ fill: 0, empty: 42 }],
    [
      { fill: 0, empty: 6 },
      { fill: 24, empty: 1, start: 27, col: "7" },
      { fill: 3, empty: 2, start: 3, col: "7" },
      { fill: 1, empty: 4, start: 1, col: "B" },
      { fill: 1, empty: 0, start: 1, col: "A" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 2, col: "B" },
      { fill: 1, empty: 0, start: 2, col: "A" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 3, col: "B" },
      { fill: 1, empty: 0, start: 3, col: "A" },
    ],
    [
      { fill: 0, empty: 6 },
      { fill: 24, empty: 1, start: 27, col: "6" },
      { fill: 3, empty: 2, start: 3, col: "6" },
      { fill: 1, empty: 4, start: 4, col: "B" },
      { fill: 1, empty: 0, start: 4, col: "A" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 5, col: "B" },
      { fill: 1, empty: 0, start: 5, col: "A" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 6, col: "B" },
      { fill: 1, empty: 0, start: 6, col: "A" },
    ],
    [
      { fill: 0, empty: 7 },
      { fill: 23, empty: 1, start: 26, col: "5" },
      { fill: 3, empty: 2, start: 3, col: "5" },
      { fill: 1, empty: 4, start: 7, col: "B" },
      { fill: 1, empty: 0, start: 7, col: "A" },
    ],
    [
      { fill: 0, empty: 36 },
      { fill: 1, empty: 4, start: 8, col: "B" },
      { fill: 1, empty: 0, start: 8, col: "A" },
    ],
    [{ fill: 0, empty: 42 }],
    [
      { fill: 0, empty: 6 },
      { fill: 24, empty: 1, start: 33, col: "4" },
      { fill: 3, empty: 2, start: 9, col: "4" },
      { fill: 6, empty: 0, start: 6, col: "4" },
    ],
    [{ fill: 0, empty: 42 }],
    [
      { fill: 0, empty: 6 },
      { fill: 24, empty: 1, start: 34, col: "3" },
      { fill: 3, empty: 1, start: 10, col: "3" },
      { fill: 7, empty: 0, start: 7, col: "3" },
    ],
    [{ fill: 0, empty: 42 }],
    [
      { fill: 30, empty: 1, start: 41, col: "2" },
      { fill: 11, empty: 0, start: 11, col: "2" },
    ],
    [{ fill: 0, empty: 42 }],
    [{ fill: 42, empty: 0, start: 42, col: "1" }],
  ]);
  return (
    <div className="flex gap-1 mx-auto p-4 w-fit">
      {mapping.map((col, i) => (
        <div key={i} className="flex flex-col gap-1">
          {col.map((item, j) => (
            <div className="flex flex-col gap-1" key={j}>
              {Array(item.fill)
                .fill(1)
                .map((_, k) => (
                  <Slot slotId={item.start - k} rowId={item.col} />
                ))}
              {Array(item.empty)
                .fill(1)
                .map((_, k) => (
                  <div key={k} className="w-7 h-7" />
                ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
