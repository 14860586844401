import React from "react";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;
const footerStyle = {
  textAlign: "center",
  backgroundColor: "white",
  height: "65px",
  alignItems: "center",
  // marginTop: "16px",
  display: "flex",
  justifyContent: "center",
  fontSize: "16px",
  fontWeight: "bold",
};

const FooterComponent = () => {
  const { t } = useTranslation();
  return <Footer style={footerStyle}>{t("copyright")}</Footer>;
};

export default FooterComponent;
