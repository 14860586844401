import axios from "../../../utils/axios";
import {
  userData,
  userDelete,
  userAdd,
  updateUser,
  uploadImage,
} from "../../../urls";
import {
  GET_USER_FALIURE,
  GET_USER_SUCCESS,
  GET_USER_REQUEST,
  DELETE_USER_FALIURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  ADD_USER_FALIURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  UPDATE_USER_FALIURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPLOAD_PROFILE_FALIURE,
  UPLOAD_PROFILE_SUCCESS,
  UPLOAD_PROFILE_REQUEST,
} from "../../constants/user";
import { getPayload } from "../../../utils/extras";

export const getUserData = (tableParams, searchQuery, role) => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_REQUEST });
    try {
      const result = await axios.get(userData(tableParams, searchQuery, role));
      dispatch({ type: GET_USER_SUCCESS, payload: result.data.data || [] });
    } catch (err) {
      dispatch({ type: GET_USER_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const deleteUserData = (userId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_USER_REQUEST });
    try {
      const result = await axios.delete(userDelete(), { data: userId });
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: result?.data?.data?.results || [],
      });
    } catch (err) {
      dispatch({ type: DELETE_USER_FALIURE, payload: err });
    }
  };
};

export const addUserData = (tableParams) => {
  return async (dispatch) => {
    dispatch({ type: ADD_USER_REQUEST });
    try {
      const result = await axios.post(userAdd(), getPayload(tableParams));
      dispatch({
        type: ADD_USER_SUCCESS,
        payload: result?.data?.data?.results || [],
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: ADD_USER_FALIURE, payload: err });
      return err;
    }
  };
};

export const updteUserData = (tableParams) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_USER_REQUEST });
    try {
      const result = await axios.put(updateUser(), getPayload(tableParams));
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: result?.data?.data?.results || [],
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: UPDATE_USER_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const uploadProfilePicture = (tableParams) => {
  return async (dispatch) => {
    dispatch({ type: UPLOAD_PROFILE_REQUEST });
    try {
      const result = await axios.post(uploadImage(), tableParams);
      return result?.data;
      // dispatch({ type: UPLOAD_PROFILE_SUCCESS, payload: result?.data?.data?.results || [] });
    } catch (err) {
      dispatch({ type: UPLOAD_PROFILE_FALIURE, payload: err });
      console.log(err);
    }
  };
};
