import axios from "axios";
import { base_url } from "../urls";

const token = localStorage.getItem("accessToken");

const axiosConfig = {
  baseURL: base_url,
  headers: { Authorization: token },
};

export default axios.create(axiosConfig);
