// Rates And Fees constants
export const ADD_RATES_REQUEST = "ADD_RATES_REQUEST";
export const ADD_RATES_SUCCESS = "ADD_RATES_SUCCESS";
export const ADD_RATES_FALIURE = "ADD_RATES_FALIURE";

export const UPDATE_RATES_REQUEST = "UPDATE_RATES_REQUEST";
export const UPDATE_RATES_SUCCESS = "UPDATE_RATES_SUCCESS";
export const UPDATE_RATES_FALIURE = "UPDATE_RATES_FALIURE";

export const GET_RATES_REQUEST = "GET_RATES_REQUEST";
export const GET_RATES_SUCCESS = "GET_RATES_SUCCESS";
export const GET_RATES_FALIURE = "GET_RATES_FALIURE";

export const DELETE_RATES_REQUEST = "DELETE_RATES_REQUEST";
export const DELETE_RATES_SUCCESS = "DELETE_RATES_SUCCESS";
export const DELETE_RATES_FALIURE = "DELETE_RATES_FALIURE";
