import {
  ADD_BOOKING_FALIURE,
  ADD_BOOKING_REQUEST,
  ADD_BOOKING_SUCCESS,
  GET_BOOKING_REQUEST,
  GET_BOOKING_FALIURE,
  GET_BOOKING_SUCCESS,
  GET_SLOTS_REQUEST,
  GET_SLOTS_FALIURE,
  GET_SLOTS_SUCCESS,
} from "../constants/booking_option";

const bookingOptionData = { users: [], isLoading: false, error: null };

function getBookingOption(state = bookingOptionData, action) {
  switch (action.type) {
    case GET_BOOKING_REQUEST:
      return { users: [], isLoading: true, error: null };
    case GET_BOOKING_SUCCESS:
      return { users: [action.payload], isLoading: false, error: null };
    case GET_BOOKING_FALIURE:
      return { users: [], isLoading: false, error: action.payload };
    default:
      return state;
  }
}

const totalSlots = { data: [], isLoading: false, error: null };

function getTotalSlot(state = totalSlots, action) {
  switch (action.type) {
    case GET_SLOTS_REQUEST:
      return { data: [], isLoading: true, error: null };
    case GET_SLOTS_SUCCESS:
      return { data: [action.payload], isLoading: false, error: null };
    case GET_SLOTS_FALIURE:
      return { data: [], isLoading: false, error: action.payload };
    default:
      return state;
  }
}

function addBookingOption(state = bookingOptionData, action) {
  switch (action.type) {
    case ADD_BOOKING_REQUEST:
      return state;
    case ADD_BOOKING_SUCCESS:
      return action.payload;
    case ADD_BOOKING_FALIURE:
      return state;
    default:
      return state;
  }
}

function editBookingOption(state = bookingOptionData, action) {
  switch (action.type) {
    case ADD_BOOKING_REQUEST:
      return state;
    case ADD_BOOKING_SUCCESS:
      return action.payload;
    case ADD_BOOKING_FALIURE:
      return action.payload;
    default:
      return state;
  }
}

export { getBookingOption, getTotalSlot, addBookingOption, editBookingOption };
