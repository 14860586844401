import { Tabs } from "antd";
import Reservation from "./Reservation";
import Holidays from "./Holidays";
import LateFee from "./LateFee";
import PromoCodes from "./PromoCodes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const RatesFees = () => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState(1);
  const [compLoading, setCompLoading] = useState(false);
  return (
    <Tabs
      id="rates-fees"
      defaultActiveKey={activeKey}
      type="card"
      size="small"
      className="w-full"
      onChange={(e) => setActiveKey(e)}
      items={[
        {
          label: t(`reservation`),
          key: 1,
          children: (
            <Reservation
              activeKey={activeKey}
              setCompLoading={setCompLoading}
              compLoading={compLoading}
            />
          ),
        },
        {
          label: t(`holidays`),
          key: 2,
          children: <Holidays activeKey={activeKey} />,
        },
        {
          label: t(`block_slot`),
          key: 3,
          children: <LateFee activeKey={activeKey} />,
        },
        {
          label: t(`promocodes`),
          key: 4,
          children: <PromoCodes activeKey={activeKey} />,
        },
      ]}
    />
  );
};

export default RatesFees;
