export const GET_COMMISSIONS_REQUEST="GET_COMMISSIONS_REQUEST";
export const GET_COMMISSIONS_SUCCESS="GET_COMMISSIONS_SUCCESS";
export const GET_COMMISSIONS_FALIURE="GET_COMMISSIONS_FALIURE";

export const ADD_COMMISSIONS_REQUEST="ADD_COMMISSIONS_REQUEST";
export const ADD_COMMISSIONS_SUCCESS="ADD_COMMISSIONS_SUCCESS";
export const ADD_COMMISSIONS_FALIURE="ADD_COMMISSIONS_FALIURE";

export const UPDATE_COMMISSIONS_REQUEST="UPDATE_COMMISSIONS_REQUEST";
export const UPDATE_COMMISSIONS_SUCCESS="UPDATE_COMMISSIONS_SUCCESS";
export const UPDATE_COMMISSIONS_FALIURE="UPDATE_COMMISSIONS_FALIURE";

export const DELETE_COMMISSIONS_REQUEST="DELETE_COMMISSIONS_REQUEST";
export const DELETE_COMMISSIONS_SUCCESS="DELETE_COMMISSIONS_SUCCESS";
export const DELETE_COMMISSIONS_FALIURE="DELETE_COMMISSIONS_FALIURE";