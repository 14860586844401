import {
  GET_ARRIVALS_DEPARTURES_REQUEST,
  GET_ARRIVALS_DEPARTURES_SUCCESS,
  GET_ARRIVALS_DEPARTURES_FALIURE,
} from "../../constants/arrivals_departures";
import {
  makeGetArrivalDepartureData,
} from "../../../urls";
import axios from "../../../utils/axios";

export const getArrivalsDepartureData = (
  params,
  extraParams,
  dateFilter,
  search,
  isAccending,
  sortBy
) => {
  return async (dispatch) => {
    dispatch({ type: GET_ARRIVALS_DEPARTURES_REQUEST });
    try {
      const result = await axios(
        makeGetArrivalDepartureData(
          params,
          extraParams,
          dateFilter,
          search,
          isAccending,
          sortBy
        )
      );
      dispatch({
        type: GET_ARRIVALS_DEPARTURES_SUCCESS,
        payload: result.data?.data,
      });
    } catch (err) {
      dispatch({ type: GET_ARRIVALS_DEPARTURES_FALIURE, payload: err });
      console.log(err);
    }
  };
};

//   export const addbookingOption = (payload) => {
//     return async (dispatch) => {
//       dispatch({ type: ADD_BOOKING_REQUEST });
//       try {
//           const result = await axios.post(
//             postAllBookingOption,
//             payload
//             );
//         dispatch({ type: ADD_BOOKING_SUCCESS, payload: result.data});

//       } catch (err) {
//         dispatch({ type: ADD_BOOKING_FALIURE, payload: err});
//         console.log(err);
//       }
//     };
//   };

//   export const EditbookingOption = (payload) => {
//     return async (dispatch) => {
//       dispatch({ type: ADD_BOOKING_REQUEST });
//       try {
//           const result = await axios.put(
//             updateAllBookingOption,
//             payload
//             );
//         dispatch({ type: ADD_BOOKING_SUCCESS, payload: result});

//       } catch (err) {
//         dispatch({ type: ADD_BOOKING_FALIURE, payload: err});
//         console.log(err);
//       }
//     };
//   };
