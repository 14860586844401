import addRow from "./addRow.svg";
import addRow_black from "./addRow_black.svg";
import arrivals_and_departures from "./arrivals_and_departures.svg";
import booking_option from "./booking_option.svg";
import close from "./close.svg";
import closeBlack from "./close_black.svg";
import column from "./column.svg";
import commissions from "./commissions.svg";
import dashboard from "./dashboard.svg";
import deleteIcon from "./delete.svg";
import diary from "./diary.svg";
import disable from "./disable.svg";
import driver_and_staff from "./driver_and_staff.svg";
import envelope from "./envelope.svg";
import flight_land from "./flight_land.svg";
import file_pdf from "./file_pdf.svg";
import flight_takeoff from "./flight_takeoff.svg";
import history from "./history.svg";
import gps from "./gps_icon.svg";
import lock from "./lock.svg";
import management from "./management.svg";
import parking_map from "./parking_map.svg";
import phone from "./phone.svg";
import pencil from "./pencil.svg";
import pencil_white from "./pencil_white.svg";
import parking from "./parking.svg";
import rates_and_fees from "./rates_and_fees.svg";
import save from "./save.svg";
import search from "./search.svg";
import settings from "./settings.svg";
import shuttle from "./shuttle.svg";
import sign_in_alt from "./sign_in_alt.svg";
import statistics from "./statistics.svg";
import tick from "./tick.svg";
import townhouse from "./townhouse.svg";
import user from "./user.svg";
import users from "./users.svg";
import whiteTick from "./whiteTick.svg";
import whiteTick2 from "./whiteTick2.svg";

export {
  addRow,
  addRow_black,
  arrivals_and_departures,
  booking_option,
  close,
  closeBlack,
  column,
  commissions,
  dashboard,
  deleteIcon,
  diary,
  disable,
  driver_and_staff,
  envelope,
  flight_land,
  file_pdf,
  flight_takeoff,
  gps,
  history,
  lock,
  management,
  parking_map,
  parking,
  pencil,
  phone,
  pencil_white,
  rates_and_fees,
  save,
  search,
  settings,
  shuttle,
  sign_in_alt,
  statistics,
  tick,
  townhouse,
  user,
  users,
  whiteTick,
  whiteTick2,
};
