import {
  ADD_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_SUCCESS,
  GET_ALL_PROMOCODES_SUCCESS,
  PROMOCODE_FALIURE,
  PROMOCODE_REQUEST,
  UPDATE_PROMOCODE_SUCCESS,
} from "../constants/promocode";

const initialState = {
  data: {},
  loading: false,
  message: "",
  error: false,
};

export function promocodeReducer(promocodes = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PROMOCODE_REQUEST: {
      return { ...promocodes, loading: true, error: false, message: "" };
    }

    case PROMOCODE_FALIURE: {
      return {
        ...promocodes,
        loading: false,
        error: true,
        message: payload?.message,
      };
    }

    case ADD_PROMOCODE_SUCCESS: {
      return {
        loading: false,
        error: false,
        message: payload?.message,
        data: {
          ...promocodes.data,
          results: [...promocodes.data.results, payload.data],
        },
      };
    }

    case GET_ALL_PROMOCODES_SUCCESS: {
      return {
        loading: false,
        error: false,
        message: payload?.message,
        data: payload?.data,
      };
    }

    case UPDATE_PROMOCODE_SUCCESS: {
      return {
        loading: false,
        error: false,
        message: payload?.message,
        data: {
          ...promocodes.data,
          results: promocodes.data?.results.map((promocode) => {
            if (promocode._id === payload.data._id) {
              return {
                ...promocode,
                ...payload.data,
              };
            } else {
              return promocode;
            }
          }),
        },
      };
    }

    case DELETE_PROMOCODE_SUCCESS: {
      return {
        loading: false,
        error: false,
        message: payload?.message,
        data: {
          ...promocodes.data,
          results: promocodes?.data?.results?.filter(
            ({ _id }) => _id !== payload?.data?._id
          ),
        },
      };
    }

    default:
      return promocodes;
  }
}
