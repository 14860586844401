import React from "react";
import { Input, Form, Checkbox } from "antd";
import logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginOption } from "../../../redux/actions/login_user";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const s = {
  wrapperClasses:
    "bg-[#F2F1F1] min-w-full min-h-screen flex justify-center items-center",
  loginFormClasses:
    "bg-white flex flex-col xl:p-28 p-5 justify-center items-center shadow-[0px_0px_15px_rgba(0, 0, 0, 0.04)] md:min-h-full min-h-screen w-[70%] relative",
  h1Classes: "text-center font-bold py-5 text-2xl uppercase",
  pClasses: "pt-2 pb-10 text-center text-2xl",
  inputClasses: "rounded-none py-4 px-5 xl:w-[42rem] w-full",
  buttonClasses:
    "uppercase w-full h-full text-white font-bold py-4 rounded-none whitespace-nowrap",
};

const lngs = {
  fr: { nativeName: "French" },
  en: { nativeName: "English" },
};
const Login = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    const requestData = {
      email: get(values, "email", ""),
      password: get(values, "password", ""),
    };
    const result = await dispatch(loginOption(requestData));
    if (
      get(result, "statusCode", "") === 200 &&
      !isEmpty(get(result, "data", {}))
    ) {
      await localStorage.setItem(
        "accessToken",
        get(result, "data.accessToken", "")
      );
      navigate("/dashboard");
      window.location.reload();
    }
  };

  const onFinish = (values) => {
    handleSubmit(values);
  };

  const onValuesChange = (changedValues, allValues) => {
    console.log("Changed values:", changedValues);
    console.log("All values:", allValues);
  };

  return (
    <div className={s.wrapperClasses}>
      <div className={s.loginFormClasses}>
        <div className="flex justify-end gap-3 ml-auto mb-10 absolute top-3 right-3">
          {Object.keys(lngs).map((lng) => (
            <button
              key={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              }}
              onClick={(e) => {
                e.preventDefault();
                i18n.changeLanguage(lng);
              }}
            >
              <img
                src={`/icons/${lng === "fr" ? "fr" : "en"}.png`}
                alt=""
                className="w-6 inline-block"
              />{" "}
              {lngs[lng].nativeName}
            </button>
          ))}
        </div>
        <img src={logo} alt="logo" />
        <h1 className={s.h1Classes}>{t("login.title")}</h1>
        <p className={s.pClasses}>{t("login.desc")}</p>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          className="w-full"
        >
          <Form.Item
            label={t("email")}
            name="email"
            rules={[
              {
                required: true,
                message: t("login.email_required"),
              },
            ]}
          >
            <Input
              placeholder={t("login.enter_email")}
              className={s.inputClasses}
            />
          </Form.Item>
          <Form.Item
            label={t("login.password")}
            name="password"
            rules={[
              {
                required: true,
                message: t("login.password_required"),
              },
            ]}
          >
            <Input
              placeholder={t("login.enter_password")}
              className={s.inputClasses}
            />
          </Form.Item>
          <Form.Item className="mt-4">
            <Checkbox>{t("login.stay_loggedin")}</Checkbox>
          </Form.Item>
          <div className="flex flex-col gap-4 md:flex-row md:gap-8">
            <Form.Item className="flex-1">
              <button
                className={s.buttonClasses + " bg-[#081258] uppercase"}
                onClick={() => navigate("/forgot-password")}
                type="button"
              >
                {t("login.forgot_password")}
              </button>
            </Form.Item>
            <Form.Item className="flex-1">
              <button
                className={s.buttonClasses + " bg-[#E12714]"}
                type="submit"
              >
                {t("login.title")}
              </button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
