import {
  Avatar,
  Radio,
  Select,
  Space,
  Table,
  Tooltip,
  Switch,
  notification,
  Input,
} from "antd";
import { get, isEmpty } from "lodash";
import { fr } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { pencil, closeBlack, tick } from "../../assets/icons";
import moment from "moment";
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import CreateReservation from "./CreateReservation";
import {
  EditbookingOption,
  addbookingOption,
  getAllSlots,
} from "../../redux/actions/booking_option";
import { deletebookingOption } from "../../redux/actions/booking_option";
import { renderSlotComp } from "../../utils/extras";
import { getArrivalsDepartureData } from "../../redux/actions/arrivals_departures";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

const ArrivalsDepartures = () => {
  const { t } = useTranslation();
  const [check, setcheck] = useState(false);
  const { Search } = Input;
  const [searchVal, setSearchVal] = useState("");

  const onChangeHandler = (values, checked) => {
    setSwitchLoading(values._id);
    localStorage.setItem("parkingSlot", get(values, "emplacement", ""));
    handleEditSubmit(values, "", checked);
  };
  const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = Math.abs(hash).toString(16).slice(0, 6);
    return `#${"0".repeat(6 - color.length)}${color}`;
  };
  const handleCancelReservation = async (id) => {
    const requestObj = {
      id: id,
    };
    const result = await dispatch(deletebookingOption(requestObj));
    if (get(result, "status", "") === 200) {
      setShowModal(false);
      notification.success({
        placement: "topRight",
        message: "Reservation removed Succesfully!",
      });
      dispatch(
        getArrivalsDepartureData(
          tableParams,
          {},
          moment(dateFilter).format("YYYY-MM-DD")
        )
      );
    } else {
      notification.error({
        placement: "topRight",
        message: "Reservation can not be removed!",
      });
    }
  };
  const actionColumn = {
    title: " ",
    dataIndex: "actions",
    sorter: false,
    render: (_, record) => {
      return (
        <Space size="middle">
          <button
            onClick={() =>
              !isEmpty(editingRow) && editingRow?.id === record?.id
                ? handleEditSubmit(record)
                : handleEdit(record)
            }
            disabled={loading}
          >
            {loading &&
            !isEmpty(editingRow) &&
            editingRow?.id === record?.id ? (
              <LoadingOutlined />
            ) : (
              <img
                src={
                  !isEmpty(editingRow) && editingRow?.id === record?.id
                    ? tick
                    : pencil
                }
                alt=""
                className="min-w-[20px]"
              />
            )}
          </button>
        </Space>
      );
    },
  };
  const columns = [
    {
      title: "AR",
      dataIndex: "arrivalDate",
      sorter: true,
      render: (landing) => {
        const parsedLandingDate = moment.parseZone(landing);
        const formattedLandingDate =
          parsedLandingDate.format("DD/MM/YYYY HH:mm");

        return (
          <span className="bg-[#47718F] text-white p-1 rounded-sm whitespace-nowrap">
            {formattedLandingDate}
          </span>
        );
      },
    },
    {
      title: "RESA",
      dataIndex: "platform",
      sorter: true,
      render: (platform, data) => {
        const pfName = platform?.split(" ");
        const backgroundColor = stringToColor(platform);
        return (
          <div className="flex items-center">
            <Tooltip placement="top" title={platform}>
              <Avatar
                className="cursor-pointer"
                onClick={() => handleRecordEdit(data)}
                style={{
                  backgroundColor,
                  marginRight: "0.5rem",
                }}
              >
                <div className="uppercase">
                  <p>
                    {pfName[0]?.[0]}
                    {pfName[1]?.[0]}
                  </p>
                </div>
              </Avatar>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "IMMAT",
      dataIndex: "vehicleNumber",
      sorter: true,
    },
    {
      title: "VEH",
      dataIndex: "vehicleInfo",
      sorter: true,
    },
    {
      title: "CLIENT",
      dataIndex: "customerName",
      sorter: true,
      render: (customerName, rec) =>
        customerName || `${rec.firstName} ${rec.lastName}`,
    },
    {
      title: "COMMENT",
      dataIndex: "comments",
      sorter: true,
    },
    {
      title: "PLACE",
      dataIndex: "emplacement",
      sorter: true,
      render: (emplacement, record) =>
        renderSlotComp(
          record,
          emplacement,
          editingRow,
          (val) => {
            localStorage.setItem("parkingSlot", val);
          },
          slotsData
        ),
    },
    {
      title: "EXIT",
      dataIndex: "exit",
      sorter: true,
      render: (exit, record) => (
        <Switch
          defaultChecked={exit === "Arrived"}
          onChange={(checked) => onChangeHandler(record, checked)}
          className={
            switchLoading === record._id ? "bg-green-500" : "bg-slate-400"
          }
        />
      ),
    },
    {
      title: "PASS",
      dataIndex: "passengerNumber",
      sorter: true,
    },
    actionColumn,
  ];
  const columns2 = [
    {
      title: "DEP",
      dataIndex: "departureDate",
      sorter: true,
      render: (landing) => {
        const parsedLandingDate = moment.parseZone(landing);
        const formattedLandingDate =
          parsedLandingDate.format("DD/MM/YYYY HH:mm");

        return (
          <span className="bg-[#47718F] text-white p-1 rounded-sm whitespace-nowrap">
            {formattedLandingDate}
          </span>
        );
      },
    },
    {
      title: "RESA",
      dataIndex: "platform",
      sorter: true,
      render: (platform, data) => {
        const pfName = platform?.split(" ");
        const backgroundColor = stringToColor(platform);
        return (
          <div className="flex items-center">
            <Tooltip placement="top" title={platform}>
              <Avatar
                className="cursor-pointer"
                onClick={() => handleRecordEdit(data)}
                style={{
                  backgroundColor,
                  marginRight: "0.5rem",
                }}
              >
                <div className="uppercase">
                  <p>
                    {pfName[0]?.[0]}
                    {pfName[1]?.[0]}
                  </p>
                </div>
              </Avatar>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "IMMAT",
      dataIndex: "vehicleNumber",
      sorter: true,
    },
    {
      title: "VEH",
      dataIndex: "vehicleInfo",
      sorter: true,
    },
    {
      title: "CLIENT",
      dataIndex: "customerName",
      sorter: true,
      render: (customerName, rec) =>
        customerName || `${rec.firstName} ${rec.lastName}`,
    },
    {
      title: "COMMENT",
      dataIndex: "comments",
      sorter: true,
    },
    {
      title: "PLACE",
      dataIndex: "emplacement",
      sorter: true,
      render: (emplacement, record) =>
        renderSlotComp(
          record,
          emplacement,
          editingRow,
          (val) => {
            localStorage.setItem("parkingSlot", val);
          },
          slotsData
        ),
    },
    {
      title: "EXIT",
      dataIndex: "exit",
      sorter: true,
      render: (exit, record) => (
        <>
          <Switch
            defaultChecked={exit === "Arrived"}
            onChange={(checked) => {
              setcheck(!check);
              onChangeHandler(record, checked);
            }}
            style={{
              backgroundColor: switchLoading === record._id ? "red" : "grey",
            }}
          />
        </>
      ),
    },
    {
      title: "PASS",
      dataIndex: "passengerNumber",
      sorter: true,
    },
    actionColumn,
  ];
  const plainOptions = [t("arrivals"), t("departures"), t("all")];
  const dispatch = useDispatch();
  const allData = useSelector((state) => state);
  const slotOccupied = allData?.getTotalSlot?.data[0]?.occupiedSlot;
  const isLoading = get(allData, "getArrivalDeparture.isLoading", []);
  const [initialValues, setInitialValue] = useState({});
  const arrivalsDeparturesData = get(
    allData,
    "getArrivalDeparture.data.0.results",
    []
  );
  const extraParams = {};
  const slotsData = get(allData, "getTotalSlot.data.0", {});
  const [showModal, setShowModal] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [showArrivalTable, setShowArrivalTable] = useState(true);
  const [showDepartureTable, setShowDepartureTable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [editingRow, setEditingRow] = useState({});
  const [tableData, setTableData] = useState([]);
  const [arrivalCount, setArrivalCount] = useState();
  const [departureCount, setDepartureCount] = useState();
  const [rechargeVal, setRechargeVal] = useState();
  const [close, setClose] = useState(false);
  const [dateFilter, setDateFilter] = useState(new Date());
  const [selectedValue, setSelectedValue] = useState(t("all"));
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
    },
  });

  const months = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;
  const handleArrivalDeparture = (value) => {
    setSelectedValue(value);

    if (value === t("arrivals")) {
      setShowArrivalTable(true);
      setShowDepartureTable(false);
    } else if (value === t("departures")) {
      setShowArrivalTable(false);
      setShowDepartureTable(true);
    } else {
      setShowArrivalTable(true);
      setShowDepartureTable(true);
    }
  };

  const handleEditSubmit = async (values, from = "", checked) => {
    setLoading(true);
    const arrive = localStorage.getItem("arrived");
    const slot = localStorage.getItem("parkingSlot");
    if (
      (!isEmpty(arrive) && !isEmpty(slot) && from === "") ||
      from === "registration" ||
      checked === true ||
      checked === false
    ) {
      delete values?._id;
      const requestObject = {
        id: values?.id,
        arrived: arrive || values?.arrived,
        countryCode: "+91",
        arrivalDate: moment
          .parseZone(values.arrivalDate)
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        arrivalFlightCompany: values.arrivalFlightCompany,
        arrivalFlightNumber: values.arrivalFlightNumber,
        arrivalFlightTerminal: values.arrivalFlightTerminal,
        bookingAmount: values.bookingAmount,
        comments: values.comments,
        departureDate: moment
          .parseZone(values.departureDate)
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        departureFlightCompany: values.departureFlightCompany,
        departureFlightNumber: values.departureFlightNumber,
        departureFlightTerminal: values.departureFlightTerminal,
        email: values.email,
        extraAmount: values.extraAmount,
        firstName: values.firstName,
        city: values.hometown,
        lastName: values.lastName,
        outdoorParking: values.outdoorParking,
        parkingSlot: slot || values.parkingSlot,
        oldParkingSlot: values?.parkingSlot,
        passengerNumber: values.passengerNumber,
        phoneNo: values.phoneNo,
        platform: values.platform,
        rearSeat: values.rearSeat,
        registration: values.registration,
        staff: values.staff,
        vehicleNumber: values.vehicleNumber,
        vehicleInfo: values.vehicleInfo,
        exit:
          checked && values.exit === "EXIT"
            ? "ARRIVED"
            : checked
            ? "Arrived"
            : "EXIT",
        rechargeVehicleAmount: values?.rechargeVehicleAmount,
        // hometown: values?.hometown,
      };
      const filteredRequestObject = Object.fromEntries(
        Object.entries(requestObject).filter(([_, value]) => value !== "")
      );

      const result = await dispatch(EditbookingOption(filteredRequestObject));
      if (get(result, "status") === 200) {
        notification.success({
          placement: "topRight",
          message: "Reservation Updated Succesfully!",
        });
        setLoading(false);
        setEditingRow({});
        setSwitchLoading(false);
        dispatch(
          getArrivalsDepartureData(
            tableParams,
            {},
            moment(dateFilter).format("YYYY-MM-DD")
          )
        );
        setcheck(false);
        localStorage.removeItem("arrived");
        localStorage.removeItem("parkingSlot");
        setShowModal(false);
      } else {
        setLoading(false);
        setSwitchLoading(false);
        notification.error({
          placement: "topRight",
          message: result.response.data.message,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleEdit = (record) => {
    localStorage.setItem("arrived", record.arrived);
    localStorage.setItem("parkingSlot", record?.emplacement);
    localStorage.setItem("exit", record?.exit);
    setEditingRow(record);
  };

  const handleRecordEdit = (data) => {
    const parsedArrivalDate = moment.parseZone(data.arrivalDate);
    const parsedDepartureDate = moment.parseZone(data.departureDate);
    const formattedArrivalDate = parsedArrivalDate.format(
      "MMMM D, YYYY h:mm A"
    );
    const formattedDepartureDate = parsedDepartureDate.format(
      "MMMM D, YYYY h:mm A"
    );
    const initData = {
      arrived: data?.arrived,
      countryCode: "+91",
      arrivalDate: formattedArrivalDate,
      arrivalFlightCompany: data.arrivalFlightCompany,
      arrivalFlightNumber: data.arrivalFlightNumber,
      arrivalFlightTerminal: data.arrivalFlightTerminal,
      bookingAmount: data.bookingAmount,
      comments: data.comments,
      departureDate: formattedDepartureDate,
      departureFlightCompany: data.departureFlightCompany,
      departureFlightNumber: data.departureFlightNumber,
      departureFlightTerminal: data.departureFlightTerminal,
      email: data.email,
      extraAmount: data.extraAmount,
      firstName: data.firstName,
      city: data.hometown,
      id: data?.id,
      lastName: data.lastName,
      outdoorParking: data.outdoorParking,
      parkingSlot: data?.parkingSlot,
      passengerNumber: data.passengerNumber,
      phoneNo: data.phoneNo.replace(/^\+33/, ""),
      platform: data.platform,
      rearSeat: data.rearSeat,
      registration: data.registration,
      staff: data.staff,
      vehicleNumber: data.vehicleNumber,
      vehicleInfo: data.vehicleInfo,
      reservationId: data?.reservationId,
      rechargeVehicleAmount: data?.rechargeVehicleAmount,
    };
    setInitialValue(initData);
    setShowModal(true);
  };

  const getFormattedDate = (currentDate) => {
    const date = new Date(currentDate);

    // Extract year, month, and day components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    // Format the date as "yyyy-MM-dd"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getType = (arrDate, depDate) => {
    const date1 = getFormattedDate(arrDate);
    const date2 = getFormattedDate(depDate);
    const presentDate = getFormattedDate(new Date());
    if (date1 === presentDate) {
      return "Arrival";
    }
    if (date2 === presentDate) {
      return "Departure";
    }
    if (date2 === presentDate && date2 === presentDate) {
      return "Arr/Dep";
    }
    return "-";
  };

  const prepareData = (bookingData) => {
    const bData = bookingData.map((item) => ({
      ...item,
      id: item._id,
      reservation: item.reservation,
      customerName: `${item?.firstName} ${item?.lastName}`,
      type: getType(
        get(item, "arrivalDate", ""),
        get(item, "departureDate", "")
      ),
      amount: item.bookingAmount
        ? `${(+item?.bookingAmount).toFixed(2)} €`
        : "0.00 €",
      immat: item.vehicleNumber,
      vehicles: item.vehicle ? item.vehicle : item.vehicleInfo,
      deposite: item.startTime ? item.startTime : item.departureDate,
      arrived: item.arrived ? item.arrived : "NO",
      emplacement: item.parkingSlot ? item.parkingSlot : "5/7",
      exit: item.exit,
      parkinType: item.platform ? item.platform : "Parking exterieur",
      landing: item.endTime ? item.endTime : item.arrivalDate,
      email: item?.email,
    }));
    return bData;
  };

  const arrivalData = tableData.filter((item) => {
    let dateVal = getFormattedDate(item.arrivalDate);
    const dateFilterFormatted = getFormattedDate(new Date(dateFilter));
    return item.exit === "WAITING" && dateVal === dateFilterFormatted;
  });

  const departureData = tableData.filter((item) => {
    let dateVal = getFormattedDate(item.departureDate);
    let dateVal2 = getFormattedDate(item.arrivalDate);
    const dateFilterFormatted = getFormattedDate(new Date(dateFilter));
    return (
      (item.exit !== "EXIT" && dateVal === dateFilterFormatted) ||
      (dateVal2 === dateFilterFormatted && item.exit === "Arrived")
    );
  });
  useEffect(() => {
    if (editingRow) {
      console.log("columns2", columns2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingRow, loading]);

  useEffect(() => {
    if (arrivalsDeparturesData && isEmpty(editingRow)) {
      setArrivalCount(
        get(allData, "getArrivalDeparture.data[0].arrivalDateCount", 0)
      );
      setDepartureCount(
        get(allData, "getArrivalDeparture.data[0].departureDateCount", 0)
      );
      const data = prepareData(arrivalsDeparturesData);
      setTableData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivalsDeparturesData, editingRow, loading]);

  useEffect(() => {
    if (isEmpty(extraParams) && sortOrder === null) {
      dispatch(
        getArrivalsDepartureData(
          tableParams,
          {},
          moment(dateFilter).format("YYYY-MM-DD")
        )
      );
      dispatch(getAllSlots());
    }
    if (!isEmpty(extraParams)) {
      dispatch(
        getArrivalsDepartureData(
          tableParams,
          extraParams,
          moment(dateFilter).format("YYYY-MM-DD")
        )
      );
      dispatch(getAllSlots());
    }
    // eslint-disable-next-line
  }, [tableParams]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const rowClassName = (record, index) => {
    const time = get(record, "departureDate", "");
    const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    if (currentDateTime > time) {
      return "bg-red-700 hover:bg-red-700";
    }
    return index % 2 === 0 ? "" : "bg-gray-200"; // Apply gray background to odd-indexed rows
  };

  const handleAddSubmit = async (values) => {
    const requestPayload = {
      ...values,
      arrivalDate: moment(values.arrivalDate).format("YYYY-MM-DD HH:mm:ss"),
      departureDate: moment(values.departureDate).format("YYYY-MM-DD HH:mm:ss"),
    };
    const result = await dispatch(
      addbookingOption({ ...requestPayload, arrived: "NO" })
    );
    if (get(result, "status") === 200) {
      setLoading(false);
      notification.success({
        placement: "topRight",
        message: "Reservation added Succesfully!",
      });
      setShowModal(false);
      dispatch(
        getArrivalsDepartureData(
          tableParams,
          {},
          moment(new Date()).format("YYYY-MM-DD")
        )
      );
    } else {
      setLoading(false);
      notification.error({
        placement: "topRight",
        message: result.response.data.message,
      });
    }
  };

  const handleSubmit = (val) => {
    setLoading(true);
    if (val?.id !== undefined) {
      handleEditSubmit(val, "registration");
    } else {
      handleAddSubmit(val);
    }
  };

  const removeOneDayToDate = (date) => {
    date.setDate(date.getDate() - 1);
    return date;
  };

  const tomorrowDate = (date) => {
    date.setDate(date.getDate() + 1);
    return date;
  };

  const handleDateFilter = async (value, from) => {
    const date = new Date();
    setTableParams({});
    const pageParam = {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 200,
      },
    };
    if (value === "today") {
      setDateFilter(new Date());
      await dispatch(
        getArrivalsDepartureData(
          pageParam,
          {},
          moment(date).format("YYYY-MM-DD")
        )
      );
    }
    if (value === "yesterday") {
      const yesterdayDate = removeOneDayToDate(date);
      setDateFilter(yesterdayDate);
      await dispatch(
        getArrivalsDepartureData(
          pageParam,
          {},
          moment(yesterdayDate).format("YYYY-MM-DD")
        )
      );
    }
    if (value === "tomorrow") {
      const tomoDate = tomorrowDate(date);
      setDateFilter(tomoDate);
      await dispatch(
        getArrivalsDepartureData(
          pageParam,
          {},
          moment(tomoDate).format("YYYY-MM-DD")
        )
      );
    }
  };

  const handleSort = (column) => {
    const { dataIndex } = column;
    let dataVal;
    if (dataIndex === "reservation") {
      dataVal = "platform";
    } else if (dataIndex === "customerName") {
      dataVal = "firstName";
    } else if (dataIndex === "landing") {
      dataVal = "arrivalDate";
    } else {
      dataVal = dataIndex;
    }
    const nextSortOrder = sortField === dataIndex ? !sortOrder : true;
    setSortField(dataIndex);
    setSortOrder(nextSortOrder);
    dispatch(
      getArrivalsDepartureData(
        tableParams,
        {},
        moment(dateFilter).format("YYYY-MM-DD"),
        "",
        nextSortOrder,
        dataVal
      )
    );
  };

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      style={{ paddingTop: "1px" }}
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const handleDateSelect = (date) => {
    const selectedDate = moment(date).format("YYYY-MM-DD");
    dispatch(
      getArrivalsDepartureData(
        tableParams,
        {},
        moment(selectedDate).format("YYYY-MM-DD")
      )
    );
    dispatch(getAllSlots());
  };

  const onSearchHandler = async (searchVal) => {
    setSearchVal(searchVal);
    await dispatch(
      getArrivalsDepartureData(
        tableParams,
        {},
        moment(dateFilter).format("YYYY-MM-DD"),
        searchVal
      )
    );
  };

  return (
    <>
      <div className="bg-[#081258] flex p-3 mb-4 text-white items-center">
        <div className="flex-1 flex items-center font-bold text-sm">
          <span className="border py-1 px-2 rounded mr-2">{arrivalCount}</span>
          {t("arrivals")}
          <span className="border py-1 px-2 rounded ml-5 mr-2">
            {departureCount}
          </span>
          {t("departures")} {formattedDate}
          <span className="border py-1 px-2 rounded ml-5 mr-2">
            {parseInt(arrivalCount || 0, 10) +
              parseInt(departureCount || 0, 10)}
          </span>
          {t("present_vehicles")}
          <span className="border py-1 px-2 rounded ml-5 mr-2">
            {parseInt(slotOccupied || 0, 10)}
          </span>
          {t("occupied_slots")}
        </div>

        <button
          className="text-white bg-[#E12714] uppercase p-3 font-semibold text-xs"
          onClick={() => setShowModal(true)}
        >
          + {t("create_reservation")}
        </button>
      </div>

      <div className="flex p-3 mb-4 items-center bg-white color-white  font-bold text-sm">
        <div className="flex-1 flex items-center text-sm" id="arrivals">
          {t("daily_flow_expected_for")}
          <Select
            suffixIcon={
              <DownOutlined style={{ fontSize: "15px", color: "white" }} />
            }
            defaultValue={t("select")}
            className=" mx-3"
            options={[
              { value: "yesterday", label: t("yesterday") },
              { value: "today", label: t("today") },
              { value: "tomorrow", label: t("tomorrow") },
            ]}
            onChange={(value) => {
              setClose(true);
              handleDateFilter(value, "days");
            }}
          />
          {t("or_for_the")}
          <span className="border bg-[#E12714] text-white py-1 px-2 rounded mx-3">
            <DatePicker
              selected={dateFilter}
              onChange={(date) => setDateFilter(date)}
              onSelect={handleDateSelect}
              customInput={<ExampleCustomInput />}
              locale={fr} // Set the French locale here
            />
          </span>
          {close && (
            <img
              src={closeBlack}
              alt="close button"
              className="cursor-pointer"
              onClick={(pageParam) => {
                dispatch(
                  getArrivalsDepartureData(
                    pageParam,
                    {},
                    moment().format("YYYY-MM-DD")
                  ),
                  setClose(false),
                  (document.querySelector(
                    ".ant-select-selection-item"
                  ).innerText = "SELECT")
                );
                dispatch(getAllSlots());
                setSelectedValue(t("all"));
              }}
            />
          )}
        </div>
        <Search
          placeholder={t("search_placeholder")}
          onSearch={onSearchHandler}
          style={{ width: 300, marginRight: "15px" }}
        />

        <Radio.Group
          options={plainOptions}
          onChange={(e) => handleArrivalDeparture(e.target.value)}
          value={selectedValue}
        />
      </div>
      <div
        style={{
          "padding-left": "1%",
          fontSize: "14px ",
          fontWeight: "bold",
          color: "red",
        }}
      >
        {t("arrivals")}
        <span
          className="text-sm"
          style={{ "padding-left": "48%", color: "red" }}
        >
          {t("departures")}
        </span>
      </div>
      <div style={{ display: "flex" }}>
        {showArrivalTable && (
          <div
            style={{
              flex: 1,
              overflow: showArrivalTable ? "auto" : "hidden",
              maxHeight: "calc(100vh - 319px)",
            }}
          >
            <Table
              id="reservation-table-1"
              columns={columns.map((column) => ({
                ...column,
                onHeaderCell: (column) => ({
                  onClick: () => handleSort(column),
                }),
              }))}
              dataSource={
                searchVal !== "" && arrivalsDeparturesData?.length !== 0
                  ? arrivalsDeparturesData
                  : arrivalData
              }
              pagination={false}
              loading={isLoading}
              onChange={handleTableChange}
              rowClassName={rowClassName}
            />
          </div>
        )}
        {showDepartureTable && (
          <div
            style={{
              flex: 1,
              overflow: showDepartureTable ? "auto" : "hidden",
              maxHeight: "calc(100vh - 319px)",
            }}
          >
            <Table
              id="reservation-table-2"
              columns={columns2.map((column) => ({
                ...column,
                onHeaderCell: (column) => ({
                  onClick: () => handleSort(column),
                }),
              }))}
              dataSource={
                searchVal !== "" && arrivalsDeparturesData?.length !== 0
                  ? arrivalsDeparturesData
                  : departureData
              }
              pagination={false}
              loading={isLoading}
              onChange={handleTableChange}
              rowClassName={rowClassName}
            />
          </div>
        )}
      </div>
      {showModal && (
        <CreateReservation
          loading={loading}
          slotsData={slotsData}
          setShowModal={setShowModal}
          showModal={showModal}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          setInitialValue={setInitialValue}
          rechargeVal={rechargeVal}
          setRechargeVal={setRechargeVal}
          handleCancelReservation={handleCancelReservation}
        />
      )}
    </>
  );
};
export default ArrivalsDepartures;
