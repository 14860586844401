import {
  UPDATE_RATES_REQUEST,
  UPDATE_RATES_SUCCESS,
  UPDATE_RATES_FALIURE,
  ADD_RATES_REQUEST,
  ADD_RATES_SUCCESS,
  ADD_RATES_FALIURE,
  GET_RATES_REQUEST,
  GET_RATES_FALIURE,
  GET_RATES_SUCCESS,
} from "../constants/rates_and_fare";

const bookingOptionData = { users: [], isLoading: false, error: null };

function getRatesFareOption(state = bookingOptionData, action) {
  switch (action.type) {
    case GET_RATES_REQUEST:
      return { users: [], isLoading: true, error: null };
    case GET_RATES_SUCCESS:
      return { users: [action.payload], isLoading: false, error: null };
    case GET_RATES_FALIURE:
      return { users: [], isLoading: false, error: action.payload };
    default:
      return state;
  }
}

function addRatesFareOption(state = bookingOptionData, action) {
  switch (action.type) {
    case ADD_RATES_REQUEST:
      return state;
    case ADD_RATES_SUCCESS:
      return action.payload;
    case ADD_RATES_FALIURE:
      return state.payload;
    default:
      return state;
  }
}

function editRatesFareOption(state = bookingOptionData, action) {
  switch (action.type) {
    case UPDATE_RATES_REQUEST:
      return state;
    case UPDATE_RATES_SUCCESS:
      return action.payload;
    case UPDATE_RATES_FALIURE:
      return action.payload;
    default:
      return state;
  }
}

export { getRatesFareOption, addRatesFareOption, editRatesFareOption };
