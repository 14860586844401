import { Col, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  getDashboardCount,
  getDashboardStatistics,
  getAmountPerPlatformVal,
  getAmountPerPlatformValPie,
} from "../../redux/actions/statistics";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Statistics = () => {
  const { t } = useTranslation();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("2023-05-01");
  const [endDate, setEndDate] = useState("2023-07-03");
  const [startDateVal, setStartDateVal] = useState("2023-05-01");
  const [endDateVal, setEndDateVal] = useState("2023-07-03");
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeAmountIndex, setActiveAmountIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(2023);
  const platformAmount = useSelector(
    (state) => state?.getPlatformDataAmount?.users[0]
  );
  const platformAmountPie = useSelector(
    (state) => state?.getPlatformDataAmountPie?.users[0]
  );
  const dashboardDataCount = useSelector(
    (state) => state?.getDashboardCountData?.users[0]
  );
  const dashboardStatisticsData = useSelector(
    (state) => state?.getDashboardStatisticsData?.users[0]
  );
  const [visibleLegendItems, setVisibleLegendItems] = useState();
  const [startIndex, setStartIndex] = useState(0);

  const [barLimit, setBarLimit] = useState(8);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const legendItems = [];

  useEffect(() => {
    dashboardStatisticsData?.forEach((item) => {
      const { _id, count } = item;
      legendItems.push({
        value: _id,
        count: count,
        color: getColor(_id),
      });
    });
  }, [dashboardStatisticsData, startIndex, selectedMonth, selectedYear]);

  useEffect(() => {
    const updatedLegendItems = [...legendItems];
    totalPlatforms.forEach((platform) => {
      const existingPlatform = updatedLegendItems.find(
        (item) => item.value === platform
      );

      if (!existingPlatform) {
        updatedLegendItems.push({
          value: platform,
          count: 0,
          color: "#0000FF",
        });
      }
    });
    dashboardStatisticsData && setVisibleLegendItems(updatedLegendItems);
  }, [dashboardStatisticsData, startIndex]);

  const totalPlatforms = [
    "Allo Pat",
    "Allo Patrk",
    "Holiday Extras",
    "Looking 4 Parking",
    "OPnGO",
    "Openpark",
    "Parclick",
    "ParkCloud",
    "Parking Premeier",
    "Parkingo",
    "Parkive",
    "Parkos",
    "TravelCar",
    "TravelCar Location",
    "Zenpark",
    "parkos",
  ];

  const monthVal = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const pieChartData = platformAmount?.map((item) => ({
    name: item?._id,
    value: item?.count,
  }));
  const pieChartAmountData = platformAmountPie?.map((item) => ({
    name: item?._id,
    value: item?.totalAmount,
  }));

  const handleMonthSelect = (value) => {
    setStartIndex(0);
    setBarLimit(8);
    setSelectedMonth(value);
  };

  const handleYearSelect = (value) => {
    setStartIndex(0);
    setBarLimit(8);
    setSelectedYear(value);
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${payload.name}. ${payload.value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieEnterAmount = (_, index) => {
    setActiveAmountIndex(index);
  };

  useEffect(() => {
    if (startDate !== "" && endDate !== "")
      dispatch(getAmountPerPlatformVal(startDate, endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDateVal !== "" && endDateVal !== "")
      dispatch(getAmountPerPlatformValPie(startDateVal, endDateVal));
  }, [startDateVal, endDateVal]);

  const handleFromDateChange = async (date, dateString) => {
    setStartDate(dateString);
    setEndDate("");
  };

  const handleToDateChange = (date, dateString) => {
    setEndDate(dateString);
  };

  const handleFromDateChangePie = async (date, dateString) => {
    setStartDateVal(dateString);
    setEndDateVal("");
  };

  const handleToDateChangePie = (date, dateString) => {
    setEndDateVal(dateString);
  };

  const startYear = 2020;
  const endYear = 2030;

  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  const getColor = (key) => {
    const colorMap = {
      "Allo Pat": "#ff00ff",
      "Allo Patrk": "#cddf4c",
      "Holiday Extras": "#4c61df",
      "Looking 4 Parking": "#fd7017",
      OPnGO: "#808000",
      Openpark: "#ffa500",
      Parclick: "#ff4500",
      ParkCloud: "#6a5acd",
      "Parking Premier": "#101caa",
      "Parking Premeier": "#ff8b00",
      Parkingo: "#8884d8",
      Parkive: "#82ca9d",
      Parkos: "#0886be",
      TravelCar: "#ee82ee",
      "TravelCar Location": "#be8c08",
      dummy: "#FF7043",
      "Holiday Express": "#63df4c",
      "Adopte un parking": "#be0824",
      Free2Move: "#f517fd",
      Zenpark: "#d88884",
    };
    if (colorMap?.hasOwnProperty(key)) {
      return colorMap[key];
    }
    return "#000000";
  };

  useEffect(() => {
    dispatch(getDashboardCount());
  }, []);

  useEffect(() => {
    if (selectedMonth !== undefined && selectedYear !== undefined) {
      setIsLoading(true);
      dispatch(getDashboardStatistics(selectedMonth, selectedYear))
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [selectedMonth, selectedYear]);

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="bg-[#081258] w-full flex p-3 text-white text-base font-semibold items-center justify-center ">
        {t("your_reservations")}
      </div>
      <div className="w-full flex p-3 items-center text-base leading-4 font-semibold justify-center">
        {t("evolution_of_reservations")}
      </div>

      <div className="w-full flex">
        <Row gutter={[16, 8]} className="w-full" style={{ margin: "0px" }}>
          <Col span={6}>
            <div className="flex flex-col">
              <span className="rounded-t-xl bg-[#081258] text-base font-semibold py-4 uppercase text-white flex justify-center">
                {t("total_period")}
              </span>
              <span className="rounded-b-xl bg-[#FF7043] text-lg font-semibold py-6 uppercase text-white flex justify-center font-bold">
                {dashboardDataCount?.totalCount ||
                dashboardDataCount?.totalCount == 0 ? (
                  dashboardDataCount?.totalCount
                ) : (
                  <Spin />
                )}
              </span>
            </div>
          </Col>
          <Col span={6}>
            <div className="flex flex-col">
              <span className="rounded-t-xl bg-[#081258] py-4 text-base font-semibold uppercase text-white flex justify-center">
                {t("last_month")}
              </span>
              <span className="rounded-b-xl bg-[#0691BD] py-6 text-lg uppercase text-white flex justify-center font-bold">
                {dashboardDataCount?.monthCount ||
                dashboardDataCount?.monthCount == 0 ? (
                  dashboardDataCount?.monthCount
                ) : (
                  <Spin />
                )}
              </span>
            </div>
          </Col>
          <Col span={6}>
            <div className="flex flex-col">
              <span className="rounded-t-xl bg-[#081258] py-4 text-base font-semibold uppercase text-white flex justify-center">
                {t("last_week")}
              </span>
              <span className="rounded-b-xl bg-[#FF434E] py-6 text-lg uppercase text-white flex justify-center font-bold">
                {dashboardDataCount?.weekCount ||
                dashboardDataCount?.weekCount == 0 ? (
                  dashboardDataCount?.weekCount
                ) : (
                  <Spin />
                )}
              </span>
            </div>
          </Col>
          <Col span={6}>
            <div className="flex flex-col">
              <span className="rounded-t-xl bg-[#081258] py-4  text-base font-semibold uppercase text-white flex justify-center">
                {t("today")}
              </span>
              <span className="rounded-b-xl bg-[#CFBC0A] py-6 uppercase text-white flex justify-center font-bold text-lg">
                {dashboardDataCount?.todayCount ||
                dashboardDataCount?.todayCount == 0 ? (
                  dashboardDataCount?.todayCount
                ) : (
                  <Spin />
                )}
              </span>
            </div>
          </Col>
        </Row>
      </div>

      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-col">
            <div
              id="booking-option"
              className="bg-white w-full .ant-select-arrow flex p-2 mb-1 items-center justify-end uppercase"
            >
              <Select
                suffixIcon={
                  <DownOutlined style={{ fontSize: "15px", color: "white" }} />
                }
                defaultValue={monthVal[currentMonth - 1]}
                className="w-99 h-33 mr-3"
                // style={{
                //   borderRadius: "3px",
                //   backgroundColor: "#E12714",
                //   color: "white",
                // }}
                onSelect={handleMonthSelect}
              >
                {monthVal.map((month, index) => (
                  <Option key={index} value={index + 1}>
                    {month}
                  </Option>
                ))}
              </Select>
              <Select
                suffixIcon={
                  <DownOutlined style={{ fontSize: "15px", color: "white" }} />
                }
                defaultValue="2023"
                className="w-89 h-33"
                // style={{
                //   borderRadius: "3px",
                //   backgroundColor: '#E12714',
                //   color: "white",
                //   marginLeft: "10px",
                // }}
                onSelect={handleYearSelect}
              >
                {years.map((year, index) => (
                  <Option key={index} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        {!isLoading ? (
          <div className="bg-white w-full flex p-3 mb-4 items-center justify-center">
            {dashboardStatisticsData ? (
              <ResponsiveContainer width="100%" height={450}>
                <BarChart
                  width={500}
                  height={300}
                  data={dashboardStatisticsData}
                  barCategoryGap={0}
                  barGap={0}
                  barSize={80}
                >
                  <XAxis
                    dataKey="_id"
                    scale="point"
                    padding={{ left: 80, right: 80 }}
                  />
                  <YAxis domain={[0, 20]} />
                  <Tooltip />
                  <Bar
                    dataKey="count"
                    fill="#081258"
                    barGap={0}
                    barCategoryGap={0}
                  >
                    {dashboardStatisticsData?.map((entry, index) => {
                      return <Cell key={`cell-${index}`} fill="#081258" />;
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div
            style={{
              height: "450px",
              width: "1253px",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Spin className="my-auto" size="large" />
          </div>
        )}

        <div className="w-full flex mb-1">
          <Row gutter={[16]} className="w-full" style={{ margin: "0px" }}>
            <Col span={12} style={{ padding: "0px 16px 0px 0px" }}>
              {!isLoading ? (
                <div className="flex flex-col">
                  <div className="bg-white w-full flex p-3 mb-1  text-base items-center justify-center font-bold">
                    {t("breakdown_reservations")}
                  </div>
                  <div className="bg-white w-full">
                    <div className="bg-white w-full flex p-3 mb-1 items-center justify-end">
                      <div className="flex items-center">
                        <span className="px-3 py-[10.5px] bg-[#E7E7E7] rounded-s uppercase">
                          {t("from")}
                        </span>
                        <DatePicker
                          onChange={handleFromDateChange}
                          className="rounded-none py-[9px]"
                        />
                        <span className="px-3 py-[10.5px] bg-[#E7E7E7] uppercase">
                          {t("to")}
                        </span>
                        <DatePicker
                          className="rounded-none py-[9px] rounded-e-lg"
                          onChange={handleToDateChange}
                        />
                      </div>
                    </div>
                    <div className="bg-white">
                      <ResponsiveContainer height={450} width="100%">
                        <PieChart>
                          {/* <Legend
                        align="left"
                        verticalAlign="bottom"
                        // content={<CustomizedLegend legendItems={legendItems} />}
                        wrapperStyle={{
                          textAlign: 'left',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                        
                      /> */}
                          <Legend
                            align="top"
                            verticalAlign="top"
                            layout="horizontal"
                          />
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={pieChartData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={110}
                            dataKey="value"
                            // label={renderCustomizedLabelVal}
                            onMouseEnter={onPieEnter}
                          >
                            {pieChartData?.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={getColor(entry?.name)}
                                displayName=""
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: "450px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Spin
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                    size="large"
                  />
                </div>
              )}
            </Col>

            <Col span={12} style={{ padding: "0px" }}>
              {!isLoading ? (
                <div className="flex flex-col">
                  <div className="bg-white w-full flex p-3 mb-1  text-base items-center justify-center font-bold ">
                    {t("billing_distribution")}
                  </div>

                  <div className="flex flex-col bg-white">
                    <div className="bg-white w-full flex p-3 mb-1 items-center justify-end">
                      <div className="flex items-center">
                        <span className="px-3 py-[10.5px] bg-[#E7E7E7] rounded-s uppercase">
                          {t("from")}
                        </span>
                        <DatePicker
                          onChange={handleFromDateChangePie}
                          className="rounded-none py-[9px]"
                        />
                        <span className="px-3 py-[10.5px] bg-[#E7E7E7] uppercase">
                          {t("to")}
                        </span>
                        <DatePicker
                          className="rounded-none py-[9px]  rounded-e-lg"
                          onChange={handleToDateChangePie}
                        />
                      </div>
                    </div>
                    <div className="bg-white">
                      <ResponsiveContainer height={450} width="100%">
                        <PieChart>
                          <Pie
                            activeIndex={activeAmountIndex}
                            activeShape={renderActiveShape}
                            data={pieChartAmountData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            // label={renderCustomizedLabel}
                            outerRadius={110}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnterAmount}
                          >
                            {pieChartAmountData?.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={getColor(entry?.name)}
                              />
                            ))}
                          </Pie>
                          <Legend
                            align="top"
                            verticalAlign="top"
                            layout="horizontal"
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: "450px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Spin
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                    size="large"
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
