import React, { useEffect, useState } from "react";
import { Form, Popconfirm, Space, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { renderComp } from "../../utils/extras";
import { getbookingOptionData } from "../../redux/actions/booking_option";
import { addRow, deleteIcon, pencil, tick } from "../../assets/icons";
import { useTranslation } from "react-i18next";

const Management = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { arrivalsDeparturesData } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [editingRow, setEditingRow] = useState({});
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
    },
  });

  const handleEdit = (record) => {
    setEditingRow(record);
  };
  const actionColumn = {
    title: <img src={addRow} alt="" />,
    dataIndex: "actions",
    sorter: false,
    render: (_, record) => {
      return (
        <Space size="middle">
          <button onClick={() => handleEdit(record)}>
            <img
              src={editingRow?.cell === record?.cell ? tick : pencil}
              alt=""
            />
          </button>
          <Popconfirm
            title={`${t("are_you_sure")} ${t("delete")}?`}
            onConfirm={() => {}}
          >
            <button>
              <img src={deleteIcon} alt="" />
            </button>
          </Popconfirm>
        </Space>
      );
    },
  };

  const columns = [
    {
      title: "Wording",
      dataIndex: "name",
      sorter: true,
      render: (name, record, i) =>
        renderComp(record, name.first + " " + name.last, editingRow),
    },
    {
      title: "Price",
      dataIndex: "name",
      sorter: true,
      render: (name, record, i) => renderComp(record, "03.80€", editingRow),
    },
    {
      title: "Editable Quantity",
      dataIndex: "immat",
      sorter: true,
      render: (name, record, i) => renderComp(record, i + 1, editingRow),
    },
    {
      title: "Daily Cost",
      dataIndex: "vehicles",
      sorter: true,
      render: (name, record, i) => renderComp(record, "11.40€", editingRow),
    },
  ];
  useEffect(() => {
    setLoading(true);
    dispatch(getbookingOptionData());
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   dispatch(clearArrivalsDeparturesData());
    // }
  };

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "" : "bg-gray-200"; // Apply gray background to odd-indexed rows
  };
  return (
    <Form>
      <Table
        id="reservation-table"
        rowKey={(record) => record.cell}
        columns={[...columns, actionColumn]}
        dataSource={arrivalsDeparturesData}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
        rowClassName={rowClassName}
      />
    </Form>
  );
};

export default Management;
