import {
  GET_COMMISSIONS_REQUEST,
  GET_COMMISSIONS_SUCCESS,
  GET_COMMISSIONS_FALIURE,
} from "../constants/commissions_constants";

const commissionData = { data: [], isLoading: false, error: null };

function getCommissionsData(state = commissionData, action) {
  switch (action.type) {
    case GET_COMMISSIONS_REQUEST:
      return { data: [], isLoading: true, error: null };
    case GET_COMMISSIONS_SUCCESS:
      return { data: [action.payload], isLoading: false, error: null };
    case GET_COMMISSIONS_FALIURE:
      return { data: [], isLoading: false, error: action.payload };
    default:
      return state;
  }
}

export { getCommissionsData };
