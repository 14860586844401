import {
  GET_BLOCK_DATES_FALIURE,
  GET_BLOCK_DATES_REQUEST,
  GET_BLOCK_DATES_SUCCESS,
  ADD_BLOCK_DATES_FALIURE,
  ADD_BLOCK_DATES_REQUEST,
  ADD_BLOCK_DATES_SUCCESS,
  UPDATE_BLOCK_DATES_FALIURE,
  UPDATE_BLOCK_DATES_REQUEST,
  UPDATE_BLOCK_DATES_SUCCESS,
  DEL_BLOCK_DATES_FALIURE,
  DEL_BLOCK_DATES_REQUEST,
  DEL_BLOCK_DATES_SUCCESS,
} from "../constants/block_dates";

const blockDatesData = { dates: [], isLoading: false, error: null };

function blockDataDates(state = blockDatesData, action) {
  switch (action.type) {
    case GET_BLOCK_DATES_REQUEST:
      return { dates: [], isLoading: true, error: null };
    case GET_BLOCK_DATES_SUCCESS:
      return { dates: [action.payload], isLoading: false, error: null };
    case GET_BLOCK_DATES_FALIURE:
      return { dates: [], isLoading: false, error: action.payload };
    default:
      return state;
  }
}

let adddata = { data: [], isLoading: false, error: null };

function addBlockDates(state = adddata, action) {
  switch (action.type) {
    case ADD_BLOCK_DATES_REQUEST:
      return state;
    case ADD_BLOCK_DATES_SUCCESS:
      return action.payload;
    case ADD_BLOCK_DATES_FALIURE:
      return state;
    default:
      return state;
  }
}

function updateBlockDates(state = adddata, action) {
  switch (action.type) {
    case UPDATE_BLOCK_DATES_REQUEST:
      return state;
    case UPDATE_BLOCK_DATES_SUCCESS:
      return action.payload;
    case UPDATE_BLOCK_DATES_FALIURE:
      return state;
    default:
      return state;
  }
}

function deleteBlockDates(state = adddata, action) {
  switch (action.type) {
    case DEL_BLOCK_DATES_REQUEST:
      return state;
    case DEL_BLOCK_DATES_SUCCESS:
      return action.payload;
    case DEL_BLOCK_DATES_FALIURE:
      return state;
    default:
      return state;
  }
}

export { blockDataDates, addBlockDates, updateBlockDates, deleteBlockDates };
