import { get, isEmpty } from "lodash";

export const base_url = "https://jauklz6la5.execute-api.us-east-1.amazonaws.com"; //dev-client
// export const base_url = "https://6f57bopg93.execute-api.eu-north-1.amazonaws.com"; // dev
// export const base_url = "http://192.168.1.85:5000"; //local

const admin_url = "api/admin";

export const makeGetAllBookingOption = (params, extraParams) => {
  let url = `${base_url}/${admin_url}/getAllReservations?location=ORLY&page=${get(
    params,
    "pagination.current",
    1
  )}&limit=${params.pagination.pageSize}`;
  if (
    !isEmpty(extraParams) &&
    get(extraParams, "dateFilter", "") !== "" &&
    get(extraParams, "parkingType", "") !== ""
  ) {
    return `${url}&arrivalDate=${get(
      extraParams,
      "dateFilter",
      ""
    )}&parkingType=${get(extraParams, "dateFilter", "")}`;
  }
  if (
    !isEmpty(extraParams) &&
    get(extraParams, "dateFilter", "") !== "" &&
    get(extraParams, "parkingType", "") === ""
  ) {
    return `${url}&arrivalDate=${get(
      extraParams,
      "dateFilter",
      ""
    )}&departureDate=${get(extraParams, "dateFilter", "")}`;
  }
  if (
    !isEmpty(extraParams) &&
    get(extraParams, "dateFilter", "") === "" &&
    get(extraParams, "parkingType", "") !== ""
  ) {
    return `${url}&parkingType=${get(extraParams, "dateFilter", "")}`;
  }
  return url;
};
export const getAllSlot = `${base_url}/${admin_url}/getAllSlot?location=ORLY`;
export const getAllAvailableSlots = `${base_url}/${admin_url}/getAllAvailableSlot?location=ORLY`;
export const postAllBookingOption = `${base_url}/${admin_url}/addReservation`;
export const deleteAllBookingOption = `${base_url}/${admin_url}/deleteReservation`;
export const updateAllBookingOption = `${base_url}/${admin_url}/updateReservation`;
export const makeGetAllRatesAndFare = (params) => {
  if (params !== undefined) {
    return `${base_url}/${admin_url}/getAllRatesPerDay?location=ORLY&page=${get(
      params,
      "pagination.current",
      1
    )}&limit=30&category=${get(params, "category", "RESERVATION")}`;
  } else {
    return `${base_url}/${admin_url}/getAllRatesPerDay?location=ORLY&limit=30&category=${get(
      params,
      "category",
      "RESERVATION"
    )}`;
  }
};

// Arrivals Departures
export const makeGetArrivalDepartureData = (
  params,
  extraParams,
  date,
  search,
  isAccending,
  sortBy
) => {
  let url = `${base_url}/${admin_url}/getAllReservations?location=ORLY`;
  if (
    !isEmpty(extraParams) &&
    get(extraParams, "arrivalOnly", false) === true
  ) {
    return `${url}&arrivalDate=${date}`;
  }
  if (
    !isEmpty(extraParams) &&
    get(extraParams, "departureOnly", false) === true
  ) {
    return `${url}&departureDate=${date}`;
  }
  if (search !== "" && search !== undefined) {
    return `${url}&search=${search}`;
  }
  if (
    isAccending !== null &&
    sortBy !== "" &&
    isAccending !== undefined &&
    sortBy !== undefined
  ) {
    return `${url}&arrivalDate=${date}&departureDate=${date}${
      isAccending ? "&isAccending=true" : ""
    }&sortBy=${sortBy}`;
  }
  return `${url}&arrivalDate=${date}&departureDate=${date}`;
};

export const updateAllRatesAndFareOption = `${base_url}/${admin_url}/updateAllRatesPerDay`;
export const addRatesAndFareOption = `${base_url}/${admin_url}/addRatesPerDay`;
export const deleteRatesAndFareOption = `${base_url}/${admin_url}/deleteRatesPerDay`;

// Drivers And Staff
export const getAllDriver = (params) =>
  `${base_url}/${admin_url}/getAllDriver?location=ORLY&page=${get(
    params,
    "pagination.current",
    1
  )}`;
export const addDriver = (params) => `${base_url}/${admin_url}/addDriver`;
export const deleteDriver = (params) => `${base_url}/${admin_url}/deleteDriver`;
export const updateDriver = (params) => `${base_url}/${admin_url}/updateDriver`;

//login
export const addLogin = `${base_url}/${admin_url}/login`;
export const FORGOT_PASSWORD = `${base_url}/${admin_url}/forgotPassword`;
export const RESET_PASSWORD = `${base_url}/${admin_url}/forgotChangePassword`;
//user
export const userData = (params, searchQuery, role) => {
  const url = `${base_url}/${admin_url}/getAllUsers?location=ORLY`;

  if (searchQuery !== undefined && searchQuery.trim() !== "") {
    return `${url}&search=${searchQuery}`;
  } else if (!isEmpty(params) && role === undefined) {
    return `${url}&page=${get(params, "pagination.current", 1)}&limit=${
      params.pagination.pageSize
    }`;
  } else if (role !== undefined && role !== "ALL") {
    return `${url}&page=${get(params, "pagination.current", 1)}&limit=${
      params.pagination.pageSize
    }&role=${role}`;
  } else if (!isEmpty(params) && role === "ALL") {
    return `${url}&page=${get(params, "pagination.current", 1)}&limit=${
      params.pagination.pageSize
    }`;
  }

  // return '';
};

///Block Dates
export const getAllBockDates = (params) =>
  `${base_url}/${admin_url}/getAllLockReservation?location=ORLY`;
export const addBockDates = (params) =>
  `${base_url}/${admin_url}/addLockReservation`;
export const updateBockDates = (params) =>
  `${base_url}/${admin_url}/updateLockReservation`;
export const deleteLockReservation = (params) =>
  `${base_url}/${admin_url}/deleteLockReservation`;

export const userDelete = (params) => `${base_url}/${admin_url}/deleteUser`;
export const userAdd = (params) => `${base_url}/${admin_url}/addUser`;
export const updateUser = (params) => `${base_url}/${admin_url}/updateUser`;
export const uploadImage = (params) => `${base_url}/${admin_url}/upload`;

// Commissions
export const getAllCommissionsData = (params) =>
  `${base_url}/${admin_url}/getAllCommission?location=ORLY&page=${get(
    params,
    "pagination.current",
    1
  )}`;
export const ADDCOMMISSIONDATA = `${base_url}/${admin_url}/addCommission`;
export const UPDATECOMMISSIONDATA = `${base_url}/${admin_url}/updateCommission`;
export const DELETECOMMISSIONDATA = `${base_url}/${admin_url}/deleteCommission`;

//statistics

export const dashBoardCount = `${base_url}/${admin_url}/getDashboardCount?location=ORLY`;
export const dashboardStatistics = (month, year) =>
  `${base_url}/${admin_url}/getDashboardStatistic?location=ORLY&month=1&year=${year}`;
export const getAmountPerPlatform = (fromDate, toDate) =>
  `${base_url}/${admin_url}/getAmountPerPlatform?location=ORLY&startDate=${fromDate}&endDate=${toDate}`;
export const getAmountPerPlatformPie = (fromDate, toDate) =>
  `${base_url}/${admin_url}/getAmountPerPlatform?location=ORLY&startDate=${fromDate}&endDate=${toDate}`;

//Diary
export const getAllDiaryData = (params) =>
  `${base_url}/${admin_url}/getAllDiary?location=ORLY`;
// Login User Details
export const GET_LOGIN_USER_DATA = `${base_url}/${admin_url}/getAdminProfile?location=ORLY`;
export const GET_ALL_HOLIDAYS = `${base_url}/${admin_url}/getAllHoliday?location=ORLY`;
export const ADD_HOLIDAY_DATA = `${base_url}/${admin_url}/addHoliday`;
export const UPDATE_HOLIDAY_DATA = `${base_url}/${admin_url}/updateHoliday`;
export const DELETE_HOLIDAY_DATA = `${base_url}/${admin_url}/deleteHoliday`;

export const GET_INCREASE_PER_DAY = `${base_url}/${admin_url}/getIncreasePerDay?location=ORLY`;

//RATE AND FEES > PROMOCODES

export const GET_ALL_PROMOCODES = `${base_url}/${admin_url}/getAllPromocode?location=ORLY`;
export const ADD_PROMOCODE = `${base_url}/${admin_url}/addPromocode`;
export const UPDATE_PROMOCODE = `${base_url}/${admin_url}/updatePromocode`;
export const DELETE_PROMOCODE = `${base_url}/${admin_url}/deletePromocode`;
