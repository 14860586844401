import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivateRoute from "./privateRoutes";
import Layout from "../common/layout";
import Login, { ForgotPassword, ResetPassword } from "../components/auth/login";
import ArrivalsDepartures from "../components/arrivals_departures";
import RatesFees from "../components/rates_fees";
import DriverAndStaff from "../components/driver_and_staff";
import BookingOption from "../components/booking_option";
import Commissions from "../components/commissions";
import Management from "../components/management";
import Statistics from "../components/statistics";
import Users from "../components/users";
import UserProfile from "../components/users/UserProfile";
import ErrorPage from "../common/layout/ErrorPage";
import Diary from "../components/diary";
import GPSModule from "../components/gps_module";
import ParkingMap from "../components/parking_map";

const LayoutComp = ({ component, title }) => (
  <PrivateRoute>
    <Layout component={component} title={title} />
  </PrivateRoute>
);
const authRoutes = [
  {
    path: "/login",
    element: <Login />,
    // and renders this element in case something went wrong
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    // and renders this element in case something went wrong
    errorElement: <ErrorPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    // and renders this element in case something went wrong
    errorElement: <ErrorPage />,
  },

  {
    // and renders this element in case something went wrong
    component: <ErrorPage />,
  },
];

const appRoutes = [
  {
    path: "/dashboard",
    element: (
      <LayoutComp
        component={<ArrivalsDepartures />}
        title="ARRIVALS / DEPARTURES"
      />
    ),
  },
  {
    path: "/",
    element: (
      <LayoutComp
        component={<ArrivalsDepartures />}
        title="ARRIVALS / DEPARTURES"
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/rates_fees",
    element: <LayoutComp component={<RatesFees />} title="RATES / FEES" />,
  },
  {
    path: "/gps_module",
    element: <LayoutComp component={<GPSModule />} title="GPS" />,
  },
  {
    path: "/shuttle",
    element: (
      <LayoutComp component={<div> Coming soon...</div>} title="SHUTTLE" />
    ),
  },
  {
    path: "/parking_map",
    element: <LayoutComp component={<ParkingMap />} title="PARKING MAP" />,
  },
  {
    path: "/booking_option",
    element: (
      <LayoutComp component={<BookingOption />} title="BOOKING OPTION" />
    ),
  },
  {
    path: "/driver_and_staff",
    element: (
      <LayoutComp component={<DriverAndStaff />} title="DRIVER AND STAFF" />
    ),
  },
  {
    path: "/diary",
    element: <LayoutComp component={<Diary />} title="DIARY" />,
  },
  {
    path: "/commissions",
    element: <LayoutComp component={<Commissions />} title="COMMISSIONS" />,
  },
  {
    path: "/management",
    element: <LayoutComp component={<Management />} title="MANAGEMENT" />,
  },
  {
    path: "/statistics",
    element: <LayoutComp component={<Statistics />} title="STATISTICS" />,
  },
  {
    path: "/clients",
    element: <LayoutComp component={<Users />} title="CLIENTS" />,
  },
  {
    path: "/user_profile",
    element: <LayoutComp component={<UserProfile />} title="USER PROFILE" />,
  },
];

const router = createBrowserRouter([...authRoutes, ...appRoutes]);
const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
