const commissionColors = [
  {"Parking Premeier": "#E41290"},
  {"Allo Patrk": "#E41245"},
  {"Parkos": "#0B4BAB"},
  {"parkos": "#0B4BAB"},
  {"Holiday Extras": "#8A07C8"},
  {"Parkive": "#024549"},
  {"Openpark": "#058B63"},
  {"TravelCar": "#03594F"},
  {"Adopte un parking": "#CE7F08"},
  {"OPnGO": "#040870"},
  {"Parkingo": "#047686"},
  {"Looking 4 Parking": "#1B4788"},
  {"Parclick": " #A9AC06"},
  {"Zenpark": "#08A818"},
  {"TravelCar Location": "#F34E1A"},
  {"ParkCloud": "#03993F"},
  {"Allo Pat": "#E41245"},
];
export default commissionColors;