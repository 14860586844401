
// Booking option constants
export const ADD_BOOKING_REQUEST="ADD_BOOKING_REQUEST";
export const ADD_BOOKING_SUCCESS="ADD_BOOKING_SUCCESS";
export const ADD_BOOKING_FALIURE="ADD_BOOKING_FALIURE";

// Booking option constants
export const GET_BOOKING_REQUEST="GET_BOOKING_REQUEST";
export const GET_BOOKING_SUCCESS="GET_BOOKING_SUCCESS";
export const GET_BOOKING_FALIURE="GET_BOOKING_FALIURE";

// Booking option constants
export const DELETE_BOOKING_REQUEST="DELETE_BOOKING_REQUEST";
export const DELETE_BOOKING_SUCCESS="DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_FALIURE="DELETE_BOOKING_FALIURE";

export const GET_SLOTS_REQUEST="GET_SLOTS_REQUEST";
export const GET_SLOTS_SUCCESS="GET_SLOTS_SUCCESS";
export const GET_SLOTS_FALIURE="GET_SLOTS_FALIURE";