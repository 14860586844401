import {
  GET_COMMISSIONS_REQUEST,
  GET_COMMISSIONS_SUCCESS,
  GET_COMMISSIONS_FALIURE,
  ADD_COMMISSIONS_REQUEST,
  ADD_COMMISSIONS_FALIURE,
  DELETE_COMMISSIONS_REQUEST,
  DELETE_COMMISSIONS_FALIURE,
} from "../../constants/commissions_constants";
import {
  getAllCommissionsData,
  ADDCOMMISSIONDATA,
  DELETECOMMISSIONDATA,
  UPDATECOMMISSIONDATA,
} from "../../../urls";
import axios from "../../../utils/axios";
import { get } from "lodash";
import { getPayload } from "../../../utils/extras";

export const getAllCommissionPlateformData = (tableParams) => {
  return async (dispatch) => {
    dispatch({ type: GET_COMMISSIONS_REQUEST });
    try {
      const result = await axios.get(getAllCommissionsData(tableParams));
      dispatch({ type: GET_COMMISSIONS_SUCCESS, payload: result.data?.data });
    } catch (err) {
      dispatch({ type: GET_COMMISSIONS_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const addCommissionOption = (payload) => {
  return async (dispatch) => {
    dispatch({ type: ADD_COMMISSIONS_REQUEST });
    try {
      const result = await axios.post(ADDCOMMISSIONDATA, getPayload(payload));
      return get(result, "data", {});
      // dispatch({ type: ADD_COMMISSIONS_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: ADD_COMMISSIONS_FALIURE, payload: err });
      console.log(err);
      return err;
    }
  };
};

export const EditCommissionOption = (payload) => {
  return async (dispatch) => {
    dispatch({ type: ADD_COMMISSIONS_REQUEST });
    try {
      const result = await axios.put(UPDATECOMMISSIONDATA, getPayload(payload));
      return get(result, "data", {});
      // dispatch({ type: ADD_COMMISSIONS_SUCCESS, payload: result });
    } catch (err) {
      return get(err, "response", {});
      // dispatch({ type: ADD_COMMISSIONS_FALIURE, payload: err });
    }
  };
};

export const deleteCommissions = (payload) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_COMMISSIONS_REQUEST });
    try {
      const result = await axios.delete(DELETECOMMISSIONDATA, {
        data: payload,
      });
      return get(result, "data", {});
      // dispatch({ type: DELETE_COMMISSIONS_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: DELETE_COMMISSIONS_FALIURE, payload: err });
      console.log("delete-Error", err);
    }
  };
};
