import {
  GET_ARRIVALS_DEPARTURES_REQUEST,
  GET_ARRIVALS_DEPARTURES_SUCCESS,
  GET_ARRIVALS_DEPARTURES_FALIURE,
} from "../constants/arrivals_departures";

const arrivalsDepartureData = { data: [], isLoading: false, error: null };

function getArrivalDeparture(state = arrivalsDepartureData, action) {
  switch (action.type) {
    case GET_ARRIVALS_DEPARTURES_REQUEST:
      return { data: [], isLoading: true, error: null };
    case GET_ARRIVALS_DEPARTURES_SUCCESS:
      return { data: [action.payload], isLoading: false, error: null };
    case GET_ARRIVALS_DEPARTURES_FALIURE:
      return { data: [], isLoading: false, error: action.payload };
    default:
      return state;
  }
}

export { getArrivalDeparture };
