import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";

const lngs = {
  fr: { nativeName: "French" },
  en: { nativeName: "English" },
};
export default function ErrorPage() {
  const { t, i18n } = useTranslation();
  const error = useRouteError();
  console.error(error);

  return (
    <div className="flex items-center justify-center h-screen">
      <Row className="w-screen">
        <Col span={12} className="h-screen bg-[#081258]"></Col>
        <Col span={12} className="h-screen flex items-center justify-center">
          <div className="pl-3">
            <div className="flex justify-end gap-3 ml-auto mb-10 absolute top-3 right-3">
              {Object.keys(lngs).map((lng) => (
                <button
                  key={lng}
                  style={{
                    fontWeight:
                      i18n.resolvedLanguage === lng ? "bold" : "normal",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    i18n.changeLanguage(lng);
                  }}
                >
                  <img
                    src={`/icons/${lng === "fr" ? "fr" : "en"}.png`}
                    alt=""
                    className="w-6 inline-block"
                  />{" "}
                  {lngs[lng].nativeName}
                </button>
              ))}
            </div>
            <h1 className="text-6xl font-bold text-gray-800 mb-5">
              {t("oops")}
            </h1>
            <p className="text-gray-600 text-3xl">{t("error_page")}.</p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
