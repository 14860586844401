import {
  getBookingOption,
  getTotalSlot,
  addBookingOption,
  editBookingOption,
} from "./bookingOption";
import {
  getRatesFareOption,
  addRatesFareOption,
  editRatesFareOption,
} from "./ratesFare";
import { getArrivalDeparture } from "./arrivalsDeparture";
import { getCommissionsData } from "./commissionReducer";
import { getDiaryData } from "./diaryReducer";
import {
  addDriverAndStaff,
  getDriverAndStaff,
  deleteDriverAndStaff,
  updateDriverAndStaff,
} from "./driverStaffReducer";
import { loginUser, forgetPassword, resetPassword } from "./login";
import { combineReducers } from "redux";
import {
  getUsersData,
  deleteDataOfUsers,
  addDataOfUsers,
  updateDataOfUsers,
  uploadProfilePictureData,
} from "./users";
import {
  getDashboardCountData,
  getDashboardStatisticsData,
  getPlatformDataAmount,
  getPlatformDataAmountPie,
} from "./statistics";
import { getHolidays } from "./holidays";
import {
  blockDataDates,
  addBlockDates,
  updateBlockDates,
  deleteBlockDates,
} from "./blockDates";
import { promocodeReducer } from "./promocode";

const rootReducer = combineReducers({
  getBookingOption: getBookingOption,
  getTotalSlot: getTotalSlot,
  addBookingOption: addBookingOption,
  editBookingOption: editBookingOption,
  getRatesFareOption: getRatesFareOption,
  addRatesFareOption: addRatesFareOption,
  editRatesFareOption: editRatesFareOption,
  getArrivalDeparture: getArrivalDeparture,
  getCommissionsData: getCommissionsData,
  getDiaryData: getDiaryData,
  getDriverAndStaff: getDriverAndStaff,
  addDriverAndStaff: addDriverAndStaff,
  deleteDriverAndStaff: deleteDriverAndStaff,
  updateDriverAndStaff: updateDriverAndStaff,
  loginUser: loginUser,
  forgetPassword: forgetPassword,
  resetPassword: resetPassword,
  getUsersData: getUsersData,
  deleteDataOfUsers: deleteDataOfUsers,
  addDataOfUsers: addDataOfUsers,
  updateDataOfUsers: updateDataOfUsers,
  uploadProfilePictureData: uploadProfilePictureData,
  getDashboardCountData: getDashboardCountData,
  getDashboardStatisticsData: getDashboardStatisticsData,
  getPlatformDataAmount: getPlatformDataAmount,
  getPlatformDataAmountPie: getPlatformDataAmountPie,
  getHolidays: getHolidays,
  blockDataDates: blockDataDates,
  addBlockDates: addBlockDates,
  updateBlockDates: updateBlockDates,
  deleteBlockDates: deleteBlockDates,
  promocodes: promocodeReducer,
});
export default rootReducer;
