import axios from "../../../utils/axios";
import {
  getAllBockDates,
  addBockDates,
  updateBockDates,
  deleteLockReservation,
} from "../../../urls";
import {
  GET_BLOCK_DATES_FALIURE,
  GET_BLOCK_DATES_REQUEST,
  GET_BLOCK_DATES_SUCCESS,
  ADD_BLOCK_DATES_FALIURE,
  ADD_BLOCK_DATES_REQUEST,
  ADD_BLOCK_DATES_SUCCESS,
  UPDATE_BLOCK_DATES_FALIURE,
  UPDATE_BLOCK_DATES_REQUEST,
  UPDATE_BLOCK_DATES_SUCCESS,
  DEL_BLOCK_DATES_FALIURE,
  DEL_BLOCK_DATES_REQUEST,
  DEL_BLOCK_DATES_SUCCESS,
} from "../../constants/block_dates";


export const getAllBlockDatesData = (params, extraParams) => {
  return async (dispatch) => {
    dispatch({ type: GET_BLOCK_DATES_REQUEST });
    try {
      const result = await axios.get(getAllBockDates(params));
      dispatch({ type: GET_BLOCK_DATES_SUCCESS, payload: result.data?.data });
    } catch (err) {
      dispatch({ type: GET_BLOCK_DATES_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const addBlockDatesData = (params) => {
  return async (dispatch) => {
    dispatch({ type: ADD_BLOCK_DATES_REQUEST });
    try {
      const result = await axios.post(addBockDates(), params);
      if (result.status !== 200) {
        throw new Error(`API request failed with status ${result.status}`);
      }
      dispatch({ type: ADD_BLOCK_DATES_SUCCESS, payload: result.data?.data });
      return result;
    } catch (err) {
      dispatch({ type: ADD_BLOCK_DATES_FALIURE, payload: err });
      return err?.response?.data?.statusCode;
    }
  };
};

export const updateBlockDatesData = (params) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_BLOCK_DATES_REQUEST });
    try {
      const result = await axios.put(updateBockDates(), params);
      dispatch({
        type: UPDATE_BLOCK_DATES_SUCCESS,
        payload: result.data?.data,
      });
    } catch (err) {
      dispatch({ type: UPDATE_BLOCK_DATES_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const deleteBlockDatesData = (params) => {
  return async (dispatch) => {
    dispatch({ type: DEL_BLOCK_DATES_REQUEST });
    try {
      const result = await axios.delete(deleteLockReservation(), {
        data: params,
      });
      dispatch({ type: DEL_BLOCK_DATES_SUCCESS, payload: result.data?.data });
    } catch (err) {
      dispatch({ type: DEL_BLOCK_DATES_FALIURE, payload: err });
      console.log(err);
    }
  };
};
