import React, { useEffect, useState } from "react";
import { deleteIcon, pencil } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Input, Popconfirm, Space, Table } from "antd";
import { getBgColor } from "../../utils/extras";
import { getUserData, deleteUserData } from "../../redux/actions/user";
import { Select } from "antd";
import AddUserModal from "./AddUserModal";
import { DownOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Search } = Input;

const Users = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getUsersData } = useSelector((state) => state);
  const getAllDataOfUsers = getUsersData?.data[0]?.results;
  const isLoading = getUsersData?.isLoading;
  const [showUserModal, setShowUserModal] = useState(false);
  const [userData, setUsertData] = useState([]);
  const [editingRow, setEditingRow] = useState({});
  const [check, setCheck] = useState();
  const [initialValues, setInitialValues] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [totalVal, setTotalVal] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [roleval, setRoleVal] = useState();
  const { Option } = Select;
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 20,
    },
  });
  const onSearch = async () => {};

  const columns = [
    {
      title: "Username",
      dataIndex: "userName",
      sorter: true,
      render: (userName) => userName,
    },
    {
      title: "Name",
      dataIndex: "firstName",
      sorter: true,
      render: (name, record, i) => (
        <>
          {!record?.image ? (
            <Avatar
              style={{
                backgroundColor: getBgColor(i),
                marginRight: "0.5rem",
                textTransform: "uppercase",
              }}
            >
              {name[0]}
              {record?.lastName[0]}
            </Avatar>
          ) : (
            <Avatar
              style={{ marginRight: "0.5rem" }}
              src={record?.image}
              alt={
                !record?.image ? `${name[0]} ${record?.lastName[0]}` : undefined
              }
            ></Avatar>
          )}

          {`${name} ${record?.lastName}`}
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
      render: (email) => email,
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: true,
      render: (role) => role,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (status, record) => (
        <span
          className={
            record.isActive === true ? "text-green-500" : "text-red-500"
          }
        >
          {record.isActive === true ? "Pending" : "Rejected"}
        </span>
      ),
    },
  ];

  const handleEdit = (record) => {
    setEditMode(true);
    setEditingRow(record);
    setShowUserModal(true);
    setInitialValues({ record });
  };

  const handleDeleteUser = async (record) => {
    let userId = {
      userId: record.id,
    };
    await dispatch(deleteUserData(userId));
    dispatch(getUserData(tableParams));
  };

  const actionColumn = {
    title: "Action",
    dataIndex: "actions",
    sorter: false,
    render: (_, record) => {
      return (
        <Space size="middle">
          <button onClick={() => handleEdit(record)}>
            <img src={pencil} alt="" />
          </button>
          <Popconfirm
            id="custom-delete-dialog"
            title={`${t("are_you_sure")} ${t("delete")}?`}
            onConfirm={() => {
              handleDeleteUser(record);
            }}
          >
            <button>
              <img src={deleteIcon} alt="" />
            </button>
          </Popconfirm>
        </Space>
      );
    },
  };

  useEffect(() => {
    dispatch(getUserData(tableParams));
  }, []);

  useEffect(() => {
    if (roleval !== undefined && searchText === "") {
      if (roleval && tableParams) {
        dispatch(getUserData(tableParams, "", roleval));
      }
    }
  }, [tableParams, roleval, searchText]);

  useEffect(() => {
    if (getAllDataOfUsers && getUsersData?.data[0]?.itemCount) {
      setUsertData(getAllDataOfUsers);
      setTotalVal(getUsersData?.data[0]?.itemCount);
    }
  }, [getAllDataOfUsers, roleval]);

  useEffect(() => {
    if (check) {
      dispatch(getUserData(tableParams));
    }
  }, [check]);

  useEffect(() => {
    // if (totalVal) {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: totalVal,
      },
    });
    // }
  }, [totalVal]);

  useEffect(() => {
    if (searchText === "" && roleval === undefined) {
      dispatch(getUserData(tableParams));
    }
  }, [tableParams]);

  useEffect(() => {
    if (searchText !== "") dispatch(getUserData({}, searchText));
  }, [searchText]);

  const handleRoleChange = (role) => {
    setSearchText("");
    setRoleVal(role);
    dispatch(getUserData({ tableParams }, "", role));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination: {
        ...tableParams.pagination,
        current: pagination.current, // Update the current page
        pageSize: pagination.pageSize, // Update the page size
      },
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   dispatch(clearArrivalsDeparturesData());
    // }
  };

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "" : "bg-gray-200"; // Apply gray background to odd-indexed rows
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div id="user-option">
      <div
        id="custom-search"
        className="bg-[#081258] flex p-3 mb-4 text-white items-center"
      >
        <div className="flex-1 flex items-center text-base">
          {t("all_users")}
          <button
            className="flex border-l-2 border-gray-400 ml-4 items-center"
            onClick={() => {
              setShowUserModal(true);
              setInitialValues({
                ...initialValues,
                record: {
                  ...initialValues.record,
                  image: "",
                },
              });
              setCheck(false);
              setEditMode(false);
              setImageUrl(null);
            }}
          >
            <PlusCircleOutlined className="mx-2 ml-4 text-lg" /> {t("add_user")}
          </button>
          <Select
            suffixIcon={
              <DownOutlined style={{ fontSize: "15px", color: "white" }} />
            }
            defaultValue="ALL"
            style={{ width: 120, marginLeft: "10px" }}
            onChange={handleRoleChange}
          >
            <Option value="ALL">{t("all")}</Option>
            <Option value="USER">USER</Option>
            <Option value="ADMIN">ADMIN</Option>
          </Select>
        </div>
        <Search
          placeholder="search..."
          onSearch={() => onSearch}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: 200, background: "white" }}
        />
      </div>

      {/* Table Section */}
      <Table
        id="user-table"
        columns={[...columns, actionColumn]}
        dataSource={userData}
        pagination={tableParams.pagination}
        loading={isLoading}
        onChange={handleTableChange}
        rowClassName={rowClassName}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />

      {/* Modal Section */}
      <AddUserModal
        setShowUserModal={setShowUserModal}
        showUserModal={showUserModal}
        getUserData={getUserData}
        setCheck={setCheck}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        editMode={editMode}
        setEditMode={setEditMode}
        tableParams={tableParams}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
      />
    </div>
  );
};

export default Users;
