import React from "react";

export default function Slot({ slotId, rowId }) {
  return (
    <div className="w-7 h-7 bg-slate-300 rounded flex justify-center items-center font-semibold text-slate-600 hover:bg-slate-400 hover:text-white cursor-pointer relative">
      {slotId}
      <span className="absolute top-0 right-0.5 text-[6px]">{rowId}</span>
    </div>
  );
}
