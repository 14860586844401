import { useEffect, useRef, useState } from "react";
import { Col, Form, Input, Modal, Row, notification, Button } from "antd";
import { pencil_white } from "../../assets/icons";
import { addUserData } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import {
  updteUserData,
  getUserData,
  uploadProfilePicture,
} from "../../redux/actions/user";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

const s = {
  modalHeader:
    "bg-[#E12714] justify-center text-2xl font-bold flex text-white uppercase p-4",
  image: "w-[122px] h-[122px] rounded-full shadow-md",
  editIcon:
    "w-6 h-auto shadow-md absolute right-0 bottom-3 text-white p-1 rounded-full bg-[#E12714]",
  button:
    "text-white uppercase px-4 py-2 items-center flex justify-center text-sm font-bold",
  row1: "bg-[#F9F9F9] py-4 w-full pl-6 pr-2",
};
const AddUserModal = ({
  setShowUserModal,
  showUserModal,
  setCheck,
  initialValues,
  setInitialValues,
  editMode,
  tableParams,
  imageUrl,
  setImageUrl,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const url = URL.createObjectURL(file);
    setImageUrl(url);

    if (file) {
      try {
        const formData = new FormData();
        formData.append("image", file);

        const result = await dispatch(uploadProfilePicture(formData));
        setSelectedFile(get(result, "data.orignal", ""));
      } catch (error) {}
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };
  const cancelModal = () => {
    setImageUrl(null);
    setShowUserModal(false);
  };
  return (
    <Modal
      open={showUserModal}
      width={678}
      footer={null}
      onCancel={cancelModal}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        className="hidden"
        ref={fileInputRef}
      />
      <div className={s.modalHeader}>
        {editMode ? t("edit_user") : t("add_user")}
      </div>
      <div className="flex flex-col gap-3 items-center bg-[#F9F9F9] pt-5">
        <div className="relative">
          <img
            src={imageUrl || get(initialValues.record, "image", "")}
            alt=""
            className={s.image + ""}
          />
          <img
            src={pencil_white}
            alt="user"
            className={s.editIcon}
            onClick={handleBrowseClick}
          />
        </div>
        <button
          className={s.button + " bg-[#E12714] rounded-md"}
          onClick={handleBrowseClick}
        >
          {t("upload_image")}
        </button>
      </div>
      <UserFormComp
        setShowUserModal={setShowUserModal}
        setCheck={setCheck}
        showUserModal={showUserModal}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        editMode={editMode}
        dispatch={dispatch}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        setImageUrl={setImageUrl}
        tableParams={tableParams}
        loading={loading}
        setLoading={setLoading}
      />
    </Modal>
  );
};

const UserFormComp = ({
  setShowUserModal,
  setCheck,
  showUserModal,
  initialValues,
  editMode,
  dispatch,
  selectedFile,
  setImageUrl,
  tableParams,
  setSelectedFile,
  loading,
  setLoading,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (editMode) {
      form.setFieldsValue(initialValues.record);
    } else {
      form.resetFields();
    }
  }, [editMode, form, initialValues]);

  const addUserHandle = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const payload = {
        email: values.email,
        userName: values.userName,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        role: "ADMIN",
        image: selectedFile || "",
      };
      const result = await dispatch(addUserData(payload));
      if (result.statusCode === 200) {
        setLoading(false);
        notification.success({
          placement: "topRight",
          message: "User Added Succesfully",
        });
        setShowUserModal(false);
        form.resetFields();
        setImageUrl("");
      } else if (
        result.statusCode === undefined &&
        result?.response?.status !== 400
      ) {
        notification.error({
          placement: "topRight",
          message: "User was not added",
        });
        setShowUserModal(false);
        form.resetFields();
        setLoading(false);
      } else if (result?.response?.status === 400) {
        notification.error({
          placement: "topRight",
          message: result?.response?.data?.message,
        });
        setShowUserModal(false);
        form.resetFields();
        setLoading(false);
      } else {
        notification.error("User was not added");
        setShowUserModal(false);
        form.resetFields();
        setLoading(false);
      }
      setCheck(true);
    } catch (error) {}
  };

  const editUserHandle = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();

      const payload = {
        ...Object.keys(values).reduce((obj, item) => {
          if (
            values[item] &&
            ["firstName", "lastName", "email", "password", "userName"].includes(
              item
            )
          ) {
            obj[item] = values[item];
          }
          return obj;
        }, {}),
        userId: initialValues?.record?._id,

        image: selectedFile ? selectedFile : initialValues?.record?.image,
      };
      const result = await dispatch(updteUserData(payload));

      if (result.statusCode === 200) {
        setLoading(false);
        notification.success({
          placement: "topRight",
          message: "User Added Succesfully",
        });
        setShowUserModal(false);
        form.resetFields();
        setImageUrl("");
        setSelectedFile(null);
      } else if (result.statusCode === undefined) {
        notification.error("User was not added");
        setShowUserModal(false);
        form.resetFields();
      } else {
        notification.error("User was not added");
      }
      dispatch(getUserData(tableParams));
    } catch (error) {}
  };
  const validateNames = (_, value) => {
    // Check if the value is a number
    if (!isNaN(value)) {
      return Promise.reject("Cannot be a number");
    }

    // Check if the value contains any digits
    if (/\d/.test(value)) {
      return Promise.reject("Cannot contain numbers");
    }

    return Promise.resolve();
  };

  const validateEmail = (_, value) => {
    // Check if the value is empty
    if (!value) {
      return Promise.reject("Email is required");
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(value)) {
      return Promise.reject("Invalid email format");
    }
    return Promise.resolve();
  };

  return (
    <Form layout="vertical" className="w-full" form={form}>
      <div className="flex flex-col">
        <div className={s.row1}>
          <Row gutter={[16]} className="w-full">
            <Col span={12}>
              <Form.Item label="Username" name="userName">
                <Input
                  placeholder="Enter your username"
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Password" name="password">
                <Input
                  type="password"
                  placeholder={"Enter your password"}
                  className="rounded-none"
                  disabled={editMode}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("firstname")}
                name="firstName"
                rules={[{ validator: validateNames }]}
              >
                <Input
                  placeholder="Enter your first name"
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("lastname")}
                name="lastName"
                rules={[{ validator: validateNames }]}
              >
                <Input
                  placeholder="Enter your last name"
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[{ validator: validateEmail }]}
              >
                <Input
                  placeholder="Enter your email"
                  className="rounded-none"
                  disabled={editMode}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("role")} name="role" initialValue="ADMIN">
                <Input
                  placeholder="Select role"
                  className="rounded-none"
                  disabled={true}
                />
              </Form.Item>
            </Col>

            <Col span={24} className="justify-center flex">
              <Button
                className={"bg-[#081258] " + s.button}
                loading={loading}
                onClick={editMode ? editUserHandle : addUserHandle}
              >
                {loading
                  ? `${t("loading")}...`
                  : editMode
                  ? t("edit_user")
                  : t("add_user")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
};

export default AddUserModal;
