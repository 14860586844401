//Login Constants
export const SHOW_USERS_REQUEST="SHOW_USERS_REQUEST";
export const SHOW_USERS_SUCCESS="SHOW_USERS_SUCCESS";
export const SHOW_USERS_FAILURE="SHOW_USERS_FAILURE";

// Signup Constants

export const LOGIN_USER_REQUEST="LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS="LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE="LOGIN_USER_FAILURE";

// forgot password

export const FORGOT_PASSWORD_REQUEST="FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS="FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE="FORGOT_PASSWORD_FAILURE";