import axios from '../../../utils/axios';
import { GET_STAFF_DRIVER_REQUEST, GET_STAFF_DRIVER_SUCCESS, GET_STAFF_DRIVER_FALIURE, POST_ADD_DRIVER_FALIURE, POST_ADD_DRIVER_SUCCESS, POST_ADD_DRIVER_REQUEST, DELETE_DRIVER_FALIURE, DELETE_DRIVER_SUCCESS, DELETE_DRIVER_REQUEST, UPDATE_DRIVER_FALIURE, UPDATE_DRIVER_REQUEST, UPDATE_DRIVER_SUCCESS } from "../../constants/staff_and_drivers";
import { getAllDriver, addDriver, deleteDriver, updateDriver } from "../../../urls";
import {getPayload} from '../../../utils/extras';

export const getDriverStaffData = (tableParams) => {
  return async (dispatch) => {
    dispatch({ type: GET_STAFF_DRIVER_REQUEST });
    try {
      const result = await axios.get(
        getAllDriver(tableParams)
      );
      dispatch({ type: GET_STAFF_DRIVER_SUCCESS, payload: result?.data?.data || [] });
    } catch (err) {
      dispatch({ type: GET_STAFF_DRIVER_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const getAddDriver = (tableParams) => {
  return async (dispatch) => {
    dispatch({ type: POST_ADD_DRIVER_REQUEST });
    try {
      const result = await axios.post(
        addDriver(), getPayload(tableParams)
      );
      return result;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

export const deleteDriverAndStaff = (tableParams) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_DRIVER_REQUEST });
    try {
      const result = await axios.delete(
        deleteDriver(), { data: tableParams }
      );
      return result;
    } catch (err) {
      return err;
    }
  };
};

export const updateDriverAndStaff = (tableParams) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_DRIVER_REQUEST });
    try {
      const result = await axios.put(
        updateDriver(), getPayload(tableParams)
      );
      return result;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};