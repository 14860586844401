import axios from "../../utils/axios";
import {
  ADD_PROMOCODE,
  DELETE_PROMOCODE,
  GET_ALL_PROMOCODES,
  UPDATE_PROMOCODE,
} from "../../urls";
import {
  ADD_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_SUCCESS,
  GET_ALL_PROMOCODES_SUCCESS,
  PROMOCODE_FALIURE,
  PROMOCODE_REQUEST,
  UPDATE_PROMOCODE_SUCCESS,
} from "../constants/promocode";

export const getAllPromocodesData = (params, extraParams) => {
  return async (dispatch) => {
    dispatch({ type: PROMOCODE_REQUEST });
    try {
      const result = await axios.get(GET_ALL_PROMOCODES);
      dispatch({
        type: GET_ALL_PROMOCODES_SUCCESS,
        payload: { data: result.data?.data, message: result.data?.message },
      });
    } catch (err) {
      console.log("getAllPromocodesData:err", err);
      dispatch({
        type: PROMOCODE_FALIURE,
        payload: { message: err?.response?.data?.message || err },
      });
      return {
        message: err?.response?.data?.message || err,
        statusCode: err?.response?.data?.statusCode,
        error: true,
      };
    }
  };
};

export const addPomocodeData = (params) => {
  return async (dispatch) => {
    dispatch({ type: PROMOCODE_REQUEST });
    try {
      const result = await axios.post(ADD_PROMOCODE, params);
      dispatch({
        type: ADD_PROMOCODE_SUCCESS,
        payload: { message: result.data?.message },
      });
      return { message: result.data?.message };
    } catch (err) {
      console.log("addPomocodeData:err", err);
      dispatch({
        type: PROMOCODE_FALIURE,
        payload: { message: err?.response?.data?.message || err },
      });
      return {
        message: err?.response?.data?.message || err,
        statusCode: err?.response?.data?.statusCode,
        error: true,
      };
    }
  };
};

export const updatePomocodeData = (params) => {
  return async (dispatch) => {
    dispatch({ type: PROMOCODE_REQUEST });
    try {
      const result = await axios.put(UPDATE_PROMOCODE, params);
      dispatch({
        type: UPDATE_PROMOCODE_SUCCESS,
        payload: { data: result.data?.data, message: result.data?.message },
      });
      return { message: result.data?.message };
    } catch (err) {
      console.log("updatePomocodeData:err", err);
      dispatch({
        type: PROMOCODE_FALIURE,
        payload: { message: err?.response?.data?.message || err },
      });
      return {
        message: err?.response?.data?.message || err,
        statusCode: err?.response?.data?.statusCode,
        error: true,
      };
    }
  };
};

export const deletePomocodeData = (params) => {
  return async (dispatch) => {
    dispatch({ type: PROMOCODE_REQUEST });
    try {
      const result = await axios.delete(DELETE_PROMOCODE, {
        data: params,
      });
      dispatch({ type: DELETE_PROMOCODE_SUCCESS, payload: result.data?.data });
      return { message: result.data?.message };
    } catch (err) {
      console.log("deletePomocodeData:err", err);
      dispatch({
        type: PROMOCODE_FALIURE,
        payload: { message: err?.response?.data?.message || err },
      });
      return {
        message: err?.response?.data?.message || err,
        statusCode: err?.response?.data?.statusCode,
        error: true,
      };
    }
  };
};
