export const GET_DIARY_REQUEST="GET_DIARY_REQUEST";
export const GET_DIARY_SUCCESS="GET_DIARY_SUCCESS";
export const GET_DIARY_FALIURE="GET_DIARY_FALIURE";

export const ADD_DIARY_REQUEST="ADD_DIARY_REQUEST";
export const ADD_DIARY_SUCCESS="ADD_DIARY_SUCCESS";
export const ADD_DIARY_FALIURE="ADD_DIARY_FALIURE";

export const UPDATE_DIARY_REQUEST="UPDATE_DIARY_REQUEST";
export const UPDATE_DIARY_SUCCESS="UPDATE_DIARY_SUCCESS";
export const UPDATE_DIARY_FALIURE="UPDATE_DIARY_FALIURE";

export const DELETE_DIARY_REQUEST="DELETE_DIARY_REQUEST";
export const DELETE_DIARY_SUCCESS="DELETE_DIARY_SUCCESS";
export const DELETE_DIARY_FALIURE="DELETE_DIARY_FALIURE";