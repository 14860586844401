import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { get } from "lodash";
import { getAllDiaryDataApi } from "../../redux/actions/diary";

const Diary = () => {
  const dispatch = useDispatch();
  const allData = useSelector((state) => state);

  const allDiaryData = get(allData, "getDiaryData.data[0].data", []);

  const renderCellContent = (arg) => {
    const formattedDate = new Date(arg.date);
    const year = formattedDate.getFullYear();
    const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
    const day = String(formattedDate.getDate()).padStart(2, "0");
    const date = `${year}-${month}-${day}`;
    const eventData = allDiaryData.find((event) => event?._id === date); //event.date === date
    return (
      <>
        <div className="w-full flex flex-col items-center gap-3 bg-white ">
          {arg.view.type === "dayGridMonth" && (
            <p className="mx-auto text-center ">
              <span
                className={
                  isCurrentDate(arg.date)
                    ? "text-ellipsis w-4 h-4 bg-[#05B44B] px-[9px] py-[9px] rounded-full"
                    : ""
                }
              >
                {get(arg, "dayNumberText", "")}
              </span>
            </p>
          )}
          {eventData && (
            <>
              <p className="w-fit bg-[#E12714] py-[3px] px-[8px] text-center text-white mx-auto rounded-[3px]">
                {Number(get(eventData, "arrivalCountDay", 0)) +
                  Number(get(eventData, "departureCountDay", 0))}
              </p>
              <div className="flex justify-between w-full px-2">
                <div className="flex gap-1 items-center">
                  {get(eventData, "arrivalPassengerNumberSumDay", 0)}
                  <p className="w-fit bg-[#081258] py-[3px] px-[8px] text-white rounded-[3px]">
                    {get(eventData, "arrivalCountDay", 0)}
                  </p>
                </div>
                <div className="flex gap-1 items-center">
                  {get(eventData, "departurepassengerNumberSumDay", 0)}
                  <p className="w-fit bg-[#03757C] py-[3px] px-[8px] text-white rounded-[3px]">
                    {get(eventData, "departureCountDay", 0)}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const isCurrentDate = (date) => {
    const today = new Date();
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  };

  useEffect(() => {
    dispatch(getAllDiaryDataApi());
    // eslint-disable-next-line
  }, []);

  function renderEventContent(eventInfo) {
    return (
      <>
        {eventInfo?.view?.type === "timeGridWeek" ||
        eventInfo?.view?.type === "timeGridDay" ? (
          <>
            <div className="flex justify-between w-full px-2">
              {get(eventInfo, "event.extendedProps.arrivalCount", 0) && (
                <div className="flex gap-1 items-center">
                  <p className="text-black">
                    {eventInfo?.event?.extendedProps?.arrivalPassengerCount ||
                      0}
                  </p>
                  <p className="w-fit bg-[#081258] py-[3px] px-[8px] text-white rounded-[3px]">
                    {eventInfo?.event?.extendedProps?.arrivalCount}
                  </p>
                </div>
              )}
              {get(eventInfo, "event.extendedProps.departureCount", 0) && (
                <div className="flex gap-1 items-center">
                  <p className="text-black">
                    {eventInfo?.event?.extendedProps?.departurePassCount || 0}
                  </p>
                  <p className="w-fit bg-[#03757C] py-[3px] px-[8px] text-white rounded-[3px]">
                    {eventInfo?.event?.extendedProps?.departureCount}
                  </p>
                </div>
              )}
            </div>
          </>
        ) : null}
      </>
    );
  }

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "today prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        locales="allLocales"
        locale="en"
        firstDay="1"
        editable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        dayCellContent={renderCellContent} // Pass the renderCellContent callback
        slotDuration="00:15:00"
        slotLabelInterval={{ minutes: 30 }}
        eventContent={renderEventContent}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
        }}
        scrollTime="00:00"
        eventOverlap={false}
        // slotEventOverlap={false}
        events={allDiaryData}
      />
    </div>
  );
};

export default Diary;
