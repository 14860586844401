import React from "react";
import { Input, Form } from "antd";
import logo from "../../../assets/images/logo.png";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { resetPasswordFunc } from "../../../redux/actions/login_user";
import { useTranslation } from "react-i18next";

const s = {
  wrapperClasses:
    "bg-[#F2F1F1] min-w-full min-h-screen flex justify-center items-center",
  loginFormClasses:
    "bg-white flex flex-col p-28 justify-center items-center shadow-[0px_0px_15px_rgba(0, 0, 0, 0.04)]",
  h1Classes: "text-center font-bold py-5 text-2xl uppercase",
  pClasses: "pt-2 pb-10 whitespace-nowrap text-center text-2xl",
  inputClasses: "rounded-none py-4 px-5 w-[42rem]",
  buttonClasses:
    "uppercase w-full h-full text-white font-bold py-4 rounded-none bg-[#E12714] mt-4",
};

const lngs = {
  fr: { nativeName: "French" },
  en: { nativeName: "English" },
};
const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenId = queryParams.get("resetToken");
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const requestData = {
      password: get(values, "password", ""),
      resetToken: tokenId,
    };
    const result = await dispatch(resetPasswordFunc(requestData));
    if (get(result, "statusCode", "") === 200) {
      navigate("/login");
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    console.log("Changed values:", changedValues);
    console.log("All values:", allValues);
  };
  return (
    <div className={s.wrapperClasses}>
      <div className={s.loginFormClasses}>
        <div className="flex justify-end gap-3 ml-auto mb-10 absolute top-3 right-3">
          {Object.keys(lngs).map((lng) => (
            <button
              key={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              }}
              onClick={(e) => {
                e.preventDefault();
                i18n.changeLanguage(lng);
              }}
            >
              <img
                src={`/icons/${lng === "fr" ? "fr" : "en"}.png`}
                alt=""
                className="w-6 inline-block"
              />{" "}
              {lngs[lng].nativeName}
            </button>
          ))}
        </div>
        <img src={logo} alt="logo" />
        <h1 className={s.h1Classes}>{t("login.reset_password")}</h1>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            label={t("login.password")}
            name="password"
            rules={[
              {
                required: true,
                message: t("login.password_required"),
              },
            ]}
          >
            <Input
              placeholder={t("login.enter_password")}
              className={s.inputClasses}
            />
          </Form.Item>
          <Form.Item
            label={t("login.confirm_password")}
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: t("login.confirmPwd_required"),
              },
            ]}
          >
            <Input
              placeholder={t("login.re_enter_pwd")}
              className={s.inputClasses}
            />
          </Form.Item>

          <button className={s.buttonClasses} type="submit">
            {t("login.reset_password")}
          </button>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
