import { get, isEmpty } from "lodash";
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  
  const isLoggedIn = !isEmpty(get(localStorage, 'accessToken', ''));
  return isLoggedIn ? children : <Navigate to="/login" />;
};
export default PrivateRoute;
