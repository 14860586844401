import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import {
  arrivals_and_departures,
  rates_and_fees,
  driver_and_staff,
  diary,
  commissions,
  management,
  statistics,
  users,
  dashboard,
  gps,
  shuttle,
  parking_map,
} from "../../assets/icons";
import { useTranslation } from "react-i18next";

const { Sider } = Layout;

const Sidebar = () => {
  const { t } = useTranslation();
  const [hoveredItem, setHoveredItem] = useState(null);

  const items = [
    {
      key: "/dashboard",
      icon: <img src={arrivals_and_departures} alt="Arrivals/Departures" />,
      label: t("ARRIVALS / DEPARTURES"),
    },
    {
      key: "/rates_fees",
      icon: <img src={rates_and_fees} alt="rates_and_fees" />,
      label: t("RATES / FEES"),
    },
    {
      key: "/gps_module",
      icon: <img src={gps} alt="gps" style={{ maxWidth: "42px" }} />,
      label: t("GPS"),
    },
    {
      key: "/shuttle",
      icon: <img src={shuttle} alt="shuttle" style={{ maxWidth: "42px" }} />,
      label: t("SHUTTLE"),
    },
    {
      key: "/parking_map",
      icon: (
        <img src={parking_map} alt="parking map" style={{ maxWidth: "42px" }} />
      ),
      label: t("PARKING MAP"),
    },
    // {
    //   key: "/booking_option",
    //   icon: <img src={booking_option} alt="booking_option" />,
    //   label: t("BOOKING OPTION"),
    // },
    {
      key: "/driver_and_staff",
      icon: <img src={driver_and_staff} alt="driver_and_staff" />,
      label: t("DRIVER AND STAFF"),
    },
    {
      key: "/diary",
      icon: <img src={diary} alt="diary" />,
      label: t("DIARY"),
    },
    {
      key: "/commissions",
      icon: <img src={commissions} alt="commissions" />,
      label: t("COMMISSIONS"),
    },
    {
      key: "/management",
      icon: <img src={management} alt="management" />,
      label: t("MANAGEMENT"),
    },
    {
      key: "/statistics",
      icon: <img src={statistics} alt="statistics" />,
      label: t("STATISTICS"),
    },
    {
      key: "/clients",
      icon: <img src={users} alt="clients" />,
      label: t("CLIENTS"),
    },
  ];
  return (
    <Sider theme="light" width={100}>
      <div className="bg-[#E12714] flex text-[30px] uppercase text-white font-semibold gap-[10px] p-6 leading-9 border-b">
        <img src={dashboard} alt="" />
      </div>
      <Menu
        theme="light"
        mode="inline"
        className="bg-[#081258]"
        defaultSelectedKeys={
          window?.location?.pathname === "/"
            ? [items[0].key]
            : [window?.location?.pathname]
        }
      >
        {items?.map((item) => (
          <Menu.Item
            key={item.key}
            icon={item.icon}
            className={`sidebar-item ${
              hoveredItem === item.key ? "hovered-item" : ""
            }`}
            onMouseEnter={() => setHoveredItem(item.key)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <Link to={item.key}>
              <span className="label-on-hover">{item.label}</span>
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
