import {
  GET_USER_FALIURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  DELETE_USER_FALIURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  ADD_USER_FALIURE,
  ADD_USER_SUCCESS,
  ADD_USER_REQUEST,
  UPDATE_USER_FALIURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPLOAD_PROFILE_FALIURE,
  UPLOAD_PROFILE_REQUEST,
  UPLOAD_PROFILE_SUCCESS,
} from "../constants/user";

const userData = { data: [], isLoading: false, error: false };
function getUsersData(state = userData, action) {
  switch (action.type) {
    case GET_USER_REQUEST:
      return { data: [], isLoading: true, error: null };

    case GET_USER_SUCCESS:
      return { data: [action.payload], isLoading: false, error: null };

    case GET_USER_FALIURE:
      return { data: [], isLoading: false, error: null };

    default:
      return state;
  }
}
function deleteDataOfUsers(state = userData, action) {
  switch (action.type) {
    case DELETE_USER_REQUEST: {
      state.isLoading = true;
      return state;
    }
    case DELETE_USER_SUCCESS: {
      state.isLoading = false;
      state = action.payload;
      return state;
    }
    case DELETE_USER_FALIURE: {
      state.error = true;
      return state;
    }
    default:
      return state;
  }
}

function addDataOfUsers(state = userData, action) {
  switch (action.type) {
    case ADD_USER_REQUEST: {
      state.isLoading = true;
      return state;
    }
    case ADD_USER_SUCCESS: {
      state.isLoading = false;
      state = action.payload;
      return state;
    }
    case ADD_USER_FALIURE: {
      state.error = false;
      return state;
    }
    default:
      return state;
  }
}

function updateDataOfUsers(state = userData, action) {
  switch (action.type) {
    case UPDATE_USER_REQUEST: {
      state.isLoading = true;
      return state;
    }
    case UPDATE_USER_SUCCESS: {
      state.isLoading = false;
      state = action.payload;
      return state;
    }
    case UPDATE_USER_FALIURE: {
      state.error = true;
      return state;
    }
    default:
      return state;
  }
}

function uploadProfilePictureData(state = userData, action) {
  switch (action.type) {
    case UPLOAD_PROFILE_REQUEST: {
      state.isLoading = true;
      return state;
    }
    case UPLOAD_PROFILE_SUCCESS: {
      state.isLoading = false;
      state = action.payload;
      return state;
    }
    case UPLOAD_PROFILE_FALIURE: {
      state.error = true;
      return state;
    }
    default:
      return state;
  }
}

export {
  getUsersData,
  deleteDataOfUsers,
  addDataOfUsers,
  updateDataOfUsers,
  uploadProfilePictureData,
};
