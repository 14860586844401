import React, { useEffect } from "react";
import {
  Space,
  Table,
  Popconfirm,
  Avatar,
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { useState } from "react";
import {
  addRow,
  deleteIcon,
  phone,
  pencil,
  user,
  lock,
} from "../../assets/icons";
import { getBgColor } from "../../utils/extras";
import { useDispatch, useSelector } from "react-redux";
import {
  getDriverStaffData,
  getAddDriver,
  deleteDriverAndStaff,
  updateDriverAndStaff,
} from "../../redux/actions/staffAndDrivers";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

const DriverAndStaff = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dnsData, setDnsData] = useState([]);
  const [totalVal, setTotalVal] = useState(0);
  const [initialVal, setInitialValue] = useState({
    id: "",
    firstName: "",
    lastName: "",
    mobile: "",
    pinCode: "",
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const driverData = useSelector((state) => state);
  const isLoading = get(driverData, "getDriverAndStaff.isLoading", false);
  const driverDataList = get(
    driverData,
    "getDriverAndStaff.data[0].results",
    []
  );

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: get(driverData, "getDriverAndStaff.data[0].itemCount", 0),
    },
  });
  const handleDelete = async (record) => {
    setLoading(true);
    let rec = {
      id: record.id,
    };
    const result = await dispatch(deleteDriverAndStaff(rec));
    if (get(result, "status") === 200) {
      setLoading(false);
      notification.success({
        placement: "topRight",
        message: "Driver deleted Succesfully!",
      });
      dispatch(getDriverStaffData());
    } else {
      notification.error({
        placement: "topRight",
        message: "Driver can not be deleted!",
      });
      setLoading(false);
    }
  };
  const openModal = () => {
    form.resetFields();
    setInitialValue({});
    setIsModalVisible(true);
    setIsEditMode(false);
  };
  const actionColumn = {
    title: <img src={addRow} alt="" onClick={openModal} />,
    dataIndex: "actions",
    sorter: false,
    render: (_, record) => {
      return (
        <Space
          size="middle"
          style={{ width: "100%", justifyContent: "flex-end" }}
        >
          <button>
            <img src={pencil} alt="" onClick={() => handleEdit(record)} />
          </button>
          <Popconfirm
            id="custom-delete-dialog"
            title={`${t("are_you_sure")} ${t("delete")}?`}
            onConfirm={() => handleDelete(record)} // Call the handleDelete function
          >
            <button>
              <img src={deleteIcon} alt="" />
            </button>
          </Popconfirm>
        </Space>
      );
    },
  };
  const columns = [
    {
      title: "Enable pin code control",
      dataIndex: "firstName",
      sorter: true,
      render: (_, record, j) => (
        <div className="flex gap-1 items-center">
          <Avatar
            size="large"
            style={{
              backgroundColor: getBgColor(j),
              marginRight: "0.5rem",
              textTransform: "uppercase",
            }}
          >
            {record.firstName[0]}
            {record.lastName[0]}
          </Avatar>
          <div className="flex flex-col capitalize font-semibold">
            <span className="text-lg">{`${record?.firstName} ${record?.lastName} (****)`}</span>
            <span className="text-sm">{record.mobile}</span>
          </div>
        </div>
      ),
    },
    actionColumn,
  ];
  const handleEdit = (record) => {
    setInitialValue({
      id: record?.id,
      firstName: record?.firstName,
      lastName: record?.lastName,
      mobile: record?.mobile,
      pinCode: record?.pinCode,
    });
    setIsEditMode(true);
    setIsModalVisible(true);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination: {
        ...tableParams.pagination,
        current: pagination.current, // Update the current page
        pageSize: pagination.pageSize, // Update the page size
      },
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      //   setData([]);
    }
  };
  const rowClassName = (_, index) => {
    return index % 2 === 0 ? "" : "bg-gray-200"; // Apply gray background to odd-indexed rows
  };

  const body = {
    page: 10,
  };
  const closeModal = () => {
    setIsModalVisible(false);
    form.resetFields();
    setInitialValue({});
  };
  useEffect(() => {
    dispatch(getDriverStaffData(body));
  }, []);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const result = await dispatch(getAddDriver(values));
    if (get(result, "status") === 200) {
      setLoading(false);
      notification.success({
        placement: "topRight",
        message: "Driver added Succesfully!",
      });
      dispatch(getDriverStaffData());
      setIsModalVisible(false);
      form.resetFields();
    } else {
      notification.error({
        placement: "topRight",
        message: "Driver can not be added!",
      });
      setLoading(false);
    }
  };

  const handleEditFormSubmit = async (values) => {
    setLoading(true);
    const updatedValues = { ...values, id: initialVal?.id };
    const result = await dispatch(updateDriverAndStaff(updatedValues));
    if (get(result, "status") === 200) {
      setLoading(false);
      notification.success({
        placement: "topRight",
        message: "Driver updated Succesfully!",
      });
      setIsEditMode(false);
      setIsModalVisible(false);
      form.resetFields();
      dispatch(getDriverStaffData());
    } else {
      notification.error({
        placement: "topRight",
        message: "Driver can not be updated!",
      });
      setLoading(false);
    }
  };

  const prepareData = (driverDataList) => {
    const bData = driverDataList.map((item) => ({
      id: item?.id,
      firstName: item.firstName,
      lastName: item.lastName,
      mobile: item.mobile,
      pinCode: item.pinCode,
    }));
    return bData;
  };
  const validateNames = (_, value) => {
    // Check if the value is a number
    if (!isNaN(value)) {
      return Promise.reject("Cannot be a number");
    }

    // Check if the value contains any digits
    if (/\d/.test(value)) {
      return Promise.reject("Cannot contain numbers");
    }

    return Promise.resolve();
  };

  const validateMobileNumber = (_, value) => {
    // Check if the value is empty
    if (!value) {
      return Promise.resolve();
    }

    // Check if the value contains any characters
    if (/\D/.test(value)) {
      return Promise.reject("Cannot contain characters");
    }
    if (value.length > 10) {
      return Promise.reject("Mobile no. cannot be more than 10 numbers");
    }
    return Promise.resolve();
  };

  const validatePinCode = (_, value) => {
    // Check if the value is empty
    if (!value) {
      return Promise.resolve();
    }

    // Check if the value contains any characters
    if (/\D/.test(value)) {
      return Promise.reject("Cannot contain characters");
    }
    if (value.length > 4 || value.length < 4) {
      return Promise.reject("Enter pincode upto 4 digits");
    }

    return Promise.resolve();
  };

  useEffect(() => {
    if (driverDataList && driverDataList.length > 0) {
      const dataVal = prepareData(driverDataList);
      setDnsData(dataVal);
      setTotalVal(get(driverData, "getDriverAndStaff.data[0].itemCount", 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverDataList]);

  useEffect(() => {
    form.setFieldsValue(initialVal);
  }, [form, initialVal]);

  useEffect(() => {
    if (totalVal) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: totalVal,
        },
      });
    }
  }, [totalVal]);

  useEffect(() => {
    dispatch(getDriverStaffData(tableParams));
  }, [tableParams]);

  return (
    <>
      <Table
        id="reservation-table"
        columns={columns}
        rowKey={(record) => record.name}
        loading={isLoading}
        dataSource={dnsData?.length > 0 ? dnsData : []}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
        rowClassName={rowClassName}
      />
      {isModalVisible && (
        <Modal
          open={isModalVisible}
          onCancel={closeModal}
          footer={null}
          style={{ padding: "10px" }}
        >
          <Form
            initialValue={initialVal}
            layout="vertical"
            form={form}
            onFinish={isEditMode ? handleEditFormSubmit : handleFormSubmit}
            className="w-full p-5"
          >
            <h2 className="font-semibold text-lg mb-5">
              {isEditMode
                ? t("edit_driver_and_staff")
                : t("add_driver_and_staff")}
            </h2>
            <Row gutter={[16, 1]}>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: t("field_required"),
                    },
                    { validator: validateNames },
                  ]}
                >
                  <Input
                    prefix={
                      <img src={user} alt="" width={20} className="mr-2" />
                    }
                    placeholder={t("firstname")}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: t("field_required"),
                    },
                    { validator: validateNames },
                  ]}
                >
                  <Input
                    prefix={
                      <img src={user} alt="" width={20} className="mr-2" />
                    }
                    placeholder={t("lastname")}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: t("field_required"),
                    },
                    { validator: validateMobileNumber },
                  ]}
                >
                  <Input
                    prefix={
                      <img src={phone} alt="" width={20} className="mr-2" />
                    }
                    placeholder={t("phone")}
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pinCode"
                  rules={[
                    {
                      required: true,
                      message: t("field_required"),
                    },
                    { validator: validatePinCode },
                  ]}
                >
                  <Input
                    prefix={
                      <img src={lock} alt="" width={20} className="mr-2" />
                    }
                    placeholder="Pin code"
                    maxLength={4}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* Add more Form.Item for other fields */}
            <Form.Item className="m-0">
              <Button
                htmlType="submit"
                className="bg-[#081258] text-white uppercase font-semibold h-fit px-6 py-2"
                loading={loading}
              >
                {loading ? `${t("loading")}...` : t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default DriverAndStaff;
