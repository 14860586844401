export const GET_BLOCK_DATES_REQUEST="GET_BLOCK_DATES_REQUEST";
export const GET_BLOCK_DATES_SUCCESS="GET_BLOCK_DATES_SUCCESS";
export const GET_BLOCK_DATES_FALIURE="GET_BLOCK_DATES_FALIURE";


export const ADD_BLOCK_DATES_REQUEST="ADD_BLOCK_DATES_REQUEST";
export const ADD_BLOCK_DATES_SUCCESS="ADD_BLOCK_DATES_SUCCESS";
export const ADD_BLOCK_DATES_FALIURE="ADD_BLOCK_DATES_FALIURE";

export const UPDATE_BLOCK_DATES_REQUEST="UPDATE_BLOCK_DATES_REQUEST";
export const UPDATE_BLOCK_DATES_SUCCESS="UPDATE_BLOCK_DATES_SUCCESS";
export const UPDATE_BLOCK_DATES_FALIURE="UPDATE_BLOCK_DATES_FALIURE";

export const DEL_BLOCK_DATES_REQUEST="DEL_BLOCK_DATES_REQUEST";
export const DEL_BLOCK_DATES_SUCCESS="DEL_BLOCK_DATES_SUCCESS";
export const DEL_BLOCK_DATES_FALIURE="DEL_BLOCK_DATES_FALIURE";