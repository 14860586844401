import axios from '../../../utils/axios';
import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  LOGIN_USER_REQUEST,
} from "../../constants/login";
import { FORGOT_PASSWORD, RESET_PASSWORD, addLogin } from "../../../urls";
import { getPayload } from '../../../utils/extras';

export const loginOption = (payload) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_USER_REQUEST });
    try {
      const result = await axios.post(addLogin, getPayload(payload));
      return result?.data;
    } catch (err) {
      console.log(err);
      return err?.response;
      // dispatch({ type: LOGIN_USER_FAILURE, payload: err});
    }
  };
};

export const forgetPassword = (payload) => {
  return async (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    try {
      const result = await axios.post(FORGOT_PASSWORD, getPayload(payload));
      return result?.data;
    } catch (err) {
      console.log(err);
      return err;
      // dispatch({ type: LOGIN_USER_FAILURE, payload: err});
    }
  };
};

export const resetPasswordFunc = (payload) => {
  return async (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    try {
      const result = await axios.post(RESET_PASSWORD, getPayload(payload));
      return result?.data;
    } catch (err) {
      console.log(err);
      return err;
      // dispatch({ type: LOGIN_USER_FAILURE, payload: err});
    }
  };
};
