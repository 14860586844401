import React from "react";
import { Space, Table, Popconfirm, notification, Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRow, deleteIcon, pencil, tick } from "../../assets/icons";
import { renderComp2 } from "../../utils/extras";
import {
  addPomocodeData,
  deletePomocodeData,
  getAllPromocodesData,
  updatePomocodeData,
} from "../../redux/actions/promocode";
import { useTranslation } from "react-i18next";

export default function PromoCodes({ activeKey }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const promocodes = useSelector((state) => state?.promocodes?.data?.results);
  const totalCount = useSelector((state) => state?.promocodes?.data?.itemCount);
  const [data, setData] = useState([]);
  const [editingRow, setEditingRow] = useState({});
  const [addRowState, setAddRowState] = useState();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    category: "PromoCodes",
  });

  const handleFieldChange = (value, fieldName, itemId) => {
    if (addRowState) {
      setAddRowState((prevAddRowState) => ({
        ...prevAddRowState,
        [fieldName]: value,
      }));
    } else {
      setEditingRow((prevEditingRow) => ({
        ...prevEditingRow,
        [fieldName]: value,
      }));
    }
  };

  const handleEditSubmit = async (values) => {
    const requestObject = {
      id: values?._id,
      to: values?.to,
      from: values?.from,
      promocode: values.promocode,
      discount: values.discount,
      discountType: "PERCENTAGE",
      description: values.description,
      location: "ORLY",
    };
    const api = await dispatch(updatePomocodeData(requestObject));
    if (api?.error) {
      notification.error({
        message: api?.message,
      });
    } else {
      notification.success({
        message: api?.message,
      });
      setEditingRow({});
      dispatch(getAllPromocodesData(tableParams));
    }
  };

  const handleAddSubmit = async (values) => {
    const { _id, ...payloadWithoutId } = values;
    const addApi = await dispatch(
      addPomocodeData({ ...payloadWithoutId, location: "ORLY" })
    );
    if (addApi?.error) {
      notification.error({
        message: addApi?.message,
      });
    } else {
      notification.success({
        message: addApi?.message,
      });
      setAddRowState(false);
      dispatch(getAllPromocodesData(tableParams));
    }
  };

  const handleAdd = (record) => {
    setAddRowState(true);
  };

  const addAction = () => {
    const requestObject = {
      _id: "",
      to: null,
      from: null,
      promocode: "",
      discount: "0",
      discountType: "PERCENTAGE",
      description: "",
    };

    setData((prevData) => [requestObject, ...prevData]);
    handleAdd(requestObject);
    setAddRowState(requestObject);
  };

  const handleEdit = (record) => {
    setEditingRow(record);
    setAddRowState(null);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setEditingRow({});
      setData([]);
    }
  };

  const rowClassName = (_, index) => {
    return index % 2 === 0 ? "" : "bg-gray-200";
  };

  const actionColumn = {
    title: (
      <img
        src={addRow}
        alt=""
        onClick={() => addAction()}
        style={{ cursor: "pointer" }}
      />
    ),
    dataIndex: "actions",
    sorter: false,
    render: (_, record, index) => {
      return (
        <Space size="middle">
          <button
            onClick={() => {
              if (addRowState?._id === "" && editingRow?.to === undefined) {
                handleAddSubmit(addRowState);
              }
              if (editingRow?._id === record?._id) {
                handleEditSubmit(editingRow);
              }
              if (
                editingRow?.to === undefined &&
                addRowState?.to === undefined
              ) {
                handleEdit(record);
              }
            }}
          >
            <img
              src={
                editingRow?._id === record?._id
                  ? index === 0
                    ? tick
                    : pencil
                  : addRowState?._id === record?._id
                  ? tick
                  : pencil
              }
              alt="edit_Icon"
            />
          </button>
          <Popconfirm
            title={`${t("are_you_sure")} ${t("delete")}?`}
            onConfirm={async () => {
              const api = await dispatch(
                deletePomocodeData({ id: record?._id })
              );
              if (api?.error) {
                notification.error({
                  message: api?.message,
                });
              } else {
                notification.success({
                  message: api?.message,
                });
                dispatch(getAllPromocodesData(tableParams));
              }
            }}
            className="popOver_bgColor"
            okText={t("yes")}
            cancelText={t("cancel")}
          >
            <Button type="text">
              <img src={deleteIcon} alt="delete_Icon" />
            </Button>
          </Popconfirm>
        </Space>
      );
    },
  };

  const columns = [
    {
      title: "From",
      dataIndex: "from",
      sorter: true,
      render: (from, record) =>
        renderComp2(
          record,
          from,
          editingRow?._id === record?._id || addRowState?._id === record?._id,
          "date",
          (val) => handleFieldChange(val, "from", record?._id)
        ),
    },
    {
      title: "To",
      dataIndex: "to",
      sorter: true,
      render: (to, record) =>
        renderComp2(
          record,
          to,
          editingRow?._id === record?._id || addRowState?._id === record?._id,
          "date",
          (val) => handleFieldChange(val, "to", record?._id)
        ),
    },
    {
      title: "Promocode",
      dataIndex: "promocode",
      sorter: true,
      render: (promocode, record) =>
        renderComp2(
          record,
          promocode,
          editingRow?._id === record?._id || addRowState?._id === record?._id,
          "text",
          (val) => handleFieldChange(val, "promocode", record?._id)
        ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      sorter: true,
      render: (discount, record) =>
        renderComp2(
          record,
          discount,
          editingRow?._id === record?._id || addRowState?._id === record?._id,
          "number",
          (val) => handleFieldChange(val, "discount", record?._id)
        ),
    },
    {
      title: "Discount Type",
      dataIndex: "discountType",
      sorter: true,
      render: (discountType, record) =>
        renderComp2(
          record,
          discountType,
          editingRow?._id === record?._id || addRowState?._id === record?._id,
          "select",
          (val) => handleFieldChange(val, "discountType", record?._id)
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: true,
      render: (description, record) =>
        renderComp2(
          record,
          description,
          editingRow?._id === record?._id || addRowState?._id === record?._id,
          "text",
          (val) => handleFieldChange(val, "description", record?._id)
        ),
    },
    actionColumn,
  ];

  const [columnData, setColumnData] = useState(columns);

  useEffect(() => {
    if (activeKey === 4) {
      setLoading(true);
      dispatch(getAllPromocodesData(tableParams));
      setLoading(false);
    } // eslint-disable-next-line
  }, [tableParams, activeKey]);

  useEffect(() => {
    if (promocodes) {
      setData(promocodes);
      setTotal(totalCount || 0);
    } // eslint-disable-next-line
  }, [promocodes, editingRow]);

  useEffect(() => {
    if (total) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: total,
        },
      });
    } // eslint-disable-next-line
  }, [total]);

  useEffect(() => {
    if (editingRow || addRowState) {
      setColumnData(columns);
    } // eslint-disable-next-line
  }, [editingRow, addRowState]);

  return (
    <Table
      id="reservation-table"
      columns={columnData}
      dataSource={data}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
      rowClassName={rowClassName}
      rowKey={(r) => r?._id}
    />
  );
}
