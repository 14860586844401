export const GET_DASHBOARD_COUNT_REQUEST="GET_DASHBOARD_COUNT_REQUEST";
export const GET_DASHBOARD_COUNT_SUCCESS="GET_DASHBOARD_COUNT_SUCCESS";
export const GET_DASHBOARD_COUNT_FALIURE="GET_DASHBOARD_COUNT_FALIURE";

export const GET_DASHBOARD_STATISTICS_REQUEST="GET_DASHBOARD_STATISTICS_REQUEST";
export const GET_DASHBOARD_STATISTICS_SUCCESS="GET_DASHBOARD_STATISTICS_SUCCESS";
export const GET_DASHBOARD_STATISTICS_FALIURE="GET_DASHBOARD_STATISTICS_FALIURE";

export const GET_AMOUNT_PLATFORM_REQUEST="GET_AMOUNT_PLATFORM_REQUEST";
export const GET_AMOUNT_PLATFORM_SUCCESS="GET_AMOUNT_PLATFORM_SUCCESS";
export const GET_AMOUNT_PLATFORM_FALIURE="GET_AMOUNT_PLATFORM_FALIURE";

export const GET_AMOUNT_PLATFORM_PIE_REQUEST="GET_AMOUNT_PLATFORM_PIE_REQUEST";
export const GET_AMOUNT_PLATFORM_PIE_SUCCESS="GET_AMOUNT_PLATFORM_PIE_SUCCESS";
export const GET_AMOUNT_PLATFORM_PIE_FALIURE="GET_AMOUNT_PLATFORM_PIE_FALIURE";