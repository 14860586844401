import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en.json"; // Import translations for each supported language
import frTranslation from "./locales/fr.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    // Add more supported languages here
  },
  lng: "fr", // Set the default language
  fallbackLng: "fr", // Fallback language if the current language translation is missing
  interpolation: {
    escapeValue: false, // Enable interpolation of variables in translations
  },
});

export default i18n;
