import React from "react";
import { logo, user } from "../../assets/images";
import { Breadcrumb, Layout, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Header } = Layout;

const s = {
  header: "flex w-full justify-between px-8 items-center bg-white",
  userWrapper: "flex gap-2 items-center",
  userImg: "w-8 h-8",
  userName: "uppercase text-l font-medium cursor-pointer",
};
const lngs = {
  en: { nativeName: "English" },
  fr: { nativeName: "French" },
};
const HeaderComponent = ({ title }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    navigate("/login");
  };

  const content = (
    <div className="flex flex-col">
      <p
        className="px-4 py-1 border-b-2 border-[#EBEBEB] hover:bg-[#061142] hover:text-white bg-white text-black uppercase cursor-pointer"
        onClick={() => navigate("/user_profile")}
      >
        {t("my_account")}
      </p>
      <p
        className="px-4 py-1 border-b-2 border-[#EBEBEB] hover:bg-[#061142] hover:text-white bg-white text-black uppercase cursor-pointer"
        onClick={handleLogout}
      >
        {t("logout")}
      </p>
    </div>
  );

  return (
    <Header className={s.header}>
      <Breadcrumb
        items={[
          {
            title: <span className="uppercase font-semibold">{t(title)}</span>,
          },
        ]}
      />
      <img src={logo} alt="logo" className=" mr-4" />
      <div className="flex w-fit">
        <div className="flex gap-4 mr-4 items-center">
          {Object.keys(lngs).map((lng) => (
            <button
              key={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              }}
              type="submit"
              onClick={() => i18n.changeLanguage(lng)}
            >
              <img
                src={`/icons/${lng === "fr" ? "fr" : "en"}.png`}
                alt=""
                className="w-6 inline-block"
              />{" "}
              {lngs[lng].nativeName}
            </button>
          ))}
        </div>
        <Popover placement="bottom" content={content} trigger="click">
          <div className={s.userWrapper}>
            <img src={user} alt="" className={s.userImg} />
            <p className={s.userName}>{t("user")}</p>
          </div>
        </Popover>
      </div>
    </Header>
  );
};
export default HeaderComponent;
