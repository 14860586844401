import {
  GET_HOLIDAYS_FALIURE,
  GET_HOLIDAYS_REQUEST,
  GET_HOLIDAYS_SUCCESS,
  ADD_HOLIDAYS_FALIURE,
  ADD_HOLIDAYS_REQUEST,
  UPDATE_HOLIDAYS_FALIURE,
  UPDATE_HOLIDAYS_REQUEST,
  DELETE_HOLIDAYS_FALIURE,
  DELETE_HOLIDAYS_REQUEST,
  DELETE_HOLIDAYS_SUCCESS,
} from "../../constants/holidays";
import {
  ADD_HOLIDAY_DATA,
  DELETE_HOLIDAY_DATA,
  GET_ALL_HOLIDAYS,
  UPDATE_HOLIDAY_DATA,
} from "../../../urls";
import axios from "../../../utils/axios";
import { get } from "lodash";
import { getPayload } from "../../../utils/extras";

export const getAllHolidaysData = () => {
  return async (dispatch) => {
    dispatch({ type: GET_HOLIDAYS_REQUEST });
    try {
      const result = await axios.get(GET_ALL_HOLIDAYS);
      dispatch({ type: GET_HOLIDAYS_SUCCESS, payload: result.data?.data });
    } catch (err) {
      dispatch({ type: GET_HOLIDAYS_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const addHolidaysData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: ADD_HOLIDAYS_REQUEST });
    try {
      const result = await axios.post(ADD_HOLIDAY_DATA, getPayload(payload));
      return get(result, "data", {});
      // dispatch({ type: ADD_COMMISSIONS_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: ADD_HOLIDAYS_FALIURE, payload: err });
      console.log(err);
      return err;
    }
  };
};

export const UpdateHolidaysData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_HOLIDAYS_REQUEST });
    try {
      const result = await axios.put(UPDATE_HOLIDAY_DATA, getPayload(payload));
      return get(result, "data", {});
      // dispatch({ type: ADD_COMMISSIONS_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: UPDATE_HOLIDAYS_FALIURE, payload: err });
      console.log(err);
      return err;
    }
  };
};

export const deleteHolidaysData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_HOLIDAYS_REQUEST });
    try {
      const result = await axios.delete(DELETE_HOLIDAY_DATA, { data: payload });
      dispatch({ type: DELETE_HOLIDAYS_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: DELETE_HOLIDAYS_FALIURE, payload: err });
      console.log("delete-Error", err);
    }
  };
};
