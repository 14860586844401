import React, { useEffect, useState } from "react";
import { pencil, tick, closeBlack } from "../../assets/icons";
import {
  Avatar,
  Checkbox,
  Col,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Switch,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getBgColor,
  renderDropDownComp,
  renderSlotComp,
} from "../../utils/extras";
import {
  getbookingOptionData,
  getAllSlots,
  addbookingOption,
  EditbookingOption,
  deletebookingOption,
} from "../../redux/actions/booking_option";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CreateReservation from "../arrivals_departures/CreateReservation";
import { get, isEmpty } from "lodash";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";

const BookingOption = () => {
  const onChangeHandler = (values, checked) => {
    localStorage.setItem("parkingSlot", get(values, "emplacement", ""));
    setSwitchLoading(values._id);
    handleEditSubmit(values, "", checked);
  };
  const actionColumn = {
    title: " ",
    dataIndex: "actions",
    sorter: false,
    render: (_, record) => {
      return (
        <Space size="middle">
          <button
            onClick={() =>
              !isEmpty(editingRow) && editingRow?.id === record?.id
                ? handleEditSubmit(record)
                : handleEdit(record)
            }
          >
            {loading &&
            !isEmpty(editingRow) &&
            editingRow?.id === record?.id ? (
              <LoadingOutlined />
            ) : (
              <img
                src={
                  !isEmpty(editingRow) && editingRow?.id === record?.id
                    ? tick
                    : pencil
                }
                alt=""
                className="min-w-[20px]"
              />
            )}
          </button>
        </Space>
      );
    },
  };
  const columns = [
    {
      title: "Status",
      dataIndex: "landing",
      sorter: true,
      render: (landing) => {
        const parsedLandingDate = moment.parseZone(landing);
        const formattedLandingDate =
          parsedLandingDate.format("MM/DD/YYYY hh:mm");

        return (
          <span className="bg-[#47718F] text-white p-1 rounded-sm whitespace-nowrap">
            {formattedLandingDate}
          </span>
        );
      },
    },
    {
      title: "Reservation",
      dataIndex: "reservation",
      sorter: true,
      render: (name, data, i) => {
        const pfName = data?.platform?.split(" ");
        return (
          <div className="flex items-center">
            <Tooltip placement="top" title={data?.platform}>
              <Avatar
                style={{
                  backgroundColor: getBgColor(i),
                  marginRight: "0.5rem",
                }}
              >
                <div className="uppercase">
                  <p>
                    {pfName[0]?.[0]}
                    {pfName[1]?.[0]}
                  </p>
                </div>
              </Avatar>
            </Tooltip>

            <p
              className="cursor-pointer"
              onClick={() => handleRecordEdit(data)}
            >
              {data?.reservationId}
            </p>
          </div>
        );
      },
    },
    {
      title: "Immat",
      dataIndex: "immat",
      sorter: true,
    },
    {
      title: "Vehicles",
      dataIndex: "vehicles",
      sorter: true,
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      sorter: (a, b) => a.customerName - b.customerName,
    },
    {
      title: "Comment",
      dataIndex: "comments",
      sorter: true,
    },
    {
      title: "Exit",
      dataIndex: "exit",
      sorter: true,
      render: (exit, record) => (
        <Switch
          defaultChecked={exit === "Pick-Up"}
          onChange={(checked) => onChangeHandler(record, checked)}
          className="bg-slate-400"
          loading={switchLoading === record._id ? loading : false}
          checked={exit === "Pick-Up" ? true : false}
        />
      ),
    },
    {
      title: "Arrived",
      dataIndex: "arrived",
      sorter: true,
      render: (dob, record) =>
        renderDropDownComp(
          record,
          record.arrived,
          editingRow,
          (val) => {
            localStorage.setItem("arrived", val);
          },
          [
            {
              value: "yes",
              label: "YES",
            },
            { value: "no", label: "NO" },
          ]
        ),
    },
    {
      title: "Pass.",
      dataIndex: "passengerNumber",
      sorter: true,
    },
    {
      title: "Deposite",
      dataIndex: "deposite",
      sorter: true,
      render: (landing) => {
        const parsedLandingDate = moment.parseZone(landing);
        const formattedLandingDate = parsedLandingDate.format("hh:mm a");

        return (
          <span className="bg-[#47718F] text-white p-1 rounded-sm whitespace-nowrap">
            {formattedLandingDate}
          </span>
        );
      },
    },
    {
      title: "Emplacement",
      dataIndex: "emplacement",
      sorter: true,
      render: (_, record) =>
        renderSlotComp(
          record,
          record.emplacement,
          editingRow,
          (val) => {
            localStorage.setItem("parkingSlot", val);
          },
          slotsData
        ),
    },
    actionColumn,
  ];
  const dispatch = useDispatch();
  const bookingOptionData = useSelector((state) => state);

  const isLoading = get(bookingOptionData, "getBookingOption.isLoading", false);
  const arrivalsDeparturesData =
    bookingOptionData?.getBookingOption?.users[0]?.results;
  const slotsData = bookingOptionData?.getTotalSlot?.data[0];
  const [loading, setLoading] = useState(false);
  const filterColumns = [];
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [editingRow, setEditingRow] = useState({});
  const [total, setTotal] = useState(0);
  const [dateFilter, setDateFilter] = useState(new Date());
  const [extraParams, setExtraParams] = useState({});
  const [initialValues, setInitialValue] = useState({});
  const [columnData, setColumnData] = useState(columns);
  const [rechargeVal, setRechargeVal] = useState();
  const [filterOn, setFilterOn] = useState(false);
  const [filterName, setFilterName] = useState("TODAY");
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: bookingOptionData?.getBookingOption?.users[0]?.itemCount,
    },
  });
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  const formattedDate = `${day} / ${month} / ${year}`;
  useEffect(() => {
    if (arrivalsDeparturesData && isEmpty(editingRow)) {
      const data = prepareData(arrivalsDeparturesData);
      setTotal(bookingOptionData?.getBookingOption?.users[0]?.itemCount);
      setTableData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivalsDeparturesData, editingRow]);

  useEffect(() => {
    if (selectedColumns && selectedColumns.length > 0) {
      const sortColumns = columns.filter((item) =>
        selectedColumns.includes(item.dataIndex)
      );
      const amountColumn = [
        {
          title: "Amount",
          dataIndex: "amount",
          sorter: true,
        },
      ];
      // const exitColumn = [
      //   {
      //     title: "Exit",
      //     dataIndex: "exit",
      //     sorter: true,
      //     render: (exit) => (
      //       <div
      //         className={`${
      //           exit === "Pick-up" ? "bg-[#FE4550] text-white" : "bg-[#E0E405]"
      //         } rounded-md  text-center px-4 py-1 w-fit font-bold whitespace-nowrap`}
      //       >
      //         {exit === "Pick-up" ? "Pick-up" : "€-Pick-up"}
      //       </div>
      //     ),
      //   },
      // ];
      if (selectedColumns.includes("amount")) {
        setColumnData([...sortColumns, ...amountColumn]);
      } else if (selectedColumns.includes("amount")) {
        setColumnData([...sortColumns, ...amountColumn]);
      } else {
        setColumnData(sortColumns);
      }
    } else {
      setColumnData(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumns]);

  useEffect(() => {
    if (editingRow) {
      setColumnData(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingRow, loading]);

  useEffect(() => {
    setLoading(true);
    if (isEmpty(extraParams)) {
      dispatch(getbookingOptionData(tableParams));
      dispatch(getAllSlots());
    }
    if (!isEmpty(extraParams)) {
      dispatch(getbookingOptionData(tableParams, extraParams));
      dispatch(getAllSlots());
    }

    setLoading(false);
    // eslint-disable-next-line
  }, [tableParams, extraParams, filterName]);

  useEffect(() => {
    if (editingRow) {
      setColumnData(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingRow, loading]);

  useEffect(() => {
    if (total) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: total,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  const handleEditSubmit = async (values, from = "", checked) => {
    const arrive = localStorage.getItem("arrived");
    const slot = localStorage.getItem("parkingSlot");
    setLoading(true);
    if (
      (!isEmpty(arrive) && !isEmpty(slot) && from === "") ||
      from === "registration" ||
      checked === true ||
      checked === false
    ) {
      delete values?._id;
      const requestObject = {
        id: values?.id,
        arrived: arrive || values?.arrived,
        exit: checked,
        countryCode: "+91",
        arrivalDate: moment
          .parseZone(values.arrivalDate)
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        arrivalFlightCompany: values.arrivalFlightCompany,
        arrivalFlightNumber: values.arrivalFlightNumber,
        arrivalFlightTerminal: values.arrivalFlightTerminal,
        bookingAmount: values.bookingAmount,
        comments: values.comments,
        departureDate: moment
          .parseZone(values.departureDate)
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        departureFlightCompany: values.departureFlightCompany,
        departureFlightNumber: values.departureFlightNumber,
        departureFlightTerminal: values.departureFlightTerminal,
        email: values.email,
        extraAmount: values.extraAmount,
        firstName: values.firstName,
        city: values.hometown,
        lastName: values.lastName,
        outdoorParking: values.outdoorParking,
        parkingSlot: slot || values.parkingSlot,
        passengerNumber: values.passengerNumber,
        phoneNo: values.phoneNo,
        platform: values.platform,
        rearSeat: values.rearSeat,
        registration: values.registration,
        staff: values.staff,
        vehicleNumber: values.vehicleNumber,
        vehicleInfo: values.vehicleInfo,
        rechargeVehicleAmount: values.rechargeVehicleAmount,
        oldParkingSlot: values?.parkingSlot,
        // hometown: values?.hometown,
      };
      const filteredRequestObject = Object.fromEntries(
        Object.entries(requestObject).filter(([_, value]) => value !== "")
      );

      const result = await dispatch(EditbookingOption(filteredRequestObject));
      if (get(result, "status") === 200) {
        setLoading(false);
        setEditingRow({});
        setColumnData(columns);
        setSwitchLoading(false);
        dispatch(getbookingOptionData(tableParams));
        localStorage.removeItem("arrived");
        localStorage.removeItem("parkingSlot");
        localStorage.removeItem("exit");
        setShowModal(false);
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleEdit = (record) => {
    localStorage.setItem("arrived", record.arrived);
    localStorage.setItem("parkingSlot", record?.emplacement);
    localStorage.setItem("exit", record?.exit);
    setEditingRow(record);
  };

  const handleRecordEdit = (data) => {
    const parsedArrivalDate = moment.parseZone(data.arrivalDate);
    const parsedDepartureDate = moment.parseZone(data.departureDate);
    const formattedArrivalDate = parsedArrivalDate.format(
      "MMMM D, YYYY h:mm A"
    );
    const formattedDepartureDate = parsedDepartureDate.format(
      "MMMM D, YYYY h:mm A"
    );
    const initData = {
      arrived: data?.arrived,
      countryCode: "+91",
      arrivalDate: formattedArrivalDate,
      arrivalFlightCompany: data.arrivalFlightCompany,
      arrivalFlightNumber: data.arrivalFlightNumber,
      arrivalFlightTerminal: data.arrivalFlightTerminal,
      bookingAmount: data.bookingAmount,
      comments: data.comments,
      departureDate: formattedDepartureDate,
      departureFlightCompany: data.departureFlightCompany,
      departureFlightNumber: data.departureFlightNumber,
      departureFlightTerminal: data.departureFlightTerminal,
      email: data.email,
      extraAmount: data.extraAmount,
      firstName: data.firstName,
      city: data.hometown,
      id: data?.id,
      lastName: data.lastName,
      outdoorParking: data.outdoorParking,
      parkingSlot: data?.parkingSlot,
      passengerNumber: data.passengerNumber,
      phoneNo: data.phoneNo.replace(/^\+33/, ""),
      platform: data.platform,
      rearSeat: data.rearSeat,
      registration: data.registration,
      staff: data.staff,
      vehicleNumber: data.vehicleNumber,
      vehicleInfo: data.vehicleInfo,
      reservationId: data?.reservationId,
      rechargeVehicleAmount: data?.rechargeVehicleAmount,
      // hometown: values?.hometown,
    };
    setInitialValue(initData);
    setShowModal(true);
  };

  const prepareData = (bookingData) => {
    const bData = bookingData.map((item) => ({
      ...item,
      id: item._id,
      reservation: item.reservation,
      customerName: item?.userId?.name
        ? item?.userId?.name
        : `${item?.userId?.firstName} ${item?.userId?.lastName}`,
      amount: item.bookingAmount
        ? `${(+item?.bookingAmount).toFixed(2)} €`
        : "0.00 €",
      immat: item.arrivalFlightNumber
        ? item.arrivalFlightNumber
        : item.registration,
      vehicles: item.vehicle ? item.vehicle : item.vehicleInfo,
      deposite: item.startTime
        ? moment.parseZone(item.startTime).format("MM/DD/YYYY hh:mm A")
        : moment.parseZone(item.departureDate).format("MM/DD/YYYY hh:mm A"),
      arrived: item.arrived ? item.arrived : "NO",
      emplacement: item.parkingSlot ? item.parkingSlot : "5/7",
      exit: item.exit === true ? "Pick-Up" : "no",
      parkinType: item.platform ? item.platform : "Parking exterieur",
      landing: item.endTime ? item.endTime : item.arrivalDate,
      email: item?.email,
    }));
    return bData;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   dispatch(clearArrivalsDeparturesData());
    // }
  };

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "" : "bg-gray-200"; // Apply gray background to odd-indexed rows
  };

  const onColumnSelection = (value, checked) => {
    if (checked === false) {
      const abc = selectedColumns.filter((item) => item !== value);
      filterColumns.push(...selectedColumns, value);
      setSelectedColumns(abc);
    }
    if (checked === true) {
      filterColumns.push(...selectedColumns, value);
      setSelectedColumns([...selectedColumns, value]);
    }
  };

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const handleSubmit = (val) => {
    setLoading(true);
    if (val?.id !== undefined) {
      handleEditSubmit(val, "registration");
    } else {
      handleAddSubmit(val);
    }
  };

  const handleAddSubmit = async (values) => {
    const requestPayload = {
      ...values,
      arrivalDate: moment(values.arrivalDate).format("YYYY-MM-DD HH:mm:ss"),
      departureDate: moment(values.departureDate).format("YYYY-MM-DD HH:mm:ss"),
    };
    const result = await dispatch(
      addbookingOption({ ...requestPayload, arrived: "NO" })
    );
    if (get(result, "status", "") === 200) {
      setShowModal(false);
      notification.success({
        placement: "topRight",
        message: "Reservation added Succesfully!",
      });
      dispatch(getbookingOptionData(tableParams, {}));
    } else {
      notification.error({
        placement: "topRight",
        message: "Reservation can not be added!",
      });
    }
  };

  const handleCancelReservation = async (id) => {
    const requestObj = {
      id: id,
    };
    const result = await dispatch(deletebookingOption(requestObj));
    if (get(result, "status", "") === 200) {
      setShowModal(false);
      notification.success({
        placement: "topRight",
        message: "Reservation removed Succesfully!",
      });
      dispatch(getbookingOptionData(tableParams, {}));
    } else {
      notification.error({
        placement: "topRight",
        message: "Reservation can not be removed!",
      });
    }
  };

  const removeOneDayToDate = (date) => {
    date.setDate(date.getDate() - 1);
    return date;
  };

  const tomorrowDate = (date) => {
    date.setDate(date.getDate() + 1);
    return date;
  };

  const handleDateFilter = (value, from) => {
    setFilterName(value);
    setFilterOn(true);
    const date = new Date();
    if (value === "today") {
      setDateFilter(new Date());
      setExtraParams({
        dateFilter: moment(date).format("YYYY-MM-DD"),
        parkingType: "",
      });
      setLoading(true);
      dispatch(getbookingOptionData(tableParams, extraParams));
      setLoading(false);
    }
    if (value === "yesterday") {
      const yesterdayDate = removeOneDayToDate(date);
      setDateFilter(yesterdayDate);
      setExtraParams({
        dateFilter: moment(yesterdayDate).format("YYYY-MM-DD"),
        parkingType: "",
      });
      setLoading(true);
      dispatch(getbookingOptionData(tableParams, extraParams));
      setLoading(false);
    }
    if (value === "tomorrow") {
      const tomoDate = tomorrowDate(date);
      setDateFilter(tomoDate);
      setExtraParams({
        dateFilter: moment(tomoDate).format("YYYY-MM-DD"),
        parkingType: "",
      });
      setLoading(true);
      dispatch(getbookingOptionData(tableParams, extraParams));
      setLoading(false);
    }
  };

  // const handleSearch = () => {
  //   if (dateFilter !== "") {
  //     setExtraParams({
  //       dateFilter: moment(dateFilter).format("YYYY-MM-DD"),
  //       parkingType: "",
  //     });
  //     setLoading(true);
  //     dispatch(getbookingOptionData(tableParams, extraParams));
  //     setLoading(false);
  //   }
  // };

  const handleClearFilter = () => {
    setFilterOn(false);
    setFilterName("Today");
    setExtraParams({});
    const date = new Date();
    setDateFilter(date);
    setLoading(true);
    dispatch(getbookingOptionData(tableParams));
    setLoading(false);
    document.querySelector(".ant-select-selection-item").innerText = "TODAY";
  };
  return (
    <div id="booking-option">
      {/* Header */}
      <div className="bg-[#081258] flex p-3 mb-4 text-white items-center">
        <div className="flex-1 flex items-center  font-bold text-sm">
          Arrivals
          <span className="border py-1 px-2 rounded ml-5 mr-2">
            {/* 9 / MAY / 2023 */}
            {formattedDate}
          </span>
        </div>
        <button
          className="text-white bg-[#E12714] uppercase p-3 font-semibold text-xs"
          onClick={() => {
            setInitialValue({});
            setShowModal(true);
            setRechargeVal(false);
          }}
        >
          + create a reservation
        </button>
      </div>
      {/* Filters Section */}
      <div className="flex p-3 mb-4 items-center bg-white  font-bold text-sm">
        <Row gutter={[16, 16]} className="w-full" style={{ margin: "0px" }}>
          <Col span={12}>
            <span className="bg-[#081258] text-white rounded-sm px-3 py-1 mr-2">
              {slotsData?.occupiedSlot ? slotsData?.occupiedSlot : 0}
            </span>
            Vahicle(s) at the moment on the parking{" "}
            {slotsData?.occupiedSlot
              ? (
                  (slotsData?.occupiedSlot * 100) /
                  slotsData?.itemCount
                ).toFixed(2)
              : 100}
            %
          </Col>
          <Col span={12} className="flex items-center justify-end">
            <span className="bg-[#081258] text-white rounded-sm px-3 py-1 mr-2">
              {slotsData?.occupiedSlot ? slotsData?.occupiedSlot : 0}
            </span>
            Vahicle(s) at midnight{" "}
            {slotsData?.occupiedSlot
              ? (
                  (slotsData?.occupiedSlot * 100) /
                  slotsData?.itemCount
                ).toFixed(2)
              : 100}
            %
          </Col>

          <Col span={12}>
            <div className="flex items-center">
              Daily occupation for
              <Select
                suffixIcon={
                  <DownOutlined style={{ fontSize: "15px", color: "white" }} />
                }
                defaultValue={filterName}
                className="w-28 mx-3"
                options={[
                  { value: "yesterday", label: "YESTERDAY" },
                  { value: "today", label: "TODAY" },
                  { value: "tomorrow", label: "TOMORROW" },
                ]}
                onChange={(value) => handleDateFilter(value, "days")}
              />
              or for the
              <span className="border bg-[#E12714] text-white py-1 px-2 rounded mx-3">
                <DatePicker
                  selected={dateFilter}
                  onChange={(date) => setDateFilter(date)}
                  customInput={<ExampleCustomInput />}
                />
              </span>
              {filterOn && (
                <img
                  src={closeBlack}
                  alt="Close Icon"
                  onClick={() => handleClearFilter()}
                  className="cursor-pointer"
                />
              )}
            </div>
          </Col>
          <Col span={12} className="flex items-center justify-end">
            <Select
              showArrow={false}
              defaultValue="COLUMNS"
              value="COLUMNS"
              placeholder="Please select"
              className="w-[150px] mr-3"
              options={[
                {
                  value: "reservation",
                  label: (
                    <Checkbox
                      onClick={(e) => {
                        onColumnSelection("reservation", e.target.checked);
                      }}
                      style={{
                        "--background-color": "#E12714",
                        "--border-color": "#E12714",
                      }}
                      checked={selectedColumns.includes("reservation")}
                    >
                      Reservation
                    </Checkbox>
                  ),
                },
                {
                  value: "customerName",
                  label: (
                    <Checkbox
                      onClick={(e) => {
                        onColumnSelection("customerName", e.target.checked);
                      }}
                      checked={selectedColumns.includes("customerName")}
                    >
                      Customer
                    </Checkbox>
                  ),
                },
                {
                  value: "amount",
                  label: (
                    <Checkbox
                      onClick={(e) => {
                        onColumnSelection("amount", e.target.checked);
                      }}
                      style={{
                        "--background-color": "#E12714",
                        "--border-color": "#E12714",
                      }}
                      checked={selectedColumns.includes("amount")}
                    >
                      Amount
                    </Checkbox>
                  ),
                },
                {
                  value: "immat",
                  label: (
                    <Checkbox
                      onClick={(e) => {
                        onColumnSelection("immat", e.target.checked);
                      }}
                      checked={selectedColumns.includes("immat")}
                    >
                      Immat
                    </Checkbox>
                  ),
                },
                {
                  value: "vehicles",
                  label: (
                    <Checkbox
                      onClick={(e) => {
                        onColumnSelection("vehicles", e.target.checked);
                      }}
                      checked={selectedColumns.includes("vehicles")}
                    >
                      Vehicles
                    </Checkbox>
                  ),
                },
                {
                  value: "deposite",
                  label: (
                    <Checkbox
                      onClick={(e) => {
                        onColumnSelection("deposite", e.target.checked);
                      }}
                      checked={selectedColumns.includes("deposite")}
                    >
                      Deposite
                    </Checkbox>
                  ),
                },
                {
                  value: "arrived",
                  label: (
                    <Checkbox
                      onClick={(e) => {
                        onColumnSelection("arrived", e.target.checked);
                      }}
                      checked={selectedColumns.includes("arrived")}
                    >
                      Arrived
                    </Checkbox>
                  ),
                },
                {
                  value: "emplacement",
                  label: (
                    <Checkbox
                      onClick={(e) => {
                        onColumnSelection("emplacement", e.target.checked);
                      }}
                      checked={selectedColumns.includes("emplacement")}
                    >
                      Emplacement
                    </Checkbox>
                  ),
                },
                {
                  value: "exit",
                  label: (
                    <Checkbox
                      onClick={(e) => {
                        onColumnSelection("exit", e.target.checked);
                      }}
                      checked={selectedColumns.includes("exit")}
                    >
                      Exit
                    </Checkbox>
                  ),
                },
                {
                  value: "landing",
                  label: (
                    <Checkbox
                      onClick={(e) => {
                        onColumnSelection("landing", e.target.checked);
                      }}
                      checked={selectedColumns.includes("landing")}
                    >
                      Status
                    </Checkbox>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </div>
      {/* Table Section */}
      <Table
        id="reservation-table"
        columns={columnData}
        dataSource={tableData}
        pagination={tableParams.pagination}
        loading={isLoading}
        onChange={handleTableChange}
        rowClassName={rowClassName}
        style={{ overflow: "auto" }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => console.log("first"), // click row
          };
        }}
      />
      {showModal && (
        <CreateReservation
          slotsData={slotsData}
          loading={loading}
          setShowModal={setShowModal}
          showModal={showModal}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          setInitialValue={setInitialValue}
          rechargeVal={rechargeVal}
          setRechargeVal={setRechargeVal}
          handleCancelReservation={handleCancelReservation}
        />
      )}
    </div>
  );
};

export default BookingOption;
