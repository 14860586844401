import React from "react";

export default function GPS_Module() {
  return (
    <div>
      <iframe
        title="GPS Module"
        src="https://v2.finder-portal.com/tabs/map"
        className="w-full h-screen"
        style={{ height: "calc(100vh - 162px)" }}
      />
    </div>
  );
}
