import React, { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Select, Tabs } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  close,
  sign_in_alt,
  whiteTick,
  save,
  parking,
  disable,
} from "../../assets/icons";
import { getAllHolidaysData } from "../../redux/actions/holidays";
import { getRatesFareData } from "../../redux/actions/rates_and_fare";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const s = {
  modalHeader:
    "bg-[#081258] justify-center text-2xl font-bold flex text-white uppercase p-4",
  button:
    "text-white uppercase px-2 py-2 flex-1 items-center flex justify-center",
  row1: "bg-[#F0F0F0] py-4 w-full pl-6 pr-2",
  row2: "bg-[#F9F9F9] py-2 w-full pl-6",
  row3: "bg-[#F0F0F0] flex gap-3 px-3 py-4",
};

const CreateReservation = ({
  slotsData,
  showModal,
  setShowModal,
  handleSubmit,
  initialValues,
  setInitialValue,
  setRechargeVal,
  rechargeVal,
  handleCancelReservation,
  loading,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [arrivalDate, setArrivalDate] = useState();
  const [passangerVal, setPassangerVal] = useState();
  const states = useSelector((state) => state);
  const rates = states?.getRatesFareOption?.users[0]?.results;
  const holidayRates = states?.getHolidays?.data?.results;
  const [departureDate, setDepartureDate] = useState();
  const demorates = [
    20, 25, 32, 39, 46, 53, 59, 65, 72, 78, 84, 90, 96, 102, 108, 114, 120, 126,
    132, 140, 146, 152, 160, 166, 174, 180, 186, 192, 199, 205,
  ];
  const [payableAmount, setPayableAmount] = useState("");
  const [holidayAmount, setHolidayAmount] = useState(0);
  const [arrivalDateState, setArrivalDateState] = useState(
    initialValues?.arrivalDate
  );
  const [departDateState, setDepartDateState] = useState(
    initialValues?.departureDate
  );
  const [holidayFlag, setHolidayFlag] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllHolidaysData());
    dispatch(getRatesFareData()); // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   console.log(passangerVal,"passangerValpassangerVal")
  //   const passengerValNumber = parseFloat(passangerVal);
  //   const payableAmountNumber = parseFloat(payableAmount);
  //   console.log(payableAmount, previousAmountVal,"hhhhhhhhhhhhhhhhhhhhhhhhh")
  //   let tempAmount;
  //   if(tempAmount !== "") {
  //     tempAmount =  parseFloat(previousAmountVal)
  //   } else {
  //     tempAmount =  parseFloat(payableAmount)
  //   }
  //       // Check if conversion was successful (not NaN)
  //   // if (!isNaN(passengerValNumber) && !isNaN(payableAmountNumber)) {
  //     // Perform addition
  //     console.log(passengerValNumber,"jjjjjjjjjjjjjjjjjjjjjjjjjjjjj", tempAmount)
  //     const result = (passengerValNumber + tempAmount).toString();
  //     form.setFieldValue("bookingAmount", result)
  //     // Convert the result to a string and update payableAmount state
  //     console.log(result,"resulttttttttttttttttttttt")
  //     setPayableAmount(result);
  //   // } else {
  //     // Handle invalid input (e.g., non-numeric input)
  //     // You can add error handling here if needed
  //   // }
  // }
  // , [passangerVal]);

  const getDatesArray = (startDate, endDate) => {
    const datesArray = [];

    // Convert the start and end dates to JavaScript Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Set the current date to the start date
    let currentDate = start;

    // Loop until the current date reaches the end date
    while (currentDate <= end) {
      // Push the current date to the array
      datesArray.push(currentDate.toISOString().split("T")[0]);

      // Increment the current date by 1 day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesArray;
  };

  const isDateInRange = (arrivalDate, departDate, array, days) => {
    const fromDate = new Date(arrivalDate);
    const toDate = new Date(departDate);
    const arrayOfObjects = array;
    let DAYS = days;
    for (let i = 0; i < arrayOfObjects?.length; i++) {
      const startDate = new Date(arrayOfObjects[i].arrivalDate);
      const endDate = new Date(arrayOfObjects[i].departureDate);
      let userSelectedDates = getDatesArray(fromDate, toDate);

      let holidaysDays = getDatesArray(startDate, endDate);

      const result = findHolidayCategory(userSelectedDates, holidaysDays);

      if (result?.holidayDates?.length > 0) {
        if (DAYS !== result.holidayDates.length) {
          DAYS = DAYS - result.holidayDates.length;
        } else {
          DAYS = result.holidayDates.length;
        }
        setHolidayAmount(
          (pre) =>
            pre +
            (arrayOfObjects[i].fixedPrice +
              arrayOfObjects[i].priceIncreaseBy *
                (result.holidayDates.length - 1))
        );
      }
      // else DAYS = 0;
    }
    return DAYS; // Entered date does not fall within any range
  };

  function findHolidayCategory(userSelectedDates, holidayDates) {
    const holidayCategories = ["holidayDates"];
    const datesByCategory = [];
    userSelectedDates.forEach((selectedDate) => {
      let category = null;

      for (let i = 0; i < holidayDates.length; i++) {
        const holidayRange = holidayDates[i];
        //   const [start, end] = holidayRange;
        const rawSelected = new Date(selectedDate);
        const selected = moment(rawSelected).format("YYYY-MM-DD");

        let arr = holidayDates[i];
        // for (let j = 0; j < arr.length; j++) {
        if (selected === arr) {
          setHolidayFlag(true);
          category = holidayCategories;
          break;
        }
        // }
      }

      if (category) {
        if (datesByCategory[category]) {
          datesByCategory[category].push(selectedDate);
        } else {
          datesByCategory[category] = [selectedDate];
        }
      }
    });

    return datesByCategory;
  }

  const calculateAmount = (days, holidayRemainingDays) => {
    if (rates?.length) {
      // Rates for API and demo rates for static rates
      let amountOut = null;

      // Initialize the passengerAmount to 0
      let passengerAmount = 0;

      // if (passangerVal && passangerVal.trim() !== '') {
      //   // Convert the passenger value to a number if it's a valid number
      //   console.log(passangerVal,"kkkkkkkkkkkkkkkkkkkkkkkkkkkkkk")
      //   const passengerAmountNumber = parseFloat(passangerVal);
      //   if (!isNaN(passengerAmountNumber)) {
      //     passengerAmount = passengerAmountNumber;
      //   }
      // }

      if (holidayFlag) {
        if (holidayRemainingDays > 181) {
          const tens = Math.floor(holidayRemainingDays / 10);
          const ones = holidayRemainingDays % 10;
          const amount =
            rates[9][10] * tens +
            rates[ones < 1 ? ones : ones - 1][ones < 1 ? ones + 1 : ones];
          amountOut = +amount + holidayAmount;
        } else {
          if (holidayRemainingDays < days)
            amountOut =
              rates[holidayRemainingDays - 1].fixedPrice + holidayAmount;
          else if (holidayRemainingDays === days) amountOut = holidayAmount;
          else amountOut += holidayAmount; // Hardcoded rateList
        }
      } else {
        setHolidayAmount(0);
        if (days > 181) {
          const tens = Math.floor(days / 10);
          const ones = days % 10;
          const amount =
            rates[9][10] * tens +
            rates[ones < 1 ? ones : ones - 1][ones < 1 ? ones + 1 : ones];
          amountOut = +amount + holidayAmount + passengerAmount;
        } else {
          amountOut = rates[days - 1]?.fixedPrice + holidayAmount;
        }
      }
      amountOut += rechargeVal ? 15 : 0;
      return (+amountOut).toFixed(0);
    }
  };

  useEffect(() => {
    if (
      arrivalDateState &&
      departDateState &&
      demorates.length &&
      rates?.length &&
      holidayRates
    ) {
      // setHolidayFlag(false);
      setHolidayAmount(0);
      const arrivalDate = new Date(arrivalDateState);
      const departDate = new Date(departDateState);
      // const userSelectedDates = getDatesArray(arrivalDate, departDate);
      // To calculate the time difference of two dates
      const Difference_In_Time = departDate.getTime() - arrivalDate.getTime();

      // To calculate the no. of days between two dates
      const days =
        Number((Difference_In_Time / (1000 * 3600 * 24)).toFixed(0)) + 1;
      const holidayRemainingDays = isDateInRange(
        arrivalDate,
        departDate,
        holidayRates,
        days
      );

      const amount = calculateAmount(days, holidayRemainingDays);
      form.setFieldValue("bookingAmount", amount);
      setPayableAmount(amount);
    }
  }, [
    arrivalDateState,
    departDateState,
    demorates,
    rates,
    holidayAmount,
    holidayRates,
    holidayFlag,
  ]);
  const onClose = () => {
    setShowModal(false);
    form.resetFields();
    setArrivalDate("");
    setDepartureDate("");
    setInitialValue({});
  };
  return (
    <Modal
      open={showModal}
      width={1060}
      footer={null}
      closeIcon={
        <span
          className={s.modalCloseButton}
          style={{ color: "white" }}
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} className="mr-2" alt="" />
        </span>
      }
    >
      <div
        className={s.modalHeader}
        onClick={() => {
          onClose();
        }}
      >
        {t("create_reservation")}
      </div>

      <Tabs
        defaultActiveKey="1"
        type="card"
        size="small"
        items={[
          {
            label: `informations`,
            key: 1,
            children: (
              <InformationsComp
                loading={loading}
                slotsData={slotsData}
                setRechargeVal={setRechargeVal}
                rechargeVal={rechargeVal}
                setShowModal={setShowModal}
                handleSubmit={handleSubmit}
                initialValues={initialValues}
                setInitialValue={setInitialValue}
                onClose={onClose}
                arrivalDate={arrivalDate}
                setArrivalDate={setArrivalDate}
                departureDate={departureDate}
                setDepartureDate={setDepartureDate}
                setDepartDateState={setDepartDateState}
                setArrivalDateState={setArrivalDateState}
                form={form}
                handleCancelReservation={handleCancelReservation}
                payableAmount={payableAmount}
                passangerVal={passangerVal}
                setPassangerVal={setPassangerVal}
                setPayableAmount={setPayableAmount}
              />
            ),
          },
          {
            label: `options`,
            key: 2,
            children: `Content of card tab options`,
          },
        ]}
      />
    </Modal>
  );
};

const InformationsComp = ({
  slotsData,
  setShowModal,
  handleSubmit,
  initialValues,
  setInitialValue,
  rechargeVal,
  setRechargeVal,
  form,
  onClose,
  arrivalDate,
  setArrivalDate,
  departureDate,
  setDepartureDate,
  handleCancelReservation,
  setDepartDateState,
  setArrivalDateState,
  payableAmount,
  setPayableAmount,
  passangerVal,
  setPassangerVal,
  loading,
}) => {
  const { t } = useTranslation();
  const staffOptions = [
    { value: "lewoissyni RAS", label: "lewoissyni RAS" },
    { value: "Nassim krichane", label: "Nassim krichane" },
    { value: "Antonio Costa de Jesus", label: "Antonio Costa de Jesus" },
    { value: "MARCO DA COSTA", label: "MARCO DA COSTA" },
    { value: "Yannis", label: "Yannis" },
    { value: "Nassim krichanne", label: "Nassim krichanne" },
    { value: "Antonio Costa de Jesuss", label: "Antonio Costa de Jesuss" },
    { value: "MARCO DA COSTAA", label: "MARCO DA COSTAA" },
  ];
  const platformOptions = [
    { value: "Parking Premier", label: "Parking Premier" },
    { value: "Allo Patrk", label: "Allo Patrk" },
    { value: "Parkos", label: "Parkos" },
    { value: "Holiday Extras", label: "Holiday Extras" },
    { value: "Parkive", label: "Parkive" },
    { value: "Openpark", label: "Openpark" },
    { value: "TravelCar", label: "TravelCar" },
    { value: "Adopte un parking", label: "Adopte un parking" },
    { value: "OPnGO", label: "OPnGO" },
    { value: "Parkingo", label: "Parkingo" },
    { value: "Looking 4 Parking", label: "Looking 4 Parking" },
    { value: "Parclick", label: "Parclick" },
    { value: "Zenpark", label: "Zenpark" },
    { value: "TravelCar Location", label: "TravelCar Location" },
    { value: "ParkCloud", label: "ParkCloud" },
  ];

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);
  useEffect(() => {
    if (
      initialValues?.rechargeVehicleAmount !== "0" &&
      initialValues?.rechargeVehicleAmount !== undefined
    ) {
      setRechargeVal(true);
    } else {
      setRechargeVal(false);
    }
  }, [initialValues?.rechargeVehicleAmount]);

  useEffect(() => {
    if (initialValues.passengerNumber == 5) {
      form.setFieldValue("extraAmount", "5");
      setPassangerVal("5");
    } else if (initialValues.passengerNumber == 6) {
      form.setFieldValue("extraAmount", "10");
      setPassangerVal("10");
    } else if (initialValues.passengerNumber == 7) {
      form.setFieldValue("extraAmount", "15");
      setPassangerVal("15");
    } else {
      //form.setFieldValue("extraAmount", "0")
      setPassangerVal("0");
    }
  }, [initialValues.passengerNumber]);

  const onFinish = (values) => {
    const phoneNumber = values.phoneNo;
    const countryCode = "+33";
    const finalValue = {
      ...values,
      id: initialValues?.id,
      arrived: initialValues?.arrived,
      reservationId: initialValues?.reservationId,
      phoneNo: countryCode + phoneNumber,
      rechargeVehicleAmount: rechargeVal ? "15" : "0",
      bookingAmount: `${payableAmount}`,
    };
    handleSubmit(finalValue);
  };

  const onValuesChange = (changedValues, allValues) => {};
  const validateNames = (_, value) => {
    // Check if the value is a number
    if (!isNaN(value)) {
      return Promise.reject("Cannot be a number");
    }

    // Check if the value contains any digits
    if (/\d/.test(value)) {
      return Promise.reject("Cannot contain numbers");
    }

    return Promise.resolve();
  };

  const validateMobileNumber = (_, value) => {
    // Check if the value is empty
    if (!value) {
      return Promise.resolve();
    }

    // Check if the value contains any characters
    if (/\D/.test(value)) {
      return Promise.reject("Cannot contain characters");
    }

    // Check if the value has more than 10 numbers
    if (value.length > 10) {
      const trimmedValue = value.substring(0, 10); // Trim any characters beyond the 10th position
      return Promise.reject(trimmedValue);
    }

    return Promise.resolve();
  };

  const validateEmail = (_, value) => {
    // Check if the value is empty
    if (!value) {
      return Promise.reject("Email is required");
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(value)) {
      return Promise.reject("Invalid email format");
    }
    return Promise.resolve();
  };

  const validateNumberr = (_, value) => {
    // Check if the value is empty
    if (!value) {
      return Promise.reject("Number is required");
    }

    // Number regular expression pattern
    const numberPattern = /^[0-9]+$/;

    // Check if the value matches the number pattern
    if (!numberPattern.test(value)) {
      return Promise.reject("Cannot be a character");
    }

    return Promise.resolve();
  };

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Input
      addonBefore={<img src={parking} alt="" className="min-w-[19px]" />}
      placeholder={t("arrives_at_parking")}
      value={value}
      className="min-w-[220px] w-full"
      // style={{ minWidth: "220px" }}
      onClick={onClick}
      ref={ref}
    />
  ));

  const DepartureCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Input
      addonBefore={<img src={parking} alt="" className="min-w-[19px]" />}
      placeholder={t("return_flight_landing_time")}
      value={value}
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
    />
  ));

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterPassedTimeArrival = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };
  const slotsDataOptions = get(slotsData, "result", []).map((item) => ({
    label: `${item.column}/${item.row}`,
    value: `${item.column}/${item.row}`,
  }));

  const handlePassengerChange = (value) => {
    form.setFieldValue("passengerNumber", value);
    if (value == 5) {
      form.setFieldValue("extraAmount", "5");
      setPassangerVal("5");
    } else if (value == 6) {
      form.setFieldValue("extraAmount", "10");
      setPassangerVal("10");
    } else if (value == 7) {
      form.setFieldValue("extraAmount", "15");
      setPassangerVal("15");
    } else {
      form.setFieldValue("extraAmount", "0");
      setPassangerVal(0);
    }
  };

  const handleRechargeClick = (event) => {
    event.preventDefault();

    // Toggle the rechargeVal state
    setRechargeVal(!rechargeVal);

    // Calculate the new payableAmount
    const passengerAmountNumber = parseFloat(passangerVal);
    const currentAmount = parseFloat(form.getFieldValue("bookingAmount"));

    let updatedAmount = currentAmount;

    if (!isNaN(passengerAmountNumber) && !isNaN(currentAmount)) {
      if (rechargeVal) {
        // Subtract 15 from the current amount if rechargeVal is true
        updatedAmount = currentAmount - 15;
      } else {
        // Add 15 to the current amount if rechargeVal is false
        updatedAmount = currentAmount + 15;
      }
    }

    // Update the bookingAmount field in the form
    form.setFieldsValue({ bookingAmount: updatedAmount.toFixed(0) });

    // Update the payableAmount state (assuming you have a corresponding state variable)
    setPayableAmount(updatedAmount);
  };

  const isOptionDisabled = (value) => {
    return value === passangerVal;
  };

  return (
    <Form
      layout="inline"
      className="w-full"
      form={form}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
      id="create-reservation-modal"
    >
      <div className="flex flex-col">
        <div className={s.row1}>
          <Row gutter={[8, 16]} className="w-full">
            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="staff"
              >
                <Select
                  defaultValue="--*Chauffeur/Staff--"
                  className="w-28"
                  options={staffOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="platform"
              >
                <Select
                  defaultValue={`--*${t("platform")}--`}
                  className="w-28"
                  options={platformOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="bookingAmount">
                <Input
                  placeholder={
                    payableAmount !== "" ? payableAmount : t("booking_amount")
                  }
                  className="rounded-none"
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={s.row2}>
          <Row gutter={[8, 16]} className="w-full mt-4">
            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  { validator: validateNames },
                ]}
                name="firstName"
              >
                <Input
                  placeholder={`*${t("firstname")}`}
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  { validator: validateNames },
                ]}
                name="lastName"
              >
                <Input
                  placeholder={`*${t("lastname")}`}
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  { validator: validateMobileNumber },
                ]}
                name="phoneNo"
              >
                <Input
                  placeholder={`*${t("phone")}`}
                  className="rounded-none"
                  maxLength={10}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  { validator: validateEmail },
                ]}
                name="email"
              >
                <Input placeholder="*Email" className="rounded-none" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  { validator: validateNumberr },
                ]}
                name="passengerNumber"
              >
                <Select
                  placeholder={`*${t("num_passengers")}`}
                  className="rounded-none"
                  onChange={(value) => handlePassengerChange(value)}
                >
                  <Select.Option value="1" disabled={isOptionDisabled("1")}>
                    1
                  </Select.Option>
                  <Select.Option value="2" disabled={isOptionDisabled("2")}>
                    2
                  </Select.Option>
                  <Select.Option value="3" disabled={isOptionDisabled("3")}>
                    3
                  </Select.Option>
                  <Select.Option value="4" disabled={isOptionDisabled("4")}>
                    4
                  </Select.Option>
                  <Select.Option value="5" disabled={isOptionDisabled("5")}>
                    5
                  </Select.Option>
                  <Select.Option value="6" disabled={isOptionDisabled("6")}>
                    6
                  </Select.Option>
                  <Select.Option value="7" disabled={isOptionDisabled("7")}>
                    7
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  { validator: validateNumberr },
                ]}
                name="extraAmount"
              >
                <Input
                  placeholder={`*${t("extra_amount")}`}
                  className="rounded-none"
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="vehicleInfo"
              >
                <Input
                  placeholder={`*${t("make_model")}`}
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="vehicleNumber"
              >
                <Input placeholder="*Immat" className="rounded-none" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="registration">
                <Input
                  placeholder="*Registration"
                  className="rounded-none"
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="rearSeat">
                <Input
                  addonBefore={
                    <img src={sign_in_alt} alt="" className="min-w-[19px]" />
                  }
                  placeholder={`*${t("rear_seat")}`}
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="parkingSlot"
              >
                <Select
                  placeholder={`*${t("parking_slot")}`}
                  className="rounded-none"
                  options={slotsDataOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="outdoorParking">
                <Input
                  placeholder={`*${t("outdoor_parking")}`}
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
            <Col span={8}></Col>
            <Col span={24}>
              <Form.Item name="comments">
                <TextArea
                  rows={4}
                  placeholder={`${t("comment")}`}
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8]} className="w-full mt-4">
            <Col xs={12} lg={8}>
              <Form.Item name="arrivalDate" className="w-full">
                <DatePicker
                  wrapperClassName="w-full"
                  className="w-full"
                  selected={arrivalDate}
                  onChange={(date) => {
                    setArrivalDate(date);
                    setArrivalDateState(new Date(date)?.toISOString());
                    form.setFieldsValue({ arrivalDate: date });
                  }}
                  customInput={<ExampleCustomInput />}
                  showTimeSelect
                  filterTime={filterPassedTimeArrival}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  // dateFormat="yyyy/MM/d HH:mm"
                  minDate={new Date(departureDate)}
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item name="departureDate" className="w-full">
                <DatePicker
                  wrapperClassName="w-full"
                  className="w-full"
                  selected={departureDate}
                  onChange={(date) => {
                    setDepartureDate(date);
                    form.setFieldValue("departureDate", date);
                    setDepartDateState(new Date(date)?.toISOString());
                  }}
                  customInput={<DepartureCustomInput />}
                  showTimeSelect
                  filterTime={filterPassedTime}
                  dateFormat="yyyy/MM/d HH:mm"
                  minDate={new Date()}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className={s.row3}>
          <button
            style={
              get(initialValues, "id", "") === ""
                ? { marginLeft: "219px" }
                : null
            }
            className={
              !rechargeVal
                ? "bg-[rgb(151,158,209)] " + s.button
                : "bg-[#081258] " + s.button
            }
            onClick={handleRechargeClick}
          >
            {rechargeVal && <img src={whiteTick} className="mr-2" alt="" />}
            {rechargeVal ? `${t("recharged")}(+15€)` : `${t("recharge")}(+15€)`}
          </button>
          <button
            className={"bg-[#081258] " + s.button}
            onClick={() => onClose()}
            type="button"
          >
            <img src={close} className="mr-2" alt="" /> {t("close")}
          </button>
          {/* <button className={"bg-[#E12714] " + s.button}>
            <img src={envelope} className="mr-2" alt="" /> Send ticket
          </button> */}
          {get(initialValues, "id", "") !== "" && (
            <button
              className={"bg-[#E12714] flex-2 " + s.button}
              type="button"
              onClick={() =>
                handleCancelReservation(get(initialValues, "id", ""))
              }
            >
              <img src={disable} className="mr-2" alt="" />{" "}
              {t("cancel_reservation")}
            </button>
          )}
          <button
            className={"bg-[#E12714] " + s.button}
            type="submit"
            disabled={loading}
          >
            <img src={save} className="mr-2" alt="" />{" "}
            {loading ? `${t("loading")}...` : t("save")}
            {loading && <LoadingOutlined />}
          </button>
        </div>
      </div>
    </Form>
  );
};
export default CreateReservation;
