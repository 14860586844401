import React from "react";
import { Space, Table, Popconfirm, notification } from "antd";
import { useEffect, useState } from "react";
import { deleteIcon, pencil_white, whiteTick2 } from "../../assets/icons";
import { renderComp, renderNumberComp } from "../../utils/extras";
import { useDispatch, useSelector } from "react-redux";
import {
  EditRatesFareOption,
  getRatesFareData,
  addRatesFareOptrion,
  deleteRatesFare,
} from "../../redux/actions/rates_and_fare";
import { isEmpty, get } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "../../utils/axios";
import { GET_INCREASE_PER_DAY } from "../../urls";
import { useTranslation } from "react-i18next";

const Reservation = (props) => {
  const { t } = useTranslation();
  const { activeKey, compLoading, setCompLoading } = props;
  const dispatch = useDispatch();
  const bookingOptionData = useSelector((state) => state);
  const [api, contextHolder] = notification.useNotification();
  const rateAndFeeData =
    bookingOptionData?.getRatesFareOption?.users[0]?.results;
  const isLoading = get(
    bookingOptionData,
    "getRatesFareOption.isLoading",
    false
  );
  const [data, setData] = useState([]);
  const [editingRow, setEditingRow] = useState({});
  const [addRowState, setAddRowState] = useState();
  const [addActionState, setAddActionState] = useState(false);
  const [editValidation, setEditValidation] = useState(false);
  const [total, setTotal] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 30,
    },
    category: "RESERVATION",
  });
  const [staticRow, setStaticRow] = useState({
    _id: "64edbc423a6dcfb96bb98d89",
    of: "--",
    "Calculation Type": "Rate Per Day",
    fixedPrice: 0,
    disabled: true,
  });
  const handleChange = (val, fieldName, itemId) => {
    if (editMode) {
      setData((prevData) => [...prevData]);
      const updatedEditedValues = { ...editedValues };

      updatedEditedValues[itemId] = {
        ...updatedEditedValues[itemId],
        [fieldName]: val,
      };

      setEditedValues(updatedEditedValues);
    }
  };

  const handleSave = async () => {
    if (editMode) {
      const payload = Object.keys(editedValues).map((itemId) => ({
        id: itemId,
        of: editedValues[itemId].of,
        fixedPrice: editedValues[itemId].fixedPrice,
      }));

      const requestObject = {
        payload,
        location: "ORLY",
      };

      await dispatch(EditRatesFareOption(requestObject));
      dispatch(getRatesFareData(tableParams));
      getStaticRowData();
      setEditMode(false);
    }
  };

  useEffect(() => {
    if (activeKey === 1) {
      dispatch(getRatesFareData(tableParams));
    }
    // eslint-disable-next-line
  }, [tableParams, activeKey, staticRow]);

  const getStaticRowData = async () => {
    try {
      const res = await axios.get(GET_INCREASE_PER_DAY);
      if (res?.data?.data?.results) {
        setStaticRow({
          ...staticRow,
          fixedPrice: res.data.data.results.fixedPrice,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (activeKey === 1) {
      getStaticRowData();
    }
    // eslint-disable-next-line
  }, [activeKey]);

  const prepareData = (bookingData) => {
    const bData = bookingData.map((item) => ({
      ...item,
      email: item?.of,
      costPerUnit: `${(+item?.costPerUnit).toFixed(2)} €`,
    }));
    return bData;
  };

  useEffect(() => {
    if (rateAndFeeData && isEmpty(editingRow)) {
      const bData = prepareData(rateAndFeeData);
      setData(bData);
      setTotal(bookingOptionData?.getRatesFareOption?.users[0]?.itemCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateAndFeeData, editingRow]);

  useEffect(() => {
    if (total) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: total,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);
  const handleEditSubmit = async (values) => {
    const fixedRate = Number(localStorage.getItem("fixedPrice"));
    const of = Number(localStorage.getItem("of"));
    // const at = Number(localStorage.getItem("at"));
    // const costPerUnit = Number(localStorage.getItem("costPerUnit"));
    // const opFirst = Number(localStorage.getItem("op-first"));
    // const opSecond = Number(localStorage.getItem("op-seconds"));
    if (!isEmpty(localStorage.getItem("fixedPrice"))) {
      setCompLoading(true);
      // delete values?._id;
      const requestObject = {
        id: values._id,
        of: of,
        fixedPrice: fixedRate,
        // category: "RESERVATION"
        // at: at,
        // costPerUnit: costPerUnit,
      };
      const result = await dispatch(EditRatesFareOption(requestObject));
      if (get(result, "status", "") === 200) {
        notification.success({
          placement: "topRight",
          message: "Reservation added Succesfully!",
        });
        setCompLoading(false);
        setEditValidation(false);
        setEditingRow({});
        dispatch(getRatesFareData(tableParams));
        localStorage.removeItem("of");
        localStorage.removeItem("at");
        localStorage.removeItem("fixedPrice");
        localStorage.removeItem("costPerUnit");
      } else {
        notification.error({
          placement: "topRight",
          message: result?.response?.data?.error,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const handleAddSubmit = async (values) => {
    const fixedRate = Number(localStorage.getItem("fixedPrice"));
    const of = Number(localStorage.getItem("of"));
    const at = Number(localStorage.getItem("at"));
    const costPerUnit = Number(localStorage.getItem("costPerUnit"));
    // const opFirst = Number(localStorage.getItem("op-first"));
    // const opSecond = Number(localStorage.getItem("op-seconds"));
    if (!isEmpty(localStorage.getItem("fixedPrice"))) {
      setCompLoading(true);
      delete values?._id;
      const requestObject = {
        // id: values.id,
        of: of,
        at: at,
        fixedPrice: fixedRate,
        costPerUnit: costPerUnit,
        category: "RESERVATION",
      };
      const result = await dispatch(addRatesFareOptrion(requestObject));
      if (result.status === 200) {
        notification.success({
          placement: "topRight",
          message: "Reservation added Succesfully!",
        });
        setAddRowState(false);
        setAddActionState(false);
        dispatch(getRatesFareData(tableParams));
        localStorage.removeItem("of");
        localStorage.removeItem("at");
        localStorage.removeItem("fixedPrice");
        localStorage.removeItem("costPerUnit");
        setCompLoading(false);
      } else {
        notification.error({
          placement: "topRight",
          message: result?.response?.data?.error,
        });
        setCompLoading(false);
      }
    }
  };
  const handleCancel = () => {
    setAddActionState(false);
    setEditValidation(false);
    setEditingRow({});
    setAddRowState(false);
    localStorage.removeItem("of");
    localStorage.removeItem("at");
    localStorage.removeItem("fixedPrice");
    localStorage.removeItem("costPerUnit");
    // setAddRowState(false);
  };
  const actionColumn = {
    title: (
      <img
        src={editMode ? whiteTick2 : pencil_white}
        alt="edit_Icon"
        onClick={() => {
          if (editMode) {
            handleSave();
          } else {
            setEditMode(true);
          }
        }}
        style={{ cursor: "pointer" }}
      />
    ),
    dataIndex: "actions",
    sorter: false,
    render: (_, record) => {
      return (
        <Space size="middle">
          {compLoading && editingRow.email === record.email ? (
            <LoadingOutlined />
          ) : (
            <button
              onClick={() => {
                if (addRowState) {
                  handleAddSubmit(data);
                } else {
                  !isEmpty(editingRow) && editingRow?.email === record?.email
                    ? handleEditSubmit(record)
                    : handleEdit(record);
                }
              }}
            >
              {/* <img
                src={editingRow?.email === record?.email ? tick : pencil}
                alt="edit_Icon"
              /> */}
            </button>
          )}
          <Popconfirm
            id="custom-delete-dialog"
            title={`${t("are_you_sure")} ${
              !(editValidation || addActionState) ? t("delete") : t("cancel")
            }?`}
            onConfirm={async () => {
              if (editValidation || addActionState) {
                handleCancel();
              } else {
                await dispatch(deleteRatesFare({ id: record._id }));
                dispatch(getRatesFareData(tableParams));
              }
            }}
            className="popOver_bgColor"
            okText={t("yes")}
            cancelText={t("no")}
          >
            {editingRow?.email !== record?.email && (
              <button>
                <img id="deleteImg" src={deleteIcon} alt="delete_Icon" />
              </button>
            )}
          </Popconfirm>
        </Space>
      );
    },
  };

  const columns = [
    {
      title: "Calculation Type",
      dataIndex: "",
      sorter: true,
      render: (exit, record) =>
        record._id === "64de06665a59461cd21c6d93" ? (
          <div>{staticRow["Calculation Type"]}</div>
        ) : (
          <div>Number Of Days</div>
        ),
    },
    {
      title: "Of",
      dataIndex: "of",
      sorter: true,
      render: (_, record, i) =>
        renderComp(record, record.of, editMode, "of", record._id, handleChange),
    },
    {
      title: "At",
      dataIndex: "at",
      sorter: true,
      render: (exit) => <div>+</div>,
      // render: (_, record, i) =>
      //   renderComp(
      //     record,
      //     "0",
      //     "text"
      // editingRow,
      // addRowState,
      // (val) => {
      //   localStorage.setItem("at", val);
      // }
      // ),
    },
    {
      title: "Fixed Price",
      dataIndex: "fixedPrice",
      sorter: true,
      render: (_, record) =>
        renderNumberComp(
          record,
          record.fixedPrice,
          editMode,
          addRowState,
          "fixedPrice",
          (val) => handleChange(val, "fixedPrice", record._id)
        ),
    },
    {
      title: "op",
      dataIndex: "op-first",
      sorter: true,
      render: (exit) => <div>+</div>,
      // render: (_, record) =>
      //   renderComp(
      //     record,
      //     "+",
      //     "text"
      // editingRow,
      // addRowState,
      // (val) => {
      //   localStorage.setItem("op-first", val);
      // }
      // ),
    },
    {
      title: "Cost per unit",
      dataIndex: "costPerUnit",
      sorter: true,
      render: (exit) => <div>0 €</div>,
      // render: (_, record) =>
      //   renderComp(
      //     record,
      //     "0 €",
      //     "text"
      // `${parseInt(record.costPerUnit, 10).toFixed(2)} €`,
      // editingRow,
      // addRowState,
      // (val) => {
      //   localStorage.setItem("costPerUnit", val.replace(/ €/g, ""));
      // }
      // ),
    },
    {
      title: "op",
      dataIndex: "op-seconds",
      sorter: true,
      render: (exit) => <div>+</div>,
      // render: (_, record) =>
      //   renderComp(
      //     record,
      //     "+",
      //     "text"
      // editingRow,
      // addRowState,
      // (val) => {
      //   localStorage.setItem("op-seconds", val);
      // }
      // ),
    },
    actionColumn,
  ];

  // const [columnData, setColumnData] = useState(columns);

  // useEffect(() => {
  //   if (editingRow || addRowState) {
  //     setColumnData(columns);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [editingRow, addRowState]);

  const handleAdd = (record) => {
    localStorage.setItem("fixedPrice", 0);
    localStorage.setItem("of", 1);
    // localStorage.setItem("at", 1);
    // localStorage.setItem("costPerUnit", 0);
    setAddRowState(record);
  };

  const handleEdit = (record) => {
    setEditValidation(true);
    localStorage.setItem("fixedPrice", parseInt(record?.fixedPrice, 10));
    localStorage.setItem("of", parseInt(record?.of, 10));
    // localStorage.setItem("at", parseInt(record?.at, 10));
    // localStorage.setItem("costPerUnit", parseInt(record?.costPerUnit, 10));
    setEditingRow(record);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "" : "bg-gray-200";
    // Apply gray background to odd-indexed rows
  };
  const saveHandler = () => {};

  return (
    <>
      {contextHolder}
      <Table
        id="reservation-table"
        columns={columns}
        dataSource={[...data.slice(0, 30), staticRow]}
        pagination={false}
        loading={isLoading}
        onChange={handleTableChange}
        rowClassName={rowClassName}
        rowKey={(record) => record?._id}
      />
    </>
  );
};

export default Reservation;
