import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Space, Table, Popconfirm, notification } from "antd";
import { addRow, deleteIcon, pencil, tick } from "../../assets/icons";
import { renderComp2 } from "../../utils/extras";
import {
  getAllBlockDatesData,
  addBlockDatesData,
  updateBlockDatesData,
  deleteBlockDatesData,
} from "../../redux/actions/blockDates";
import { useTranslation } from "react-i18next";

const LateFee = (props) => {
  const { t } = useTranslation();
  const { activeKey } = props;
  const dispatch = useDispatch();
  const bookingOptionData = useSelector(
    (state) => state?.blockDataDates?.dates[0]?.itemCount
  );
  const blockDates = useSelector(
    (state) => state?.blockDataDates?.dates[0]?.results
  );
  const [data, setData] = useState([]);
  const [editingRow, setEditingRow] = useState({});
  const [addRowState, setAddRowState] = useState();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    category: "RESERVATION",
  });

  useEffect(() => {
    if (activeKey === 3) {
      setLoading(true);
      dispatch(getAllBlockDatesData(tableParams));
      setLoading(false);
    } // eslint-disable-next-line
  }, [tableParams, activeKey]);

  useEffect(() => {
    if (blockDates) {
      setData(blockDates);
      setTotal(bookingOptionData || 0);
    } // eslint-disable-next-line
  }, [blockDates, editingRow]);

  useEffect(() => {
    if (total) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: total,
        },
      });
    } // eslint-disable-next-line
  }, [total]);

  const handleFieldChange = (value, fieldName, itemId) => {
    if (addRowState) {
      setAddRowState((prevAddRowState) => ({
        ...prevAddRowState,
        [fieldName]: value,
      }));
    } else {
      setEditingRow((prevEditingRow) => ({
        ...prevEditingRow,
        [fieldName]: value,
      }));
    }
  };

  const handleEditSubmit = async (values) => {
    const requestObject = {
      id: values._id,
      to: values.to.toString(),
      from: values.from,
      event: values.event,
      comment: values.comment,
      location: "ORLY",
    };
    await dispatch(updateBlockDatesData(requestObject));
    setEditingRow({});
    dispatch(getAllBlockDatesData(tableParams));
  };

  const handleAddSubmit = async (values) => {
    const { _id, ...payloadWithoutId } = values;
    const addBlockDatesApi = await dispatch(
      addBlockDatesData({ ...payloadWithoutId, location: "ORLY" })
    );
    if (addBlockDatesApi === 400) {
      notification.error({
        message: "Date already exist",
      });
    } else {
      setAddRowState(false);
      dispatch(getAllBlockDatesData(tableParams));
    }
  };

  const actionColumn = {
    title: (
      <img
        src={addRow}
        alt=""
        onClick={() => addAction()}
        style={{ cursor: "pointer" }}
      />
    ),
    dataIndex: "actions",
    sorter: false,
    render: (_, record, index) => {
      return (
        <Space size="middle">
          <button
            onClick={() => {
              if (addRowState?._id === "" && editingRow?.to === undefined) {
                handleAddSubmit(addRowState);
              }
              if (editingRow?._id === record._id) {
                handleEditSubmit(editingRow);
              }
              if (
                editingRow?.to === undefined &&
                addRowState?.to === undefined
              ) {
                handleEdit(record);
              }
            }}
          >
            <img
              src={
                editingRow?._id === record._id
                  ? index === 0
                    ? tick
                    : pencil
                  : addRowState?._id === record._id
                  ? tick
                  : pencil
              }
              alt="edit_Icon"
            />
          </button>
          <Popconfirm
            id="custom-delete-dialog"
            title={`${t("are_you_sure")} ${t("delete")}?`}
            onConfirm={async () => {
              await dispatch(deleteBlockDatesData({ id: record._id }));
              dispatch(getAllBlockDatesData(tableParams));
            }}
            className="popOver_bgColor"
            okText={t("yes")}
            cancelText={t("cancel")}
          >
            <button>
              <img src={deleteIcon} alt="delete_Icon" />
            </button>
          </Popconfirm>
        </Space>
      );
    },
  };

  const columns = [
    {
      title: "From",
      dataIndex: "from",
      sorter: true,
      render: (_, record) =>
        renderComp2(
          record,
          record.from,
          editingRow?._id === record._id || addRowState?._id === record._id,
          "date",
          (val) => handleFieldChange(val, "from", record._id)
        ),
    },
    {
      title: "To",
      dataIndex: "to",
      sorter: true,
      render: (_, record) =>
        renderComp2(
          record,
          record.to,
          editingRow?._id === record._id || addRowState?._id === record._id,
          "date",
          (val) => handleFieldChange(val, "to", record._id)
        ),
    },
    {
      title: "Event",
      dataIndex: "event",
      sorter: true,
      render: (_, record) =>
        renderComp2(
          record,
          record.event,
          editingRow?._id === record._id || addRowState?._id === record._id,
          "text",
          (val) => handleFieldChange(val, "event", record._id)
        ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      sorter: true,
      render: (_, record) =>
        renderComp2(
          record,
          record.comment,
          editingRow?._id === record._id || addRowState?._id === record._id,
          "text",
          (val) => handleFieldChange(val, "comment", record._id)
        ),
    },
    actionColumn,
  ];

  const [columnData, setColumnData] = useState(columns);

  useEffect(() => {
    if (editingRow || addRowState) {
      setColumnData(columns);
    } // eslint-disable-next-line
  }, [editingRow, addRowState]);

  const handleAdd = (record) => {
    setAddRowState(true);
  };

  const addAction = () => {
    const requestObject = {
      _id: "",
      to: null,
      from: null,
      event: "",
      comment: "",
    };

    setData((prevData) => [requestObject, ...prevData]);
    handleAdd(requestObject);
    setAddRowState(requestObject);
  };

  const handleEdit = (record) => {
    setEditingRow(record);
    setAddRowState(null);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setEditingRow({});
      setData([]);
    }
  };

  const rowClassName = (_, index) => {
    return index % 2 === 0 ? "" : "bg-gray-200";
  };

  return (
    <Table
      id="reservation-table"
      columns={columnData}
      dataSource={data}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
      rowClassName={rowClassName}
      rowKey={(r) => r?._id}
    />
  );
};

export default LateFee;
