import { Layout } from "antd";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const { Content } = Layout;

const LayoutModel = ({component, title}) => {
  return (
    <Layout className="min-h-screen overflow-hidden max-h-screen">
      <Sidebar />
      <Layout className="site-layout">
        <Header title={title}/>
        <Content className="m-4 overflow-auto bg-[#F5F5F5]">
          {component}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default LayoutModel;
