import React, { useEffect, useState } from "react";
import {
  Avatar,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  notification,
  Spin,
} from "antd";
import { Collapse } from "antd";
import {
  addRow_black,
  deleteIcon,
  pencil,
  tick,
  closeBlack,
} from "../../assets/icons";
import {
  renderCompDatePicker,
  renderNumberCompCommission,
} from "../../utils/extras";
import {
  getAllCommissionPlateformData,
  EditCommissionOption,
  addCommissionOption,
  deleteCommissions,
} from "../../redux/actions/commissions";
import { isEmpty, get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import commissionColors from "./CommissionColors";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const Commissions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allData = useSelector((state) => state);
  const allCommissionData = get(
    allData,
    "getCommissionsData.data[0].results",
    []
  );
  const isLoading = get(allData, "getCommissionsData.isLoading", []);

  const [rawData, setRawData] = useState([]);
  const [platformData, setPlateformData] = useState([]);
  const [panelIndex, setPanelIndex] = useState([]);
  const [panelIndex2, setPanelIndex2] = useState([]);
  const [activePanelData, setActivePanelData] = useState([]);
  const [activePanelData2, setActivePanelData2] = useState([]);
  let arrPanelIndex = [];
  let arrPanelIndex2 = [];
  const [editingRow, setEditingRow] = useState();
  const [addRowState, setAddRowState] = useState(false);
  const [addActionState, setAddActionState] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [editValidation, setEditValidation] = useState(false);

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [paginationTable, setPaginationTable] = useState([]);

  const currentDate = moment().format("yyyy-MM-DD"); //yyyy-MM-DDTHH:mm:ss.SSS[Z]
  let commission = 0;
  const [panelTableParams, setPanelTableParams] = useState({
    pageSize: 3,
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const getPagination = (platform) => {
    const a = paginationTable.find((item) => item.platform === platform);
    return a;
  };

  const handleTableChange = (platform, id) => {
    const index = paginationTable.findIndex(
      (item) => item.platform === platform.platform
    );
    const addIndex = paginationTable.findIndex(
      (item) => item.platform === platform.platform
    );
    if (id === "addAction") {
      if (addIndex !== -1) {
        paginationTable[index] = {
          current: 1, // Replace with the desired new value
          pageSize: platform.pageSize, // Replace with the desired new value
          platform: platform.platform, // Replace with the desired new value
        };
        setPaginationTable(paginationTable);
        return paginationTable[index];
      }
    } else {
      if (index !== -1) {
        paginationTable[index] = {
          current: platform.current, // Replace with the desired new value
          pageSize: platform.pageSize, // Replace with the desired new value
          platform: platform.platform, // Replace with the desired new value
        };

        setPaginationTable(paginationTable);
        return paginationTable[index];
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem("to");
    localStorage.removeItem("from");
    localStorage.removeItem("platform");
    localStorage.removeItem("commission");
    dispatch(getAllCommissionPlateformData(tableParams));
    setRawData(allCommissionData);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (allCommissionData) {
      setRawData(allCommissionData);
      setTotal(get(allData, "getCommissionsData.data[0].itemCount", ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCommissionData]);

  useEffect(() => {
    const pagination = rawData.map((e, i) => {
      return { current: 1, pageSize: 3, total: e.count, platform: e._id };
    });
    if (rawData) {
      setPaginationTable(pagination);
    }
  }, [rawData]);

  const removeDuplicates = (arr) => {
    const valueCounts = {};
    arr.forEach((value) => {
      if (!valueCounts[value]) {
        valueCounts[value] = 1;
      } else {
        valueCounts[value]++;
      }
    });
    const uniqueArray = arr.filter((value) => valueCounts[value] % 2);
    // setActivePanelData(uniqueArray);
    return uniqueArray;
  };
  useEffect(() => {
    setActivePanelData(removeDuplicates(panelIndex));
    setActivePanelData2(removeDuplicates(panelIndex2));
  }, [panelIndex, panelIndex2]);

  const HeaderComp = (e) => {
    const companyName = e._id;
    const companyNameInitials = e._id.split(" ", 2);

    const color = commissionColors.filter((name, i) => {
      return e._id === Object.keys(name)[0];
    });

    for (const obj of e.items) {
      const fromDate = obj.from;
      const toDate = obj.to;
      if (currentDate >= fromDate && currentDate <= toDate) {
        commission = obj.commission;
        break; // Exit the loop if current date is within any range
      } else {
        commission = 0;
      }
    }

    return (
      <div className="flex gap-1 items-center">
        <Avatar
          style={{
            backgroundColor: color.length ? color[0][companyName] : e.color,
            marginRight: "0.5rem",
          }}
        >
          {companyNameInitials.map((str) => str[0].toUpperCase())}
        </Avatar>
        <span className="flex-1">{e._id}</span>
        <span className="bg-[#081258] px-3 py-[2px] text-white rounded-full">
          {commission ? commission : 0}
        </span>
      </div>
    );
  };
  const handleAdd = (record) => {
    localStorage.setItem("to", record.to);
    localStorage.setItem("from", record.from);
    localStorage.setItem("commission", record.commission);
    localStorage.setItem("platform", record.platform);
    setAddRowState(record);
  };

  const PanelContent = (data) => {
    const platformName = localStorage.getItem("platform");
    const panelData = rawData.find((abc) => abc._id === platformName);
    const newRowData =
      addRowState !== false && platformName !== null
        ? [addRowState, ...panelData?.items]
        : [];

    if (
      addRowState !== false &&
      !isEmpty(newRowData) &&
      isEmpty(platformData)
    ) {
      setPlateformData(newRowData);
    }
    if (addRowState === false && editingRow === false) {
      setPlateformData(data.data);
    }
    const handleClearLocalStorageValue = () => {
      localStorage.removeItem("to");
      localStorage.removeItem("from");
      localStorage.removeItem("platform");
      localStorage.removeItem("commission");
    };
    const addAction = () => {
      handleCancel();
      setAddActionState(true);
      if (!editValidation) {
        const requestObject = {
          _id: "newRow",
          to: currentDate,
          from: currentDate,
          commission: 0,
          platform: data.data[0].platform,
        };
        let addPagination = {
          current: 1,
          pageSize: 3,
          platform: data.data[0].platform,
        };
        handleTableChange(addPagination, "addAction");
        // setPlateformData((pre) => {
        //   return [requestObject, ...pre];
        // });
        handleAdd(requestObject);
      } else
        api.warning({
          message: `Warning:`,
          description: "Please complete your existing action before",
          placement: "topRight",
        });
    };
    const handleAddSubmit = async (values) => {
      setLoading(true);
      const commission = parseInt(localStorage.getItem("commission"), 10);
      const from = localStorage.getItem("from");
      const to = localStorage.getItem("to");
      const platform = localStorage.getItem("platform");
      if (!isEmpty(localStorage.getItem("commission"))) {
        const requestObject = {
          // id: values.id,
          to: to,
          from: from,
          platform: platform,
          commission: commission,
        };
        const result = await dispatch(addCommissionOption(requestObject));
        if (get(result, "statusCode", "") === (200 || 201)) {
          notification.success({
            placement: "topRight",
            message: "Commission Added Succesfully!",
          });
          setLoading(false);
          setAddRowState({});
          setAddActionState(false);
          setEditingRow(false);
          setColumnData(columns);
          dispatch(getAllCommissionPlateformData(tableParams));
          localStorage.removeItem("to");
          localStorage.removeItem("from");
          localStorage.removeItem("platform");
          localStorage.removeItem("commission");
        } else {
          setLoading(false);
          notification.error({
            message: "Error",
            description: result.response.data.message,
            placement: "topRight",
          });
        }
      } else {
        setLoading(false);
      }
    };
    const handleCancel = () => {
      setAddActionState(false);
      setEditValidation(false);
      setEditingRow(false);
      setAddRowState(false);
      handleClearLocalStorageValue();
      setPlateformData([]);
      setEditingRow({});
    };
    const handleEdit = (record) => {
      setEditValidation(true);
      const from = moment(record.from).format("YYYY-MM-DD");
      const to = moment(record.to).format("YYYY-MM-DD");
      localStorage.setItem("commission", record.commission);
      localStorage.setItem("from", from);
      localStorage.setItem("to", to);
      localStorage.setItem("platform", record.platform);
      setEditingRow(record);
    };

    const handleEditSubmit = async (values) => {
      const commission = parseInt(localStorage.getItem("commission"), 10);
      const from = localStorage.getItem("from");
      const to = localStorage.getItem("to");
      const platform = localStorage.getItem("platform");
      if (!isEmpty(localStorage.getItem("commission"))) {
        setLoading(true);
        const requestObject = {
          id: values._id,
          to: to,
          from: from,
          platform: platform,
          commission: commission,
        };
        const result = await dispatch(EditCommissionOption(requestObject));
        if (get(result, "statusCode", "") === 200) {
          notification.success({
            placement: "topRight",
            message: "Commission Updated Succesfully!",
          });
          setEditValidation(false);
          setEditingRow(undefined);
          setColumnData(columns);
          setPlateformData([]);
          setLoading(false);
          dispatch(getAllCommissionPlateformData(tableParams));
          localStorage.removeItem("to");
          localStorage.removeItem("from");
          localStorage.removeItem("platform");
          localStorage.removeItem("commission");
          setLoading(false);
        } else {
          notification.error({
            message: "Error",
            description: result.data.message,
            placement: "topRight",
          });
          setLoading(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const actionColumn = {
      title: (
        <img
          src={addRow_black}
          alt=""
          onClick={(e) => {
            setAddActionState(true);
            addAction();
          }}
          style={{ cursor: "pointer" }}
        />
      ),
      dataIndex: "actions",
      sorter: false,
      render: (_, record) => {
        return (
          <Space size="middle">
            <button
              onClick={() => {
                if (!isEmpty(addRowState)) {
                  handleAddSubmit();
                } else {
                  !isEmpty(editingRow) &&
                  get(editingRow, "_id", "") === get(record, "_id", "")
                    ? handleEditSubmit(record)
                    : handleEdit(record);
                }
              }}
            >
              {/* {addRowState && <img src={tick} alt="edit_Icon" />} */}
              <img
                src={
                  get(editingRow, "_id", "") === get(record, "_id", "") ||
                  (get(addRowState, "_id", "") === "newRow" &&
                    get(record, "_id", "") === "newRow" &&
                    addRowState.platform === record.platform)
                    ? tick
                    : pencil
                }
                alt="edit_Icon"
              />
            </button>
            <Popconfirm
              id="custom-delete-dialog"
              title={`${t("are_you_sure")} ${
                !(
                  get(editingRow, "_id", "") === get(record, "_id", "") ||
                  (get(addRowState, "_id", "") === "newRow" &&
                    get(record, "_id", "") === "newRow" &&
                    addRowState.platform === record.platform)
                )
                  ? t("delete")
                  : t("cancel")
              }?`}
              onConfirm={async () => {
                if (editValidation || addActionState) {
                  handleCancel();
                } else {
                  await dispatch(deleteCommissions({ id: record._id }));
                  // notification.success({
                  //   placement: "topRight",
                  //   message: "Commission Deleted Succesfully!",
                  // });
                  setPlateformData([]);
                  await dispatch(getAllCommissionPlateformData(tableParams));
                }
              }}
              className="popOver_bgColor"
              okText={t("yes")}
              cancelText={t("no")}
            >
              <button>
                <img
                  id="deleteImg"
                  src={
                    get(editingRow, "_id", "") === get(record, "_id", "") ||
                    (get(addRowState, "_id", "") === "newRow" &&
                      get(record, "_id", "") === "newRow" &&
                      addRowState.platform === record.platform)
                      ? closeBlack
                      : deleteIcon
                    //!isEmpty(platformData) && data?.plateform === addRowState?.platform
                  }
                  alt="delete_Icon"
                />
              </button>
            </Popconfirm>
          </Space>
        );
      },
    };
    const columns = [
      {
        title: "From",
        dataIndex: "from",
        // render: (landing) => moment(landing).format("YYYY-MM-DD"),
        render: (_, landing, i) =>
          renderCompDatePicker(
            landing,
            `${moment(landing.from).format("YYYY-MM-DD")}`,
            // landing.from,
            editingRow,
            addRowState,
            (val) => {
              localStorage.setItem("from", val);
            },
            `from`
          ),
      },
      {
        title: "To",
        dataIndex: "to",
        // render: (landing) => moment(landing).format("YYYY-MM-DD"),
        render: (_, landing, i) =>
          renderCompDatePicker(
            landing,
            `${moment(landing.to).format("YYYY-MM-DD")}`,
            // landing.to,
            editingRow,
            addRowState,
            (val) => {
              localStorage.setItem("to", val);
            },
            `to`
          ),
      },
      {
        title: "Commission",
        dataIndex: "commission",
        // render: (text) => text,
        render: (_, landing, i) =>
          renderNumberCompCommission(
            landing,
            `${landing.commission}`,
            editingRow,
            addRowState,
            (val) => {
              localStorage.setItem("commission", val);
            },
            "price"
          ),
      },
      actionColumn,
    ];
    const [columnData, setColumnData] = useState(columns);

    useEffect(() => {
      if (editingRow || addRowState) {
        setColumnData(columns);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingRow, addRowState]);
    return (
      <>
        {contextHolder}
        <Table
          id="commission-table"
          columns={columns}
          dataSource={
            !isEmpty(platformData) &&
            get(data, "plateform", "") === get(addRowState, "platform", "")
              ? platformData
              : data.data
          }
          loading={loading}
          pagination={getPagination(get(data, "plateform", ""))}
          onChange={(e) => {
            setPanelTableParams(e);
            handleTableChange(e, "pagination");
          }}
        />
      </>
    );
  };

  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <Row gutter={[16, 16]} style={{ margin: "0px" }} id="commissions">
      <Col span={12}>
        {rawData && rawData.length > 0
          ? rawData
              .filter((_, i) => i < 8)
              .map((e, index) => {
                return (
                  <Col span={24} key={index} className="mb-4">
                    <Collapse
                      size="large"
                      key={index}
                      expandIconPosition="end"
                      onChange={() => {
                        arrPanelIndex = [...panelIndex, index];
                        setPanelIndex(arrPanelIndex);
                      }}
                      activeKey={activePanelData}
                    >
                      <Panel header={<HeaderComp {...e} />} key={index}>
                        <PanelContent data={e.items} plateform={e._id} />
                      </Panel>
                    </Collapse>
                  </Col>
                );
              })
          : null}
      </Col>
      <Col span={12}>
        {rawData && rawData.length > 0
          ? rawData
              .filter((_, i) => i > 7)
              .map((e, index) => {
                const headerProps = {
                  e,
                };
                return (
                  <Col span={24} key={index} className="mb-4">
                    <Collapse
                      size="large"
                      key={index}
                      expandIconPosition="end"
                      onChange={() => {
                        arrPanelIndex2 = [...panelIndex2, index];
                        setPanelIndex2(arrPanelIndex2);
                      }}
                      activeKey={activePanelData2}
                    >
                      <Panel header={<HeaderComp {...e} />} key={index}>
                        <PanelContent data={e.items} plateform={e._id} />
                      </Panel>
                    </Collapse>
                  </Col>
                );
              })
          : null}
      </Col>
    </Row>
  );
};

export default Commissions;
