import { DownOutlined } from "@ant-design/icons";
import { Form, Input, Select, DatePicker, InputNumber } from "antd";
import { get, isEmpty } from "lodash";
import dayjs from "dayjs";
import moment from "moment";

export const getBgColor = (i) => {
  if (i % 4 === 0) {
    return "#E31F89";
  } else if (i % 3 === 0) {
    return "#B7B005";
  } else if (i % 2 === 0) {
    return "#E3842D";
  } else {
    return "#6270EC";
  }
};

const editRow = (editingRow, record) => {
  if (isEmpty(editingRow)) {
    return false;
  }
  if (record?.category === "RESERVATION" || record?.category !== undefined) {
    return editingRow.email === record.email;
  }
  return editingRow.id === record.id;
};

export const renderComp = (
  record,
  text,
  editMode,
  fieldName,
  itemId,
  handleChange
) => {
  const isEditing = editMode;
  const isNumeric = !isNaN(text);

  const renderValue = () => {
    if (isEditing) {
      return (
        <Input
          style={{ maxWidth: "170px" }}
          defaultValue={text}
          onChange={(e) => handleChange(e.target.value, fieldName, itemId)}
        />
      );
    } else if (isNumeric) {
      return `${text}`;
    } else {
      return text;
    }
  };

  return renderValue();
};

export const renderComp2 = (
  record,
  value,
  isEditing,
  fieldType,
  handleChange
) => {
  if (isEditing) {
    if (fieldType === "date") {
      return (
        <DatePicker
          defaultValue={value ? dayjs(value, "YYYY-MM-DD") : null}
          onChange={(date, dateString) => handleChange(dateString)}
          allowClear
        />
      );
    } else if (fieldType === "text") {
      return (
        <Input
          defaultValue={value}
          onChange={(e) => handleChange(e.target.value)}
        />
      );
    } else if (fieldType === "number") {
      return (
        <InputNumber
          min={0}
          defaultValue={value}
          onChange={(e) => handleChange(e)}
        />
      );
    } else if (fieldType === "select") {
      return (
        <Select
          style={{ width: "100%" }}
          defaultValue={value}
          onChange={(val) => handleChange(val)}
          options={[
            {
              key: "percentage",
              label: "Percentage",
              value: "PERCENTAGE",
            },
            // {
            //   key: "flat",
            //   label: "Flat",
            //   value: "FLAT",
            // },
          ]}
        />
      );
    }
  } else {
    return fieldType === "date" ? (
      moment(value).format("YYYY-MM-DD")
    ) : fieldType === "select" ? (
      <span style={{ textTransform: "capitalize" }}>{value?.toLowerCase()}</span>
    ) : (
      value
    );
  }
};

export const renderNumberComp = (
  record,
  text,
  editMode,
  addRowState,
  name = "",
  handleChange
) => {
  // const isEditing = editRow(editingRow, record);
  const isAdditing = editRow(addRowState, record);

  return editMode || isAdditing ? (
    <Form.Item name={["name", name]} style={{ margin: 0 }}>
      <InputNumber
        style={{ maxWidth: "170px" }}
        defaultValue={text}
        onChange={(val) => handleChange(val)}
      />
    </Form.Item>
  ) : name === "holiday" ? (
    `${text}.00 €`
  ) : (
    `${text}.00 €`
  );
};

export const renderDropDownComp = (
  record,
  text,
  editingRow,
  handleChange,
  dataOptions,
  from
) => {
  const isEditing = isEmpty(editingRow) ? false : editingRow.id === record.id;
  return isEditing ? (
    <Form.Item name={["name", editingRow.cell]} style={{ margin: 0 }}>
      <Select
        suffixIcon={
          <DownOutlined style={{ fontSize: "15px", color: "white" }} />
        }
        style={{ maxWidth: "170px" }}
        defaultValue={text}
        onChange={(val) => handleChange(val)}
        options={dataOptions}
      />
    </Form.Item>
  ) : (
    <span
      className={`${
        text !== "Pick-Up" && (text === "no" || "NO" || "No")
          ? "bg-[#E12714]"
          : "bg-[#05B44B]"
      } text-white py-1 px-2 rounded-md text-sm font-semibold capitalize min-w-[40px] flex justify-center whitespace-nowrap w-fit`}
    >
      {text}
    </span>
  );
};

export const getRandomuserParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

export const renderCompDatePicker = (
  record,
  date,
  editingRow,
  addRowState,
  handleChange,
  name = ""
) => {
  const isEditing = isEmpty(editingRow) ? false : editingRow._id === record._id;
  const isAdditing = isEmpty(addRowState)
    ? false
    : addRowState._id === "newRow" &&
      record?._id === "newRow" &&
      addRowState.platform === record.platform;
  const abc = (current, name) => {
    const x = disabledDate(current);
    if (name === "from") {
      return x || current < dayjs(current).startOf("day");
    }
    if (name === "to")
      return (
        x ||
        current < dayjs(localStorage.getItem("from") ?? current).startOf("day")
      );
  };
  const disabledDate = (current, name) => {
    return current && current < moment().endOf("day");
  };

  return isEditing || isAdditing ? (
    <Form.Item name={["name", name]} style={{ margin: 0 }}>
      <DatePicker
        style={{ maxWidth: "170px" }}
        defaultValue={
          dayjs(localStorage.getItem(name)) ?? dayjs(date, "YYYY-MM-DD")
        }
        onChange={(e, i) => handleChange(i)}
        disabledDate={(current) => abc(current, name)}
      />
    </Form.Item>
  ) : (
    date
  );
};

const disabledRanges = [
  [new Date("2023-07-05"), new Date("2023-07-10")],
  [new Date("2023-07-15"), new Date("2023-07-20")],
];

const disabledDate = (current) => {
  // Check if the current date falls within any disabled date range
  return disabledRanges.some(
    ([startDate, endDate]) =>
      current && current >= startDate && current <= endDate
  );
};

export const renderDatePicker = (
  record,
  date,
  editingRow,
  addRowState,
  handleChange,
  name = ""
) => {
  const isEditing = isEmpty(editingRow) ? false : editingRow._id === record._id;
  const isAdditing = isEmpty(addRowState)
    ? false
    : addRowState.id === "newRow" && record?.id === "newRow";

  return isEditing || isAdditing ? (
    <Form.Item name={["name", name]} style={{ margin: 0 }}>
      <DatePicker
        style={{ maxWidth: "170px" }}
        defaultValue={dayjs(date, "YYYY-MM-DD")}
        format={"YYYY-MM-DD"}
        onChange={(e, i) => handleChange(i)}
        disabledDate={disabledDate}
      />
    </Form.Item>
  ) : (
    date
  );
};
export const renderCompCommission = (
  record,
  text,
  editingRow,
  addRowState,
  handleChange,
  name = ""
) => {
  const isEditing = isEmpty(editingRow) ? false : editingRow._id === record._id;
  const isAdditing = isEmpty(addRowState)
    ? false
    : addRowState._id === "newRow" &&
      record?._id === "newRow" &&
      addRowState.platform === record.platform;

  return isEditing || isAdditing ? (
    <Form.Item name={["name", name]} style={{ margin: 0 }}>
      <Input
        style={{ maxWidth: "170px" }}
        defaultValue={text}
        onChange={(e) => handleChange(e.target.value)}
      />
    </Form.Item>
  ) : (
    text
  );
};
export const renderNumberCompCommission = (
  record,
  text,
  editingRow,
  addRowState,
  handleChange,
  name = ""
) => {
  const isEditing = isEmpty(editingRow) ? false : editingRow._id === record._id;
  const isAdditing = isEmpty(addRowState)
    ? false
    : addRowState._id === "newRow" &&
      record?._id === "newRow" &&
      addRowState.platform === record.platform;

  return isEditing || isAdditing ? (
    <Form.Item name={["name", name]} style={{ margin: 0 }}>
      <InputNumber
        style={{ maxWidth: "170px" }}
        defaultValue={text}
        onChange={(val) => handleChange(val)}
      />
    </Form.Item>
  ) : name === "price" ? (
    `${text}%`
  ) : (
    text
  );
};

export const renderSlotComp = (
  record,
  text,
  editingRow,
  handleChange,
  slotsData,
  from
) => {
  const isEditing = isEmpty(editingRow) ? false : editingRow.id === record.id;
  const dataOptions = get(slotsData, "result", []).map((item) => ({
    label: `${item.column}/${item.row}`,
    value: `${item.column}/${item.row}`,
  }));
  return isEditing ? (
    <Form.Item name={["name", editingRow.cell]} style={{ margin: 0 }}>
      <Select
        suffixIcon={
          <DownOutlined style={{ fontSize: "15px", color: "white" }} />
        }
        style={{ maxWidth: "170px" }}
        defaultValue={text}
        onChange={(val) => handleChange(val)}
        options={dataOptions}
      />
    </Form.Item>
  ) : (
    <span>{text}</span>
  );
};

export const getPayload = (payload) => {
  const updatedPayload = {
    ...payload,
    location: "ORLY",
  };
  return updatedPayload;
};
