// import axios from "axios";
import {
  GET_DIARY_REQUEST,
  GET_DIARY_SUCCESS,
  GET_DIARY_FALIURE,
} from "../../constants/diary_constants";
import {
  getAllDiaryData,
  // ADDCOMMISSIONDATA,
  // DELETECOMMISSIONDATA,
  // UPDATECOMMISSIONDATA,
} from "../../../urls";
import axios from "../../../utils/axios";

export const getAllDiaryDataApi = () => {
  return async (dispatch) => {
    dispatch({ type: GET_DIARY_REQUEST });
    try {
      const result = await axios.get(getAllDiaryData());
      dispatch({ type: GET_DIARY_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: GET_DIARY_FALIURE, payload: err });
      console.log(err);
    }
  };
};

// export const addDiaryOption = (payload) => {
//   console.log("addDiaryOption-payload: ", payload);
//   return async (dispatch) => {
//     dispatch({ type: ADD_DIARY_REQUEST });
//     try {
//       const result = await axios.post(ADDCOMMISSIONDATA, payload);
//       console.log(result,"resultttttttttttttttttttttt")
//       return get(result, "data", {});
//       // dispatch({ type: ADD_DIARY_SUCCESS, payload: result.data });
//     } catch (err) {
//       dispatch({ type: ADD_DIARY_FALIURE, payload: err });
//       console.log(err);
//       return err
//     }
//   };
// };

// export const EditDiaryOption = (payload) => {
//   return async (dispatch) => {
//     dispatch({ type: ADD_DIARY_REQUEST });
//     try {
//       const result = await axios.put(UPDATECOMMISSIONDATA, payload);
//       return get(result, "data", {});
//       // dispatch({ type: ADD_DIARY_SUCCESS, payload: result });
//     } catch (err) {
//       console.log("Kul",err);
//       return get(err, "response", {});
//       // dispatch({ type: ADD_DIARY_FALIURE, payload: err });
//     }
//   };
// };

// export const deleteDiary = (payload) => {
//   return async (dispatch) => {
//     console.log("payload-delete", payload, dispatch);
//     dispatch({ type: DELETE_DIARY_REQUEST });
//     try {
//       const result = await axios.delete(DELETECOMMISSIONDATA, {
//         data: payload,
//       });
//       console.log("result", result);
//       return get(result, "data", {});
//       // dispatch({ type: DELETE_DIARY_SUCCESS, payload: result.data });
//     } catch (err) {
//       dispatch({ type: DELETE_DIARY_FALIURE, payload: err });
//       console.log("delete-Error", err);
//     }
//   };
// };
