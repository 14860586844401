import axios from "../../../utils/axios";
import {
  UPDATE_RATES_FALIURE,
  UPDATE_RATES_REQUEST,
  UPDATE_RATES_SUCCESS,
  ADD_RATES_REQUEST,
  ADD_RATES_SUCCESS,
  ADD_RATES_FALIURE,
  GET_RATES_REQUEST,
  GET_RATES_SUCCESS,
  GET_RATES_FALIURE,
  DELETE_RATES_REQUEST,
  DELETE_RATES_SUCCESS,
  DELETE_RATES_FALIURE,
} from "../../constants/rates_and_fare";
import {
  makeGetAllRatesAndFare,
  addRatesAndFareOption,
  deleteRatesAndFareOption,
  updateAllRatesAndFareOption,
} from "../../../urls";
import { getPayload } from "../../../utils/extras";

//RESERVATION
export const getRatesFareData = (tableParams) => {
  return async (dispatch) => {
    dispatch({ type: GET_RATES_REQUEST });
    try {
      const result = await axios.get(makeGetAllRatesAndFare(tableParams));
      dispatch({ type: GET_RATES_SUCCESS, payload: result.data?.data });
      // return result.data?.data;
    } catch (err) {
      dispatch({ type: GET_RATES_FALIURE, payload: err });
      // return err ;
    }
  };
};

export const addRatesFareOptrion = (payload) => {
  return async (dispatch) => {
    dispatch({ type: ADD_RATES_REQUEST });
    try {
      const result = await axios.post(
        addRatesAndFareOption,
        getPayload(payload)
      );
      return result;
    } catch (err) {
      return err;
    }
  };
};

export const EditRatesFareOption = (payload) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_RATES_REQUEST });
    try {
      const result = await axios.put(
        updateAllRatesAndFareOption,
        getPayload(payload)
      );
      // dispatch({ type: UPDATE_RATES_SUCCESS, payload: result });
      return result;
    } catch (err) {
      dispatch({ type: UPDATE_RATES_FALIURE, payload: err });
      return err;
    }
  };
};

export const deleteRatesFare = (payload) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_RATES_REQUEST });
    try {
      const result = await axios.delete(deleteRatesAndFareOption, {
        data: payload,
      });
      // dispatch({ type: DELETE_RATES_SUCCESS, payload: result.data});
      return result;
    } catch (err) {
      dispatch({ type: DELETE_RATES_FALIURE, payload: err });
      return err;
    }
  };
};

//HOLIDAYS
export const getHolidayRatesFareData = (tableParams) => {
  return async (dispatch) => {
    dispatch({ type: GET_RATES_REQUEST });
    try {
      const result = await axios.get(makeGetAllRatesAndFare(tableParams));
      dispatch({ type: GET_RATES_SUCCESS, payload: result.data?.data });
    } catch (err) {
      dispatch({ type: GET_RATES_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const addHolidayRatesFareOptrion = (payload) => {
  return async (dispatch) => {
    dispatch({ type: ADD_RATES_REQUEST });
    try {
      const result = await axios.post(
        addRatesAndFareOption,
        getPayload(payload)
      );
      dispatch({ type: ADD_RATES_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: ADD_RATES_FALIURE, payload: err });
    }
  };
};

export const EditHolidayRatesFareOption = (payload) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_RATES_REQUEST });
    try {
      const result = await axios.put(
        updateAllRatesAndFareOption,
        getPayload(payload)
      );
      dispatch({ type: UPDATE_RATES_SUCCESS, payload: result });
    } catch (err) {
      dispatch({ type: UPDATE_RATES_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const deleteHolidayRatesFare = (payload) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_RATES_REQUEST });
    try {
      const result = await axios.delete(deleteRatesAndFareOption, {
        data: payload,
      });
      dispatch({ type: DELETE_RATES_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: DELETE_RATES_FALIURE, payload: err });
      console.log("delete-Error", err);
    }
  };
};

//LATEFEE

export const getLateFeeRatesFareData = (tableParams) => {
  return async (dispatch) => {
    dispatch({ type: GET_RATES_REQUEST });
    try {
      const result = await axios.get(makeGetAllRatesAndFare(tableParams));
      dispatch({ type: GET_RATES_SUCCESS, payload: result.data?.data });
    } catch (err) {
      dispatch({ type: GET_RATES_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const addLateFeeRatesFareOptrion = (payload) => {
  return async (dispatch) => {
    dispatch({ type: ADD_RATES_REQUEST });
    try {
      const result = await axios.post(
        addRatesAndFareOption,
        getPayload(payload)
      );
      dispatch({ type: ADD_RATES_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: ADD_RATES_FALIURE, payload: err });
      console.log("ADD", err.response.data.message);
    }
  };
};

export const EditLateFeeRatesFareOption = (payload) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_RATES_REQUEST });
    try {
      const result = await axios.put(
        updateAllRatesAndFareOption,
        getPayload(payload)
      );
      dispatch({ type: UPDATE_RATES_SUCCESS, payload: result });
    } catch (err) {
      dispatch({ type: UPDATE_RATES_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const deleteLateFeeRatesFare = (payload) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_RATES_REQUEST });
    try {
      const result = await axios.delete(deleteRatesAndFareOption, {
        data: payload,
      });
      dispatch({ type: DELETE_RATES_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: DELETE_RATES_FALIURE, payload: err });
      console.log("delete-Error", err);
    }
  };
};
