import {
  GET_STAFF_DRIVER_FALIURE,
  GET_STAFF_DRIVER_REQUEST,
  GET_STAFF_DRIVER_SUCCESS,
  POST_ADD_DRIVER_FALIURE,
  POST_ADD_DRIVER_SUCCESS,
  POST_ADD_DRIVER_REQUEST,
  DELETE_DRIVER_FALIURE,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_REQUEST,
  UPDATE_DRIVER_FALIURE,
  UPDATE_DRIVER_REQUEST,
  UPDATE_DRIVER_SUCCESS,
} from "../constants/staff_and_drivers";

const dataStaffDriver = { data: [], isLoading: false, error: false };
function getDriverAndStaff(state = dataStaffDriver, action) {
  switch (action.type) {
    case GET_STAFF_DRIVER_REQUEST:
      return { data: [], isLoading: true, error: null };
    case GET_STAFF_DRIVER_SUCCESS:
      return { data: [action.payload], isLoading: false, error: null };
    case GET_STAFF_DRIVER_FALIURE:
      return { data: [action.payload], isLoading: false, error: true };
    default:
      return state;
  }
}

function addDriverAndStaff(state = dataStaffDriver, action) {
  switch (action.type) {
    case POST_ADD_DRIVER_REQUEST:
      return state;
    case POST_ADD_DRIVER_SUCCESS:
      return action.payload;
    case POST_ADD_DRIVER_FALIURE:
      return action.payload;
    default:
      return state;
  }
}

function deleteDriverAndStaff(state = dataStaffDriver, action) {
  switch (action.type) {
    case DELETE_DRIVER_REQUEST:
      return state;
    case DELETE_DRIVER_SUCCESS:
      return action.payload;
    case DELETE_DRIVER_FALIURE:
      return action.payload;
    default:
      return state;
  }
}

function updateDriverAndStaff(state = dataStaffDriver, action) {
  switch (action.type) {
    case UPDATE_DRIVER_REQUEST:
      return state;
    case UPDATE_DRIVER_SUCCESS:
      return action.payload;
    case UPDATE_DRIVER_FALIURE:
      return action.payload;
    default:
      return state;
  }
}

export {
  getDriverAndStaff,
  addDriverAndStaff,
  deleteDriverAndStaff,
  updateDriverAndStaff,
};
