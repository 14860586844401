import React from "react";
import { DatePicker, Form, Popconfirm, Space, Table, notification } from "antd";
import { useEffect, useState } from "react";
import {
  addRow,
  deleteIcon,
  pencil,
  tick,
  closeBlack,
} from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateHolidaysData,
  addHolidaysData,
  deleteHolidaysData,
  getAllHolidaysData,
} from "../../redux/actions/holidays";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { renderComp, renderNumberComp } from "../../utils/extras";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

// const getRandomuserParams = (params) => ({
//   results: params.pagination?.pageSize,
//   page: params.pagination?.current,
//   ...params,
// });
const Holidays = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allData = useSelector((state) => state);
  const holidayRatesData = get(allData, "getHolidays.data.results", []);
  const isLoading = get(allData, "getHolidays.isLoading", false);
  const { activeKey } = props;
  const [data, setData] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [addActionState, setAddActionState] = useState(false);
  const [editValidation, setEditValidation] = useState(false);
  const [editingRow, setEditingRow] = useState({});
  const [addRowState, setAddRowState] = useState();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const currentDate = new Date();

  const removeItems = () => {
    localStorage.removeItem("year");
    localStorage.removeItem("event");
    localStorage.removeItem("arrivalDate");
    localStorage.removeItem("departureDate");
    localStorage.removeItem("fixedPrice");
    localStorage.removeItem("priceIncreaseBy");
  };

  const handleAddSubmit = async (values) => {
    const fixedRate = Number(localStorage.getItem("fixedPrice"));
    const year = localStorage.getItem("year");
    const event = localStorage.getItem("event");
    const from = localStorage.getItem("arrivalDate");
    const to = localStorage.getItem("departureDate");
    const incresedBy = localStorage.getItem("priceIncreaseBy");
    if (
      !isEmpty(localStorage.getItem("fixedPrice")) &&
      !isEmpty(localStorage.getItem("year")) &&
      !isEmpty(localStorage.getItem("event")) &&
      !isEmpty(localStorage.getItem("arrivalDate")) &&
      !isEmpty(localStorage.getItem("departureDate")) &&
      !isEmpty(localStorage.getItem("priceIncreaseBy"))
    ) {
      setLoading(true);
      delete values?.id;
      const requestObject = {
        year: year,
        event: event,
        fixedPrice: fixedRate,
        priceIncreaseBy: incresedBy,
        arrivalDate: from,
        departureDate: to,
      };

      const result = await dispatch(addHolidaysData(requestObject));
      if (get(result, "statusCode") === 200) {
        notification.success({
          placement: "topRight",
          message: "Holiday added Succesfully!",
        });
        setLoading(false);
        setAddRowState(false);
        setAddActionState(false);
        // setColumnData(columns);
        dispatch(getAllHolidaysData(tableParams));
        removeItems();
      } else {
        setLoading(false);
        notification.error({
          placement: "topRight",
          message: "Holiday can not be added!",
        });
      }
    } else {
      api.warning({
        message: `Warning:`,
        description: "Please fill all fields",
        placement: "topRight",
      });
    }
  };

  const handleEditSubmit = async (values) => {
    setLoading(true);
    const fixedRate = Number(localStorage.getItem("fixedPrice"));
    const year = localStorage.getItem("year");
    const event = localStorage.getItem("event");
    const from = localStorage.getItem("arrivalDate");
    const to = localStorage.getItem("departureDate");
    const incresedBy = localStorage.getItem("priceIncreaseBy");
    if (
      !isEmpty(localStorage.getItem("fixedPrice")) &&
      !isEmpty(localStorage.getItem("year")) &&
      !isEmpty(localStorage.getItem("event")) &&
      !isEmpty(localStorage.getItem("arrivalDate")) &&
      !isEmpty(localStorage.getItem("departureDate")) &&
      !isEmpty(localStorage.getItem("priceIncreaseBy"))
    ) {
      // delete values?._id;
      const requestObject = {
        id: values?.id,
        year: year,
        event: event,
        fixedPrice: fixedRate,
        priceIncreaseBy: incresedBy,
        arrivalDate: from,
        departureDate: to,
      };
      const result = await dispatch(UpdateHolidaysData(requestObject));
      if (get(result, "statusCode") === 200) {
        notification.success({
          placement: "topRight",
          message: "Holiday Updated Succesfully!",
        });
        setLoading(false);
        setEditValidation(false);
        setEditingRow({});
        // setColumnData(columns);
        dispatch(getAllHolidaysData(tableParams));
        removeItems();
      } else {
        setLoading(false);
        notification.error({
          placement: "topRight",
          message: "Holiday can not be updated!",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleAdd = (record) => {
    // localStorage.setItem("fixedPrice", 0);
    // localStorage.setItem("of", 1);
    // localStorage.setItem("at", 1);
    // localStorage.setItem("costPerUnit", 0);
    setAddRowState(record);
  };

  const handleEdit = (record) => {
    setEditValidation(true);
    localStorage.setItem("fixedPrice", record?.fixedPrice);
    localStorage.setItem("year", record?.year);
    localStorage.setItem("event", record?.event);
    localStorage.setItem("arrivalDate", record?.arrivalDate);
    localStorage.setItem("departureDate", record?.departureDate);
    localStorage.setItem("priceIncreaseBy", record?.priceIncreaseBy);
    // localStorage.setItem("at", parseInt(record?.at, 10));
    // localStorage.setItem("costPerUnit", parseInt(record?.costPerUnit, 10));
    setEditingRow(record);
  };

  const addAction = () => {
    if (!editValidation) {
      const requestObject = {
        arrivalDate: "",
        departureDate: "",
        event: "",
        fixedPrice: "",
        op: "",
        priceIncreaseBy: "",
        year: "",
        id: "newRow",
        // id: randomNumber,
        // at: "",
        // costPerUnit: "",
      };

      setData((pre) => {
        return [requestObject, ...pre];
      });
      handleAdd(requestObject);
    } else
      api.warning({
        message: `Warning:`,
        description: "Please complete your existing action before",
        placement: "topRight",
      });
  };

  const handleCancel = () => {
    setAddActionState(false);
    setEditValidation(false);
    setEditingRow({});
    setAddRowState(false);
    removeItems();
    // setAddRowState(false);
  };

  const disabledRanges = holidayRatesData.map((item) => [
    item?.arrivalDate,
    item?.departureDate,
    item?.id,
  ]);
  // const disabledRanges = [
  //   [new Date('2023-07-05'), new Date('2023-07-10')],
  //   [new Date('2023-07-15'), new Date('2023-07-20')],
  // ];

  // const disabledBeforeDate = (current, name) => {
  //   // Set the specific date
  //   if (name === "To") {
  //     const specificDate = new Date("2023-07-01");

  //     // Disable dates before the specific date
  //     return current && current < specificDate.setHours(0, 0, 0, 0);
  //   }
  //   return false;
  // };

  const renderDatePicker = (
    record,
    date,
    editingRow,
    addRowState,
    handleChange,
    name = ""
  ) => {
    const filterDisabled = disabledRanges.filter(
      (item) => item[2] !== record?.id
    );

    const disabledDate = (current) => {
      // Check if the current date falls within any disabled date range
      return filterDisabled.some(([startDate, endDate]) => {
        let start = new Date(startDate);
        start.setDate(start.getDate() - 1);
        return (
          current && current >= new Date(start) && current <= new Date(endDate)
        );
      });
    };

    const abc = (current, name) => {
      const x = disabledDate(current, name);
      if (name === "From")
        return x || current < dayjs(currentDate).startOf("day");
      if (name === "To")
        return (
          x ||
          current <
            dayjs(localStorage.getItem("arrivalDate") ?? currentDate).startOf(
              "day"
            )
        );
    };

    const isEditing = isEmpty(editingRow)
      ? false
      : editingRow._id === record._id;
    const isAdditing = isEmpty(addRowState)
      ? false
      : addRowState.id === "newRow" && record?.id === "newRow";
    return isEditing || isAdditing ? (
      <Form.Item name={["name", name]} style={{ margin: 0 }}>
        <DatePicker
          // defaultValue={moment(new Date(date), 'YYYY-MM-DD')}
          style={{ maxWidth: "170px" }}
          defaultValue={date === null ? null : dayjs(date, "YYYY-MM-DD")}
          format={"YYYY-MM-DD"}
          onChange={(e, i) => handleChange(i)}
          disabledDate={(current) => abc(current, name)}
          // disabled={(current) => disabledBeforeDate(current, name)}
        />
      </Form.Item>
    ) : (
      date
    );
  };

  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      sorter: true,
      render: (_, record, i) =>
        renderComp(
          record,
          record.year,
          editingRow,
          addRowState,
          "text",
          (val) => {
            localStorage.setItem("year", val);
          }
        ),
    },
    {
      title: "Event",
      dataIndex: "event",
      sorter: true,
      render: (_, record, i) =>
        renderComp(
          record,
          record.event,
          editingRow,
          addRowState,
          "text",
          (val) => {
            localStorage.setItem("event", val);
          }
        ),
    },

    {
      title: "From",
      dataIndex: "arrivalDate",
      sorter: true,
      render: (_, record, i) =>
        renderDatePicker(
          record,
          record?.arrivalDate
            ? `${moment(record.arrivalDate).format("YYYY-MM-DD")}`
            : null,
          // landing.to,
          editingRow,
          addRowState,
          (val) => {
            localStorage.setItem("arrivalDate", val);
          },
          "From"
        ),
      // render: (data) => `${moment(data).format("YYYY-MM-DD")}`,
    },
    {
      title: "To",
      dataIndex: "departureDate",
      sorter: true,
      render: (_, record, i) =>
        renderDatePicker(
          record,
          record.departureDate
            ? `${moment(record.departureDate).format("YYYY-MM-DD")}`
            : null,
          // landing.to,
          editingRow,
          addRowState,
          (val) => {
            localStorage.setItem("departureDate", val);
          },
          "To"
        ),
      // render: (data) => `${moment(data).format("YYYY-MM-DD")}`,
    },
    {
      title: "Fixed Price",
      dataIndex: "fixedPrice",
      sorter: true,
      render: (_, record, i) =>
        renderNumberComp(
          record,
          record.fixedPrice,
          editingRow,
          addRowState,
          "holiday",
          (val) => {
            localStorage.setItem("fixedPrice", val);
          }
        ),
      // render: (dob) => dob + ".00 €",
    },
    {
      title: "Increased By",
      dataIndex: "priceIncreaseBy",
      sorter: true,
      render: (_, record, i) =>
        renderNumberComp(
          record,
          record.priceIncreaseBy,
          editingRow,
          addRowState,
          "holiday",
          (val) => {
            localStorage.setItem("priceIncreaseBy", val);
          }
        ),
    },
    {
      title: "Arrival / Departure",
      dataIndex: "cost",
      sorter: true,
      render: () => "Lorem Ipsum",
    },
    {
      title: (
        <div className="flex justify-end cursor-pointer">
          <img
            src={addRow}
            alt=""
            style={{ marginLeft: "0px" }}
            onClick={(e) => {
              setAddActionState(true);
              addAction();
            }}
          />
        </div>
      ),
      dataIndex: "dob",
      sorter: false,
      render: (_, record) => {
        return (
          <Space size="middle">
            {loading &&
            (get(editingRow, "id", "") === get(record, "id", "") ||
              (addRowState?.id === "newRow" && record?.id === "newRow")) ? (
              <LoadingOutlined />
            ) : (
              <>
                <button
                  onClick={() => {
                    if (addRowState) {
                      handleAddSubmit(addRowState);
                    } else {
                      !isEmpty(editingRow) && editingRow?.id === record?.id
                        ? handleEditSubmit(record)
                        : handleEdit(record);
                    }
                  }}
                >
                  <img
                    src={
                      get(editingRow, "id", "") === get(record, "id", "") ||
                      (addRowState?.id === "newRow" && record?.id === "newRow")
                        ? tick
                        : pencil
                    }
                    alt="edit_Icon"
                  />
                </button>
                <Popconfirm
                  id="custom-delete-dialog"
                  title={`${t("are_you_sure")} ${
                    !(editValidation || addActionState)
                      ? t("delete")
                      : t("cancel")
                  }?`}
                  onConfirm={async () => {
                    if (editValidation || addActionState) {
                      handleCancel();
                    } else {
                      await dispatch(deleteHolidaysData({ id: record?._id }));
                      dispatch(getAllHolidaysData(tableParams));
                    }
                  }}
                  className="popOver_bgColor"
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <button>
                    <img
                      id="deleteImg"
                      src={
                        get(editingRow, "id", "") === get(record, "id", "") ||
                        (addRowState?.id === "newRow" &&
                          record?.id === "newRow")
                          ? closeBlack
                          : deleteIcon
                      }
                      alt="delete_Icon"
                    />
                  </button>
                </Popconfirm>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (activeKey === 2) {
      setLoading(true);
      dispatch(getAllHolidaysData());
      setLoading(false);
      removeItems();
    }
    // eslint-disable-next-line
  }, [tableParams, activeKey]);

  const prepareData = (data) => {
    const bData = data.map((item) => ({
      ...item,
      email: item?.of,
      id: item?._id,
    }));
    return bData;
  };

  useEffect(() => {
    if (
      holidayRatesData &&
      isEmpty(editingRow) &&
      holidayRatesData.length > 0
    ) {
      const bData = prepareData(holidayRatesData);
      setData(bData);
      setTotal(get(allData, "getHolidays.data.itemCount", 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holidayRatesData, editingRow]);

  useEffect(() => {
    if (total) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: total,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "" : "bg-gray-200"; // Apply gray background to odd-indexed rows
  };

  return (
    <>
      {contextHolder}
      <Table
        id="reservation-table"
        columns={columns}
        loading={isLoading}
        rowKey={(record) => record?._id}
        dataSource={data}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
        rowClassName={rowClassName}
      />
    </>
  );
};

export default Holidays;
