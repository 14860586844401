import axios from '../../../utils/axios';
import { dashBoardCount, dashboardStatistics, getAmountPerPlatform , getAmountPerPlatformPie } from '../../../urls';
import {
  GET_DASHBOARD_COUNT_FALIURE,
  GET_DASHBOARD_COUNT_REQUEST,
  GET_DASHBOARD_COUNT_SUCCESS,
  GET_DASHBOARD_STATISTICS_FALIURE,
  GET_DASHBOARD_STATISTICS_REQUEST,
  GET_DASHBOARD_STATISTICS_SUCCESS,
  GET_AMOUNT_PLATFORM_FALIURE,
  GET_AMOUNT_PLATFORM_REQUEST,
  GET_AMOUNT_PLATFORM_SUCCESS,
  GET_AMOUNT_PLATFORM_PIE_REQUEST,
  GET_AMOUNT_PLATFORM_PIE_FALIURE,
  GET_AMOUNT_PLATFORM_PIE_SUCCESS
} from '../../constants/statistics';


export const getDashboardCount = () => {
  return async (dispatch) => {
    dispatch({ type: GET_DASHBOARD_COUNT_REQUEST });
    try {

      const result = await axios.get(dashBoardCount);
      dispatch({ type: GET_DASHBOARD_COUNT_SUCCESS, payload: result.data.data || [] });
    } catch (err) {
      dispatch({ type: GET_DASHBOARD_COUNT_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const getDashboardStatistics = (month, year) => {
  return async (dispatch) => {
    dispatch({ type: GET_DASHBOARD_STATISTICS_REQUEST });
    try {

      const result = await axios.get(dashboardStatistics(month, year));
      dispatch({ type: GET_DASHBOARD_STATISTICS_SUCCESS, payload: result.data.data || [] });
    } catch (err) {
      dispatch({ type: GET_DASHBOARD_STATISTICS_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const getAmountPerPlatformVal = (fromData, toDate) => {
  return async (dispatch) => {
    dispatch({ type: GET_AMOUNT_PLATFORM_REQUEST });
    try {

      const result = await axios.get(getAmountPerPlatform(fromData, toDate));
      dispatch({ type: GET_AMOUNT_PLATFORM_SUCCESS, payload: result.data.data || [] });
    } catch (err) {
      dispatch({ type: GET_AMOUNT_PLATFORM_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const getAmountPerPlatformValPie = (fromData, toDate) => {
  return async (dispatch) => {
    dispatch({ type: GET_AMOUNT_PLATFORM_PIE_REQUEST });
    try {

      const result = await axios.get(getAmountPerPlatformPie(fromData, toDate));
      dispatch({ type: GET_AMOUNT_PLATFORM_PIE_SUCCESS, payload: result.data.data || [] });
    } catch (err) {
      dispatch({ type: GET_AMOUNT_PLATFORM_PIE_FALIURE, payload: err });
      console.log(err);
    }
  };
};