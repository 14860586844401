import {
  GET_DASHBOARD_COUNT_FALIURE,
  GET_DASHBOARD_COUNT_REQUEST,
  GET_DASHBOARD_COUNT_SUCCESS,
  GET_DASHBOARD_STATISTICS_FALIURE,
  GET_DASHBOARD_STATISTICS_REQUEST,
  GET_DASHBOARD_STATISTICS_SUCCESS,
  GET_AMOUNT_PLATFORM_FALIURE,
  GET_AMOUNT_PLATFORM_REQUEST,
  GET_AMOUNT_PLATFORM_SUCCESS,
  GET_AMOUNT_PLATFORM_PIE_REQUEST,
  GET_AMOUNT_PLATFORM_PIE_FALIURE,
  GET_AMOUNT_PLATFORM_PIE_SUCCESS,
} from "../constants/statistics";

const dashboardCountData = { users: [], isLoading: false, error: null };
const dashBoardStatisticsData = { users: [], isLoading: false, error: null };
const platformAmountData = { users: [], isLoading: false, error: null };
const platformAmountDataPie = { users: [], isLoading: false, error: null };

function getDashboardCountData(state = dashboardCountData, action) {
  switch (action.type) {
    case GET_DASHBOARD_COUNT_REQUEST:
      return { users: [], isLoading: true, error: null };
    case GET_DASHBOARD_COUNT_SUCCESS:
      return { users: [action.payload], isLoading: false, error: null };
    case GET_DASHBOARD_COUNT_FALIURE:
      return { users: [], isLoading: false, error: action.payload };
    default:
      return state;
  }
}

function getDashboardStatisticsData(state = dashBoardStatisticsData, action) {
  switch (action.type) {
    case GET_DASHBOARD_STATISTICS_REQUEST: {
      return { users: [], isLoading: true, error: null };
    }
    case GET_DASHBOARD_STATISTICS_SUCCESS: {
      return { users: [action.payload], isLoading: false, error: null };
    }
    case GET_DASHBOARD_STATISTICS_FALIURE: {
      return { users: [], isLoading: false, error: action.payload };
    }
    default:
      return state;
  }
}

function getPlatformDataAmount(state = platformAmountData, action) {
  switch (action.type) {
    case GET_AMOUNT_PLATFORM_REQUEST: {
      return { users: [], isLoading: true, error: null };
    }
    case GET_AMOUNT_PLATFORM_SUCCESS: {
      return { users: [action.payload], isLoading: false, error: null };
    }
    case GET_AMOUNT_PLATFORM_FALIURE: {
      return { users: [], isLoading: false, error: action.payload };
    }
    default:
      return state;
  }
}

function getPlatformDataAmountPie(state = platformAmountDataPie, action) {
  switch (action.type) {
    case GET_AMOUNT_PLATFORM_PIE_REQUEST: {
      return { users: [], isLoading: true, error: null };
    }
    case GET_AMOUNT_PLATFORM_PIE_SUCCESS: {
      return { users: [action.payload], isLoading: false, error: null };
    }
    case GET_AMOUNT_PLATFORM_PIE_FALIURE: {
      return { users: [], isLoading: false, error: action.payload };
    }
    default:
      return state;
  }
}

export {
  getDashboardCountData,
  getDashboardStatisticsData,
  getPlatformDataAmount,
  getPlatformDataAmountPie,
};
