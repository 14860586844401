import {
  ADD_BOOKING_FALIURE,
  ADD_BOOKING_REQUEST,
  GET_BOOKING_REQUEST,
  GET_BOOKING_FALIURE,
  GET_BOOKING_SUCCESS,
  GET_SLOTS_REQUEST,
  GET_SLOTS_SUCCESS,
  GET_SLOTS_FALIURE,
  DELETE_BOOKING_REQUEST,
} from "../../constants/booking_option";
import {
  makeGetAllBookingOption,
  getAllAvailableSlots,
  postAllBookingOption,
  updateAllBookingOption,
  deleteAllBookingOption,
} from "../../../urls";
import axios from "../../../utils/axios";
import { getPayload } from "../../../utils/extras";

export const getbookingOptionData = (params, extraParams) => {
  return async (dispatch) => {
    dispatch({ type: GET_BOOKING_REQUEST });
    try {
      const result = await axios(makeGetAllBookingOption(params, extraParams));
      dispatch({ type: GET_BOOKING_SUCCESS, payload: result.data?.data });
    } catch (err) {
      dispatch({ type: GET_BOOKING_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const getAllSlots = () => {
  return async (dispatch) => {
    dispatch({ type: GET_SLOTS_REQUEST });
    try {
      const result = await axios.get(getAllAvailableSlots);
      dispatch({ type: GET_SLOTS_SUCCESS, payload: result.data?.data });
    } catch (err) {
      dispatch({ type: GET_SLOTS_FALIURE, payload: err });
      console.log(err);
    }
  };
};

export const addbookingOption = (payload) => {
  return async (dispatch) => {
    dispatch({ type: ADD_BOOKING_REQUEST });
    try {
      const result = await axios.post(
        postAllBookingOption,
        getPayload(payload)
      );
      return result;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

export const deletebookingOption = (payload) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_BOOKING_REQUEST });
    try {
      const result = await axios.delete(deleteAllBookingOption, {
        data: payload,
      });
      return result;
    } catch (err) {
      return err;
    }
  };
};

export const EditbookingOption = (payload) => {
  return async (dispatch) => {
    dispatch({ type: ADD_BOOKING_REQUEST });
    try {
      const result = await axios.put(
        updateAllBookingOption,
        getPayload(payload)
      );
      return result;
      // dispatch({ type: ADD_BOOKING_SUCCESS, payload: result });
    } catch (err) {
      console.log(err);
      // dispatch({ type: ADD_BOOKING_FALIURE, payload: err });
      // return err;
    }
  };
};
