import React from "react";
import { Col, Form, Input, Row } from "antd";
import { user, pencil_white } from "../../assets/icons";

const s = {
  modalHeader:
    "bg-[#E12714] justify-center text-2xl font-bold flex text-white uppercase p-4",
  image: "w-[200px] h-[200px] rounded-full shadow-lg",
  editIcon:
    "w-6 h-auto shadow-md absolute right-3 bottom-6 p-1 rounded-full bg-[#E12714]",
  button:
    "text-white capitalize px-4 py-2 items-center flex justify-center text-sm",
  row1: "py-4 w-full pl-6 pr-2",
};

const UserProfile = () => {
  return (
    <div>
      <div className="flex gap-8 items-center pt-5 mb-4 pl-6 pr-2">
        <div className="relative">
          <img src={user} alt="user" className={s.image} />
          <img src={pencil_white} alt="user" className={s.editIcon} />
        </div>
        <div>
          <p className="text-2xl font-bold">Jhon Doe</p>
          <p className="text-xs">Edit profile</p>
        </div>
      </div>
      <div className="flex gap-4 flex-col">
        <Form layout="vertical" className="w-full">
          <div className="flex flex-col">
            <div className={s.row1}>
              <Row gutter={[16]} className="w-full">
                <Col span={8}>
                  <Form.Item label="Username">
                    <Input
                      placeholder="Enter your username"
                      className="rounded-none"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="First Name">
                    <Input
                      placeholder="Enter your first name"
                      className="rounded-none"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Last Name">
                    <Input
                      placeholder="Enter your last name"
                      className="rounded-none"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Email Address">
                    <Input
                      placeholder="Enter your email"
                      className="rounded-none"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Phone Number">
                    <Input
                      placeholder="Enter your phone number"
                      className="rounded-none"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Password" >
                    <Input.Password
                      placeholder="Enter your password"
                      className="rounded-none"
                    />
                  </Form.Item>
                </Col>

                <Col span={24} className="justify-end flex gap-4">
                  <button className={"bg-[#E12714] " + s.button}>
                    Edit Profile
                  </button>
                  <button className={"bg-[#081258] " + s.button}>
                    Save Profile
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UserProfile;
