import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from "../constants/login";

const initialState = { data: [], isLoading: false, error: null };

function loginUser(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { data: [], isLoading: true, error: null };
    case LOGIN_USER_SUCCESS:
      return { data: [action.payload], isLoading: false, error: null };
    case LOGIN_USER_FAILURE:
      return { data: [], isLoading: false, error: action.payload };
    default:
      return state;
  }
}

function forgetPassword(state = initialState, action) {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return { data: [], isLoading: true, error: null };
    case FORGOT_PASSWORD_SUCCESS:
      return { data: [action.payload], isLoading: false, error: null };
    case FORGOT_PASSWORD_FAILURE:
      return { data: [], isLoading: false, error: action.payload };
    default:
      return state;
  }
}

function resetPassword(state = initialState, action) {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return { data: [], isLoading: true, error: null };
    case FORGOT_PASSWORD_SUCCESS:
      return { data: [action.payload], isLoading: false, error: null };
    case FORGOT_PASSWORD_FAILURE:
      return { data: [], isLoading: false, error: action.payload };
    default:
      return state;
  }
}
export { loginUser, forgetPassword, resetPassword };
