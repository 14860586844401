export const GET_STAFF_DRIVER_REQUEST="GET_STAFF_DRIVER_REQUEST";
export const GET_STAFF_DRIVER_SUCCESS="GET_STAFF_DRIVER_SUCCESS";
export const GET_STAFF_DRIVER_FALIURE="GET_STAFF_DRIVER_FALIURE";

export const POST_ADD_DRIVER_REQUEST="POST_ADD_DRIVER_REQUEST";
export const POST_ADD_DRIVER_SUCCESS="POST_ADD_DRIVER_SUCCESS";
export const POST_ADD_DRIVER_FALIURE="POST_ADD_DRIVER_FALIURE";

export const DELETE_DRIVER_REQUEST="DELETE_DRIVER_REQUEST";
export const DELETE_DRIVER_SUCCESS="DELETE_DRIVER_SUCCESS";
export const DELETE_DRIVER_FALIURE="DELETE_DRIVER_FALIURE";

export const UPDATE_DRIVER_REQUEST="UPDATE_DRIVER_REQUEST";
export const UPDATE_DRIVER_SUCCESS="UPDATE_DRIVER_SUCCESS";
export const UPDATE_DRIVER_FALIURE="UPDATE_DRIVER_FALIURE";
