import {
  GET_DIARY_REQUEST,
  GET_DIARY_SUCCESS,
  GET_DIARY_FALIURE,
} from "../constants/diary_constants";

const dairyData = { data: [], isLoading: false, error: null };

function getDiaryData(state = dairyData, action) {
  switch (action.type) {
    case GET_DIARY_REQUEST:
      return { data: [], isLoading: true, error: null };
    case GET_DIARY_SUCCESS:
      return { data: [action.payload], isLoading: false, error: null };
    case GET_DIARY_FALIURE:
      return { data: [], isLoading: false, error: action.payload };
    default:
      return state;
  }
}

export { getDiaryData };
