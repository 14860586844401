export const GET_USER_REQUEST="GET_USER_REQUEST";
export const GET_USER_SUCCESS="GET_USER_SUCCESS";
export const GET_USER_FALIURE="GET_USER__FALIURE";

export const DELETE_USER_REQUEST="DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS="DELETE_USER_SUCCESS";
export const DELETE_USER_FALIURE="DELETE_USER_FALIURE";

export const ADD_USER_REQUEST="ADD_USER_REQUEST";
export const ADD_USER_SUCCESS="ADD_USER_SUCCESS";
export const ADD_USER_FALIURE="ADD_USER_ALIURE";

export const UPDATE_USER_REQUEST="UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS="UPDATE_USER_SUCCESS";
export const UPDATE_USER_FALIURE="UPDATE_USER_ALIURE";

export const UPLOAD_PROFILE_REQUEST="UPLOAD_PROFILE_REQUEST";
export const UPLOAD_PROFILE_SUCCESS="UPLOAD_PROFILE_SUCCESS";
export const UPLOAD_PROFILE_FALIURE="UPLOAD_PROFILE_ALIURE";



